import React, { useContext, useState } from 'react'
import { Button, Card, Form, Table, Col, Row } from 'react-bootstrap'
import Layout from '../../layout/Layout'
import { Link, useNavigate } from 'react-router-dom'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import {toast} from 'react-toastify'
import Swal from 'sweetalert2'
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { API, AuthContext } from '../../App'
import { useEffect } from 'react'
import Pagination from 'rc-pagination'
import { requestSearch1 } from '../Search'
import Cookies from 'js-cookie'

const MutualFund = () => {
    const {commonSearch,setCommonSearch} = useContext(AuthContext);
    const [dataLength, setDataLength] = useState(1)
    const tokenAuth = async () => {
        const token = Cookies.get('jwt')
        const response = await API.post("/home", {}, { headers: { "Authorization": `Bearer ${token}` } })
        if (response.data.status === false) {
            navigate("/")
        }
    }
    const [iconcoror, Seticoncoror] = useState("id_up")
    const [loading,setLoading] = useState(true)
    const navigate = useNavigate()
    const [data, setData] = useState([])
    const [sData, setSData] = useState([])
    const getData = async () => {
        const token = Cookies.get('jwt')
        const response = await API.post("/getMutualFund", {}, { headers: { "Authorization": `Bearer ${token}` } })
        if (response.data.status === false) {
            if (response.data.response_code === 429) {
                navigate("/")
            } else {
                setDataLength(0)
                setLoading(false)
                setData([])
                setSData([])
                // setOid([])
            }
        } else {
            setData(response.data.data.data)
            setSData(response.data.data.data)
            setDataLength(1)
            setLoading(false)
            if(commonSearch[7].search!==""){
                requestSearch(commonSearch[7].search,response.data.data.data)
              }
        }

    }

    const change = async (id, e,name) => {
        var Status;
        Status = (e === true) ? 1 : 0
        const Form = new FormData()
        // console.log(Status)
        Form.append('id', id)
        Form.append('status', Status)
        Form.append('name',name)
        const token = Cookies.get('jwt')
        const update = await API.post(`/mutualFundStatus/${id}`, Form, { headers: { "Authorization": `Bearer ${token}` } })
        if (update.data.status === false) {
            if (update.data.response_code === 429) {
                navigate("/")
            } else {
                toast.error('Something went wrong')
            }
        }else{
            var statusData = data.findIndex(x => x._id ===id);
            data[statusData][name]=Status
            toast.success('Status Updated')
        }
    }

    const deleteUser = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger me-2'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, Delete it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const token = Cookies.get('jwt')
                    const delCity = await API.post(`/deleteMutualFund/${id}`, {}, { headers: { "Authorization": `Bearer ${token}` } })
                    if (delCity.data.status === false) {
                        if (delCity.data.response_code === 429) {
                            navigate("/")
                        } else {
                            swalWithBootstrapButtons.fire(
                                'Cancelled',
                                `${delCity.data.response_message}`,
                                'error'
                            )
                        }
                    } else {
                        getData()
                    }

                }
            })
    }

    // Paggintion Code // 
    const [size, setSize] = useState(10);
    const [current, setCurrent] = useState(1);

    const wetData = (current, pageSize) => {
        return data.slice((current - 1) * pageSize, current * pageSize);
    };

    const PerPageChange = (value) => {
        console.log(value)
        setSize(value);
        console.log(size)
        const newPerPage = Math.ceil(data.length / value);
        if (current > newPerPage) {
            setCurrent(newPerPage);
        }
    }

    const PaginationChange = (page, pageSize) => {
        setCurrent(page);
        setSize(pageSize)
    }


    const PrevNextArrow = (current, type, originalElement) => {
        if (type === 'prev') {
            return <button className='paggination-btn'>Previous</button>;
        }
        if (type === 'next') {
            return <button className='paggination-btn'>Next</button>;
        }
        return originalElement;
    }

    const requestSearch = (searchedVal,mainData) => {
        // setMutualSearch(searchedVal)
        let srcVal = [...commonSearch]
        srcVal[7].search = searchedVal
        setCommonSearch(srcVal)
        const searchData = requestSearch1(searchedVal,mainData,mainData,"name")
        if(searchData.length===0){
            setData([])
            setDataLength(0)
        }else{
            setData(searchData)
            setDataLength(1)
        }
        setCurrent(1)
    };

    const sorting = (col, type = "string", order, e) => {
        Seticoncoror(e.target.id)
        if (order === "ASC") {
          const sorted = [...data].sort((a, b) =>
            a[col] > b[col] ? 1 : -1
          );
          if (iconcoror !== e.target.id) {
            setData(sorted)
          }
        }
        if (order === "DSC") {
          const sorted = [...data].sort((a, b) =>
            a[col] < b[col] ? 1 : -1
          );
          if (iconcoror !== e.target.id) {
            setData(sorted)
          }
        }
    }

    //Drag And Drop

    const handleDragEnd = (e) => {
        if (!e.destination) return;
        let tempData = Array.from(data);
        let [source_data] = tempData.splice(e.source.index, 1);
        tempData.splice(e.destination.index, 0, source_data);
        setData(tempData);
        var order = [];
        var x = (tempData).map(function (index, element) {
          order.push({
            id: index._id,
            position: element + 1,
          });
        });
        sortCategory(order);
    
    };

    const sortCategory = async (data) => {
        const token = Cookies.get('jwt')
        const results = await API.patch(`/mutualfund/drag`, {data:data},{ headers: { "Authorization": `Bearer ${token}` } })
        if (results.data.status === true) {
            toast.success("Position Updated Succesfully");
        }else{
            toast.error("Postion Not Changed")
        }
    }

    //ClearSearchFunction
    const clearSearchFunc = (searchEle)=>{
        let src = [...commonSearch]
        src.map((val)=>{
            if(val.name!==searchEle){
                if(val.name==="City"){
                    val.search={
                        state:"",
                        city:""
                      }
                }else if(val.name==="Fuel"){
                    val.search={
                        state:"",
                        city:"",
                        date:""
                      }
                }else if(val.name==="Branch"){
                    val.search={
                        bank:"",
                        state:"",
                        city:""
                      }
                }else{
                    val.search=""
                }
            }
            return val
        })
        setCommonSearch(src)
    }

    useEffect(() => {
        tokenAuth()
        getData()
        clearSearchFunc("Mutual")
    }, [])
    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3 className='my-1'>Mutual Fund</h3>
                <Row className='align-items-center justify-content-end'>
                    <Col md="auto" xs={6}>
                        <Form.Select onChange={e => { setSize(Number(e.target.value)); setCurrent(1) }} className="my-1">
                        {
                            [10, 25, 50, 100].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                            ))
                        }
                        </Form.Select>
                    </Col>
                    <Col md="auto">
                        <Form.Control type="search" placeholder="Search By Name" value={commonSearch[7].search} onChange={(e) => requestSearch(e.target.value,sData)} className="form-control my-1" />
                    </Col>
                    <Col md="auto">
                        <Link className="btn btn-primary my-1" to="/mutualfund/add">Add New</Link>
                    </Col>
                </Row>
            </div>
            <div className="page-content">
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Body>
                                <DragDropContext onDragEnd={handleDragEnd}>
                                    {
                                        loading ? <div className='loader table-loader' ></div>:<></>
                                    }
                                    <Table bordered responsive>
                                        <thead>
                                            <tr>
                                                <th width='5%'>
                                                <div className='table-sort-filter justify-content-center'>
                                                    No
                                                    {/* <span className='table-sort'>
                                                        <div className={`sort-down ${iconcoror === "id_down" ? "active" : ""}`} id="id_down" onClick={(e) => { sorting('_id', "", "DSC", e) }} ></div>
                                                        <div className={`sort-up ${iconcoror === "id_up" ? "active" : ""}`} id="id_up" onClick={(e) => { sorting('_id', "", "ASC", e) }} ></div>
                                                    </span> */}
                                                </div>
                                                </th>
                                                <th width='50%'>
                                                <div className='table-sort-filter'>
                                                    Name
                                                    <span className='table-sort'>
                                                        <div className={`sort-down ${iconcoror === "name_down" ? "active" : ""}`} id="name_down" onClick={(e) => { sorting('name', "", "DSC", e) }} ></div>
                                                        <div className={`sort-up ${iconcoror === "name_up" ? "active" : ""}`} id="name_up" onClick={(e) => { sorting('name', "", "ASC", e) }} ></div>
                                                    </span>
                                                </div>
                                                </th>
                                                <th width="10%" className="text-center">Image</th>
                                                <th width='10%'>
                                                <div className='table-sort-filter justify-content-center'>
                                                    Status
                                                    <span className='table-sort'>
                                                        <div className={`sort-down ${iconcoror === "status_down" ? "active" : ""}`} id="status_down" onClick={(e) => { sorting('status', "", "DSC", e) }} ></div>
                                                        <div className={`sort-up ${iconcoror === "status_up" ? "active" : ""}`} id="status_up" onClick={(e) => { sorting('status', "", "ASC", e) }} ></div>
                                                    </span>
                                                </div>    
                                                </th>
                                                <th width='10%'>
                                                <div className='table-sort-filter justify-content-center'>
                                                    Show
                                                    <span className='table-sort'>
                                                        <div className={`sort-down ${iconcoror === "show_down" ? "active" : ""}`} id="show_down" onClick={(e) => { sorting('show', "", "DSC", e) }} ></div>
                                                        <div className={`sort-up ${iconcoror === "show_up" ? "active" : ""}`} id="show_up" onClick={(e) => { sorting('show', "", "ASC", e) }} ></div>
                                                    </span>
                                                </div>    
                                                </th>
                                                <th width='10%' className='text-center'>Action</th>
                                            </tr>
                                        </thead>
                                        <Droppable droppableId="droppable-1">
                                            {(provider) => (
                                                <tbody ref={provider.innerRef} {...provider.droppableProps}>
                                                    {
                                                        wetData(current, size).map((dataVal, ind) => {
                                                            return(
                                                            <Draggable key={dataVal._id} draggableId={dataVal._id} index={ind}>
                                                                {(provider) => (
                                                                    <tr key={ind} {...provider.draggableProps} ref={provider.innerRef} {...provider.dragHandleProps}>
                                                                        {/* <td className='text-center'>{oid.indexOf(dataVal._id)+1}</td> */}
                                                                        <td className='text-center'>{(current === 1) ? ind + 1 : current * size + ind + 1 - size}</td>
                                                                        <td>{dataVal.name}</td>
                                                                        <td className='text-center'>
                                                                            <a data-fancybox="gallery" href={dataVal.image}>
                                                                                <img src={dataVal.image} className="hv-40" alt="img" />
                                                                            </a>
                                                                        </td>
                                                                        <td className='text-center'>
                                                                            <BootstrapSwitchButton checked={dataVal.status === 1 ? true : false} onChange={(e) => { change(dataVal._id, e,"status") }} onlabel='Enable' offlabel='Disable' size="sm" width={100} />
                                                                        </td>
                                                                        <td className='text-center'>
                                                                            <BootstrapSwitchButton checked={dataVal.show === 1 ? true : false} onChange={(e) => { change(dataVal._id, e,"show") }} onlabel='Enable' offlabel='Disable' size="sm" width={100} />
                                                                        </td>
                                                                        <td className='text-center'>
                                                                            <Link className="btn btn-outline-primary me-2 btn-icon" size="sm" to={`/mutualfund/edit/${dataVal._id}`}><i className='bx bx-edit-alt'></i></Link>
                                                                            <Button variant="outline-danger" size="sm" className="btn-icon" onClick={() => deleteUser(dataVal._id)}><i className='bx bx-trash-alt' ></i></Button>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </Draggable>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        dataLength === 0 ? <tr>
                                                            <td colSpan="100%" class="p-0">
                                                                <div className='no-found'>
                                                                    <img src="../../not-found/mutual-funds.svg" alt='Data Not Found'/>
                                                                    <p>No Found Mutual Fund List</p>
                                                                </div>
                                                            </td>
                                                        </tr> : ""
                                                    }
                                                </tbody>
                                            )}
                                        </Droppable>
                                    </Table>
                                    {
                                        data.length > size ?
                                            <div className="pagination-custom">
                                                <Pagination showTitle={false}
                                                    className="pagination-data"
                                                    onChange={PaginationChange}
                                                    total={data.length}
                                                    current={current}
                                                    pageSize={size}
                                                    showSizeChanger={false}
                                                    itemRender={PrevNextArrow}
                                                    onShowSizeChange={PerPageChange}
                                                />
                                            </div> : ""
                                    }
                                </DragDropContext>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Layout>
    )
}

export default MutualFund