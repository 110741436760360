import React, { useEffect, useState } from 'react'
import { Breadcrumb, Row, Col, Card, Form, Button, InputGroup } from 'react-bootstrap'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Layout from '../../layout/Layout'
import { API } from '../../App';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie'

const MutualFundCompanyAdd = () => {

    const tokenAuth = async () => {
        const token = Cookies.get('jwt')
        const response = await API.post("/home", {}, { headers: { "Authorization": `Bearer ${token}` } })
        if (response.data.status === false) {
            navigate("/")
        }
    }
    const [validate,setValidate] = useState(false)
    const navigate = useNavigate()
    const [inpData, setInpData] = useState({
        Name: "",
        Status: 1
    })

    const getValue = (e) => {
        setInpData({ ...inpData, [e.target.name]: e.target.value })
    }

    const [selectedImage, setSelectedImage] = useState();

    const onImags = (event) => {
        console.log(event.target.files[0]);
        setSelectedImage(event.target.files[0]);
    }

    const saveData = async () => {
        if (inpData.Name === "" || selectedImage === undefined) {
            setValidate(true)
        } else {
            console.log(selectedImage);
            const Form = new FormData()
            Form.append('name', inpData.Name)
            Form.append('status', inpData.Status)
            Form.append('image', selectedImage)
            const token = Cookies.get('jwt')
            const response = await API.post("/add/amc/company", Form, { headers: { "Authorization": `Bearer ${token}` } })
            if (response.data.status === true) {
                toast.success("Company added Succesfully")
                navigate("/mutualfundcompany")
            } else {
                if (response.data.response_code === 429) {
                    navigate("/")
                } else {
                    toast.error('something went wrong')
                }
            }
        }
    }

    useEffect(() => {
        tokenAuth()
    }, [])

    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3>Add Mutual Fund Company</h3>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/mutualfundcompany">Mutual Fund Company List</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Add New Mutual Fund Company</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="page-content">
            <Form noValidate validated={validate}>
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col lg={4} sm={12}>
                                        <Form.Group>
                                            <Form.Label htmlFor='state-name'>Company Name</Form.Label>
                                            <Form.Control type="text" className="my-2" id='state-name' value={inpData.Name} name="Name" onChange={(e) => { getValue(e) }} placeholder="Enter Your Company Name" required/>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4} sm={12}>
                                        <Form.Group>
                                            <Form.Label htmlFor='state-name'>Image</Form.Label>
                                            <InputGroup className="my-2">
                                                <Form.Control type="file" id='state-name' onChange={(e) => { onImags(e) }} placeholder="Enter Your Name" required/>
                                                {selectedImage && (
                                                    <img src={URL.createObjectURL(selectedImage)} alt="img" height={40} />
                                                )}
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4} sm={12} >
                                        <Form.Group>
                                            <Form.Label className="d-block mb-2" htmlFor='bank-status'>Status </Form.Label>
                                            <BootstrapSwitchButton id="bank-status" checked={inpData.Status === 1 ? true : false} onChange={(checked) => { if (checked === true) { setInpData({ ...inpData, Status: 1 }) } else { setInpData({ ...inpData, Status: 0 }) } }} onlabel='Enable' offlabel='Disable' width={125} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer className="text-end">
                                <Button variant="primary" className="me-3" onClick={saveData}>Save</Button>
                                <Link to="/mutualfundcompany">
                                    <Button variant="secondary">Cancle</Button>
                                </Link>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Form>
            </div>
        </Layout>
    )
}

export default MutualFundCompanyAdd