import React from 'react'
import { Breadcrumb, Card, Col, Row, Form, Button } from 'react-bootstrap'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Layout from '../../layout/Layout'
import { API } from '../../App'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {toast} from 'react-toastify'
import Cookies from 'js-cookie'


const DistrictEdit = () => {
  const tokenAuth = async () => {
    const token = Cookies.get('jwt')
    const response = await API.post("/home", {}, { headers: { "Authorization": `Bearer ${token}` } })
    if (response.data.status === false) {
      navigate("/")
    }
  }
  const [validate,setValidate] = useState(false)
  const navigate = useNavigate()
  const { _id } = useParams()
  const [data, setData] = useState([])
  const [user, setUser] = useState({
    name: "",
    stateId: "",
    status: "",
    id: ""
  })
  const getData = async () => {
    const token = Cookies.get('jwt')
    const cityData = await API.post(`/showCity/${_id}`, {}, { headers: { "Authorization": `Bearer ${token}` } })
    if (cityData.data.status === false) {
      if (cityData.data.response_code === 429) {
        navigate("/")
      } else {
        toast.error('Something went wrong')
      }
    } else {
      setUser({
        name: cityData.data.data.name,
        stateId: cityData.data.data.stateId,
        status: cityData.data.data.status === 1 ? true : false,
        id: cityData.data.data._id
      })
    }
    const response = await API.post("/getState", {}, { headers: { "Authorization": `Bearer ${token}` } })
    if (response.data.status === false) {
      if (response.data.response_code === 429) {
        navigate("/")
      } else {
        toast.error('Something went wrong in State List')
      }
    } else {
      setData(response.data.data.data)
    }
  }

  const getVal = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  const change = (e) => {
    setUser({ ...user, ['status']: e })
    console.log(user);
  }

  const saveData = async () => {
    if (user.stateId === "" || user.name === "") {
      if (user.stateId === "") {
        setValidate(true)
      } else {
        setValidate(true)
      }
    } else {
      if(user.name.match(/^[A-Za-z& ]+$/)){
        const Form = new FormData
        Form.append("state", user.stateId)
        Form.append("name", user.name)
        Form.append("status", user.status === true ? 1 : 0)
        const token = Cookies.get('jwt')
        const response = await API.post(`/editCity/${_id}`, Form, { headers: { "Authorization": `Bearer ${token}` } })
        if (response.data.status === true) {
          toast.success('District Updated Succesfully')
          navigate("/district")
        } else {
          if (response.data.response_code === 429) {
            navigate("/")
          } else {
            toast.error('Something went wrong')
          }
        }
      }else{
        toast.warning('Please enter valid District name')
      }
    }
  }


  useEffect(() => {
    tokenAuth()
    getData()
  }, [])
  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3>Edit District</h3>
        <Breadcrumb>
          <Breadcrumb.Item href='/#/home'>
            <i className='bx bx-home-alt me-2 fs-5' ></i> Home
          </Breadcrumb.Item>
          <Breadcrumb.Item href='/#/district'>
            District List
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Edit District</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="page-content">
      <Form noValidate validated={validate}>
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Row className='align-items-center'>
                  <Col lg={4}>
                    <Form.Group>
                      <Form.Label htmlFor='district-sid'>State Name</Form.Label>
                      <Form.Select id="district-sid" className="my-2" name='stateId'  onChange={(e) => { getVal(e) }} placeholder='Select State' required>
                        <option value="">Select State Name</option>
                        {
                          data.map((dataVal, ind) => {
                            return (
                              <option  selected={dataVal._id === user.stateId ? true : false} value={dataVal._id} key={ind}>{dataVal.name}</option>
                            )
                          })
                        }
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={4} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='district-name'>Name</Form.Label>
                      <Form.Control type="text" className="my-2" id='district-name' value={user.name} name="name" onChange={(e) => { getVal(e) }} placeholder="Enter Your Name" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group>
                      <Form.Label className="d-block mb-2" htmlFor='district-status'>Status </Form.Label>
                      <BootstrapSwitchButton id="district-status" checked={user.status} onChange={change} onlabel='Enable' offlabel='Disable' width={125} />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-end">
                <Button variant="primary" className="me-3" onClick={saveData} >Save</Button>
                <Link to="/district">
                  <Button variant="secondary">Cancle</Button>
                </Link>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Form>
      </div>
    </Layout>
  )
}

export default DistrictEdit