import React, { useEffect, useState } from 'react'
import { Breadcrumb, Card, Col, Row, Form, Button, InputGroup } from 'react-bootstrap'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Layout from '../../layout/Layout'
import {toast} from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import { API } from '../../App'
import Cookies from 'js-cookie'
const LoanAdd = () => {
  const tokenAuth = async () => {
    const token = Cookies.get('jwt')
    const response = await API.post("/home", {}, { headers: { "Authorization": `Bearer ${token}` } })
    if (response.data.status === false) {
      navigate("/")
    }
  }
  const [validate,setValidate] = useState(false)
  const navigate = useNavigate()
  const [status, setStatus] = useState(1)
  const [inpData, setInpData] = useState({
    Name: "",
    Description: "",
    About: "",
    Status: "",
    Webview:""
  })

  const [selectedImage, setSelectedImage] = useState();

  const onImags = (event) => {
    console.log(event.target.files[0]);
    setSelectedImage(event.target.files[0]);
  }

  const getValue = (e) => {
    setInpData({ ...inpData, [e.target.name]: e.target.value })
  }

  const saveData = async () => {
    if (inpData.Name === "" || inpData.Description === "" || inpData.About === "" || inpData.Webview==="" || selectedImage === undefined) {
      setValidate(true)
    } else {
      // if(inpData.Name.match(/^[A-Za-z& ]+$/)){
        const Form = new FormData()
        Form.append('name', inpData.Name)
        Form.append('description', inpData.Description)
        Form.append('about', inpData.About)
        Form.append('webview', inpData.Webview)
        Form.append('image', selectedImage)
        Form.append('status', status)
        const token = Cookies.get('jwt')
        const response = await API.post("/addLoan", Form, { headers: { "Authorization": `Bearer ${token}` } })
        if (response.data.status === true) {
          toast.success('Loan Added Succesfully')
          navigate("/loan")
        } else {
          if (response.data.response_code === 429) {
            navigate("/")
          } else {
            toast.error('Something went wrong')
          }
        }
      // }else{
      //   toast.warning('Enter Valid Loan Name')
      // }
    }
  }

  useEffect(() => {
    tokenAuth()
  }, [])

  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3>Add Loan</h3>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/loan">Loan List</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Add New Loan</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="page-content">
      <Form noValidate validated={validate}>
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Row>
                  <Col lg={6} sm={12}>
                  <Form.Group>
                    <Form.Label htmlFor='loan-name'>Name</Form.Label>
                    <Form.Control type="text" className="my-2" id='loan-name' value={inpData.Name} name='Name' onChange={(e) => { getValue(e) }} placeholder="Enter Your Name" required/>
                  </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Label className="d-block mb-2" htmlFor='loan-status'>Status </Form.Label>
                    <BootstrapSwitchButton id="loan-status" checked={status === 1 ? true : false} onChange={(checked) => { if (checked === true) { setStatus(1) } else { setStatus(0) } }} onlabel='Enable' offlabel='Disable' width={125} />
                  </Col>
                  <Col lg={6} sm={12}>
                  <Form.Group>
                    <Form.Label htmlFor='loan-desc'>Description</Form.Label>
                    <Form.Control as="textarea" rows={4} className="my-2" id='loan-desc' value={inpData.Description} name='Description' onChange={(e) => { getValue(e) }} placeholder="Enter Your Description" required/>
                  </Form.Group>
                  </Col>
                  <Col lg={6} sm={12}>
                  <Form.Group>
                    <Form.Label htmlFor='loan-about'>About</Form.Label>
                    <Form.Control as="textarea" rows={4} className="my-2" id='loan-about' value={inpData.About} name='About' onChange={(e) => { getValue(e) }} placeholder="Enter Your About" required/>
                  </Form.Group>
                  </Col>
                  <Col lg={6} sm={12}>
                  <Form.Group>
                    <Form.Label htmlFor='loan-about'>Webview</Form.Label>
                    <Form.Control as="textarea" rows={4} className="my-2" id='loan-about' value={inpData.Webview} name='Webview' onChange={(e) => { getValue(e) }} placeholder="Enter Your Webview" required/>
                  </Form.Group>
                  </Col>
                  <Col lg={4} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='state-name'>Image</Form.Label>
                      <InputGroup className="my-2">
                        <Form.Control type="file" id='state-name' onChange={(e) => { onImags(e) }} placeholder="Enter Your Name" required/>
                        {selectedImage && (
                            <img src={URL.createObjectURL(selectedImage)} alt="Logo" height={40} />
                        )}
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-end">
                <Button variant="primary" className="me-3" onClick={saveData}>Save</Button>
                <Link to="/loan">
                  <Button variant="secondary">Cancle</Button>
                </Link>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Form>
      </div>
    </Layout>
  )
}

export default LoanAdd