import React, { useState } from 'react'
import { Breadcrumb, Row, Col, Card, Form, Button } from 'react-bootstrap'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Layout from '../../layout/Layout'
import { API } from '../../App';
import {toast} from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Cookies from 'js-cookie'

const StateAdd = () => {
  const tokenAuth = async () => {
    const token = Cookies.get('jwt')
    const response = await API.post("/home", {}, { headers: { "Authorization": `Bearer ${token}` } })
    if (response.data.status === false) {
      navigate("/")
    }
  }
  const [validate,setValidate] = useState(false)
  const [status, setStatus] = useState(1)
  const navigate = useNavigate()
  const [inpData, setInpData] = useState({
    name: ""
  })

  const getValue = (e) => {
    setInpData({ ...inpData, [e.target.name]: e.target.value })
  }

  const saveData = async (event) => {
    if (inpData.name === "") {
        setValidate(true)
    }else {
      if(inpData.name.match(/^[A-Za-z& ]+$/)){
        const Form = new FormData()
        Form.append('name', inpData.name)
        Form.append('status', status)
        const token = Cookies.get('jwt')
        const response = await API.post("/addState", Form, { headers: { "Authorization": `Bearer ${token}` } })
        if (response.data.status === true) {
          toast.success('State Added Succesfully')
          navigate("/state")
        } else {
          if (response.data.response_code === 429) {
            navigate("/")
          } else {
            toast.error('Something went wrong')
          }
        }
      }else{
        toast.warning('Please enter valid State name')
      }
    }
  }

  useEffect(() => {
    tokenAuth()
  }, [])

  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3>Add State</h3>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/state">State List</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Add New State</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="page-content">
        <Form noValidate validated={validate}>
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Row>
                  <Col sm={4}>
                    <Form.Group>
                      <Form.Label htmlFor='state-name'>Name</Form.Label>
                      <Form.Control type="text" min={0} className="my-2" id='state-name' name="name" onChange={(e) => { getValue(e) }} placeholder="Enter Your Name" required/>
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group>
                      <Form.Label className="d-block mb-2" htmlFor='state-status'>Status</Form.Label>
                      <BootstrapSwitchButton id="state-status" checked={status === 1 ? true : false} onlabel='Enable' onChange={(checked) => { if (checked === true) { setStatus(1) } else { setStatus(0) } }} offlabel='Disable' width={125} />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-end">
                <Button variant="primary"type='button' className="me-3" onClick={saveData}>Save</Button>
                <Link to="/state">
                  <Button variant="secondary">Cancle</Button>
                </Link>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        </Form>
      </div>
    </Layout>
  )
}

export default StateAdd