import React, { useContext, useState } from 'react'
import { Button, Card, Form, Table, Col, Row } from 'react-bootstrap'
import Layout from '../../layout/Layout'
import { Link, useNavigate } from 'react-router-dom'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Pagination from 'rc-pagination'
import Swal from 'sweetalert2'
import {toast} from 'react-toastify'
import { API, AuthContext } from '../../App'
import { useEffect } from 'react'
import Fancybox from '../../Component/FancyBox';
import { requestSearch1 } from '../Search'
import Cookies from 'js-cookie'
const Bank = () => {
    const { commonSearch,setCommonSearch} = useContext(AuthContext);
    const [dataLength, setDataLength] = useState(1)
    const navigate = useNavigate()
    const tokenAuth = async () => {
        const token = Cookies.get('jwt')
        const response = await API.post("/home", {}, { headers: { "Authorization": `Bearer ${token}` } })
        if (response.data.status === false) {
            navigate("/")
        }
    }
    const [iconcoror, Seticoncoror] = useState("id_up")
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [sData, setSData] = useState([])
    const getData = async () => {
        const token = Cookies.get('jwt')
        const response = await API.post("/getBank",{}, { headers: { "Authorization": `Bearer ${token}` } })
        if (response.data.status === false) {
            if (response.data.response_code === 429) {
                navigate("/")
            } else {
                setData([])
                setSData([])
                setDataLength(0)
                setLoading(false)
            }
        } else {
            setData(response.data.data.data)
            setSData(response.data.data.data)
            setDataLength(1)
            setLoading(false)
            if(commonSearch[2].search!==""){
                requestSearch(commonSearch[2].search,response.data.data.data)
            }
        }
    }

    const change = async (id, e, field) => {
        var Status;
        Status = (e === true) ? 1 : 0
        const Form = new FormData()
        // console.log(Status)
        Form.append('id', id)
        Form.append('field', field)
        Form.append('status', Status)
        const token = Cookies.get('jwt')
        const update = await API.post(`/bankStatus/${id}`, Form, { headers: { "Authorization": `Bearer ${token}` } })
        if (update.data.status === false) {
            if (update.data.response_code === 429) {
                navigate("/")
            } else {
                toast.error('Something went wrong')
            }
        }else{
            var statusData = data.findIndex(x => x._id ===id);
            data[statusData][field]=Status
            toast.success('Status Updated')
        }
    }

    const deleteUser = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger me-2'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                const token = Cookies.get('jwt')
                const delBank = await API.post(`/deleteBank/${id}`, {}, { headers: { "Authorization": `Bearer ${token}` } })
                if (delBank.data.status === false) {
                    if (delBank.data.response_code === 429) {
                        navigate("/")
                    } else {
                        swalWithBootstrapButtons.fire(
                            'Cancelled',
                            `${delBank.data.response_message}`,
                            'error'
                        )
                    }
                } else {
                    getData()
                    setCurrent(1)
                }

            } 
        })
    }

    const requestSearch = (searchedVal,mainData) => {
        // setBankSearch(searchedVal)
        let srcVal = [...commonSearch]
        srcVal[2].search = searchedVal
        setCommonSearch(srcVal)
        const searchData = requestSearch1(searchedVal,mainData,mainData,"name")
        console.log(searchData,"ser");
        if(searchData.length===0){
            setData([])
            setDataLength(0)
        }else{
            setData(searchData)
            setDataLength(1)
        }
        setCurrent(1)
    };

    const sorting = (col, type = "string", order, e) => {
        Seticoncoror(e.target.id)
        if (order === "ASC") {
          const sorted = [...data].sort((a, b) =>
            a[col] > b[col] ? 1 : -1
          );
          if (iconcoror !== e.target.id) {
            setData(sorted)
          }
        }
        if (order === "DSC") {
          const sorted = [...data].sort((a, b) =>
            a[col] < b[col] ? 1 : -1
          );
          if (iconcoror !== e.target.id) {
            setData(sorted)
          }
        }
    }

    //ClearSearchFunction
    const clearSearchFunc = (searchEle)=>{
        let src = [...commonSearch]
        src.map((val)=>{
            if(val.name!==searchEle){
                if(val.name==="City"){
                    val.search={
                        state:"",
                        city:""
                      }
                }else if(val.name==="Fuel"){
                    val.search={
                        state:"",
                        city:"",
                        date:""
                      }
                }else if(val.name==="Branch"){
                    val.search={
                        bank:"",
                        state:"",
                        city:""
                      }
                }else{
                    val.search=""
                }
            }
            return val
        })
        setCommonSearch(src)
    }

    useEffect(() => {
        tokenAuth()
        getData()
        clearSearchFunc("Bank")
    }, [])

    // Paggintion Code // 
    const [size, setSize] = useState(10);
    const [current, setCurrent] = useState(1);

    const wetData = (current, pageSize) => {
        return data.slice((current - 1) * pageSize, current * pageSize);
    };

    const PerPageChange = (value) => {
        console.log(value)
        setSize(value);
        console.log(size)
        const newPerPage = Math.ceil(data.length / value);
        console.log(newPerPage);
        if (current > newPerPage) {
            setCurrent(newPerPage);
        }
    }

    const PaginationChange = (page, pageSize) => {
        console.log(page);
        console.log(pageSize);
        setCurrent(page);
        setSize(pageSize)
    }


    const PrevNextArrow = (current, type, originalElement) => {
        if (type === 'prev') {
            return <button className='paggination-btn'>Previous</button>;
        }
        if (type === 'next') {
            return <button className='paggination-btn'>Next</button>;
        }
        return originalElement;
    }

    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3 className='my-1'>Bank</h3>
                <Row className='align-items-center justify-content-end'>
                    <Col md="auto" xs={6}>
                        <Form.Select onChange={e => { setSize(Number(e.target.value)); setCurrent(1) }} className="my-1">
                        {
                            [10, 25, 50, 100].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                            ))
                        }
                        </Form.Select>
                    </Col>
                    <Col md="auto">
                        <Form.Control type="search" placeholder="Search By Name" value={commonSearch[2].search} onChange={(e) => requestSearch(e.target.value,sData)} className="form-control my-1" />
                    </Col>
                    <Col md="auto">
                        <Link className="btn btn-primary my-1" to="/bank/add">Add New</Link>
                    </Col>
                </Row>
            </div>
            <div className="page-content">
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Body>
                                <Fancybox>
                                        {
                                            loading ? <div className='loader table-loader' ></div> : <></>
                                        }
                                    <Table bordered responsive>
                                        <thead>
                                            <tr>
                                                <th width='5%'>
                                                <div className='table-sort-filter justify-content-center'>
                                                    No 
                                                    {/* <span className='table-sort'>
                                                        <div className={`sort-down ${iconcoror === "id_down" ? "active" : ""}`} id="id_down" onClick={(e) => { sorting('_id', "", "DSC", e) }} ></div>
                                                        <div className={`sort-up ${iconcoror === "id_up" ? "active" : ""}`} id="id_up" onClick={(e) => { sorting('_id', "", "ASC", e) }} ></div>
                                                    </span> */}
                                                </div>
                                                </th>
                                                <th width='55%'>
                                                <div className='table-sort-filter'>
                                                    Name 
                                                    <span className='table-sort'>
                                                        <div className={`sort-down ${iconcoror === "name_down" ? "active" : ""}`} id="name_down" onClick={(e) => { sorting('name', "", "DSC", e) }} ></div>
                                                        <div className={`sort-up ${iconcoror === "name_up" ? "active" : ""}`} id="name_up" onClick={(e) => { sorting('name', "", "ASC", e) }} ></div>
                                                    </span>
                                                </div>
                                                </th>
                                                <th width='10%' className='text-center'>Image</th>
                                                <th width='10%'>
                                                <div className='table-sort-filter justify-content-center'>
                                                    Status
                                                    <span className='table-sort'>
                                                        <div className={`sort-down ${iconcoror === "status_down" ? "active" : ""}`} id="status_down" onClick={(e) => { sorting('status', "", "DSC", e) }} ></div>
                                                        <div className={`sort-up ${iconcoror === "status_up" ? "active" : ""}`} id="status_up" onClick={(e) => { sorting('status', "", "ASC", e) }} ></div>
                                                    </span>
                                                </div>    
                                                </th>
                                                <th width='10%'>
                                                <div className='table-sort-filter justify-content-center'>
                                                    Loan Status
                                                    <span className='table-sort'>
                                                        <div className={`sort-down ${iconcoror === "loanStatus_down" ? "active" : ""}`} id="loanStatus_down" onClick={(e) => { sorting('loanStatus', "", "DSC", e) }} ></div>
                                                        <div className={`sort-up ${iconcoror === "loanStatus_up" ? "active" : ""}`} id="loanStatus_up" onClick={(e) => { sorting('loanStatus', "", "ASC", e) }} ></div>
                                                    </span>
                                                </div>    
                                                </th>
                                                <th width='10%' className='text-center'>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                wetData(current, size).map((dataVal, ind) => {
                                                    return (
                                                        <tr key={ind}>
                                                            <td className='text-center'>{(current === 1) ? ind + 1 : current * size + ind + 1 - size}</td>
                                                            {/* <td className='text-center'>{oid.indexOf(dataVal._id)+1}</td> */}
                                                            <td>{dataVal.name}</td>
                                                            <td className='text-center'>
                                                                <a data-fancybox="gallery" href={dataVal.image}>
                                                                    <img src={dataVal.image} className="hv-40" alt="img" />
                                                                </a>
                                                            </td>
                                                            <td className='text-center'>
                                                                <BootstrapSwitchButton checked={dataVal.status === 1 ? true : false} onChange={(e) => { change(dataVal._id, e, "status") }} onlabel='Enable' offlabel='Disable' size="sm" width={100} />
                                                            </td>
                                                            <td className='text-center'>
                                                                <BootstrapSwitchButton checked={dataVal.loanStatus === 1 ? true : false} onChange={(e) => { change(dataVal._id, e, "loanStatus") }} onlabel='Enable' offlabel='Disable' size="sm" width={100} />
                                                            </td>
                                                            <td className='text-center'>
                                                                <Link className="btn btn-outline-primary me-2 btn-icon" size="sm" to={`/bank/edit/${dataVal._id}`}><i className='bx bx-edit-alt'></i></Link>
                                                                <Button variant="outline-danger" size="sm" className="btn-icon" onClick={() => deleteUser(dataVal._id)}><i className='bx bx-trash-alt' ></i></Button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            {
                                                dataLength === 0 ? <tr>
                                                    <td colSpan="100%" className="p-0">
                                                        <div className='no-found'>
                                                            <img src="../../not-found/bank-loan.svg" alt='Data Not Found'/>
                                                            <p>No Found Bank</p>
                                                        </div>
                                                    </td>
                                                </tr> : ""
                                            }
                                        </tbody>
                                    </Table>
                                    {data.length > size ?
                                        <div className="pagination-custom">
                                            <Pagination showTitle={false}
                                                className="pagination-data"
                                                onChange={PaginationChange}
                                                total={data.length}
                                                current={current}
                                                pageSize={size}
                                                showSizeChanger={false}
                                                itemRender={PrevNextArrow}
                                                onShowSizeChange={PerPageChange}
                                            />
                                        </div> : ""
                                    }
                                </Fancybox>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Layout>
    )
}

export default Bank