import React, { useState } from 'react'
import { Breadcrumb, Row, Col, Card, Form, Button} from 'react-bootstrap'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Layout from '../../layout/Layout'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { API } from '../../App';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie'

const StateEdit = () => {
  const tokenAuth = async() => {
    const token = Cookies.get('jwt')
    const response = await API.post("/home",{},{headers:{"Authorization" : `Bearer ${token}`}})
    if(response.data.status===false){
        navigate("/")
    }
  }
  const [validate,setValidate] = useState(false)
  const navigate = useNavigate()
  const {_id} = useParams()
  const [user,setUser] = useState({
    Module:"",
    Api:"",
    Version:"",
    id:""
  })

  const getData = async() => {
    const token = Cookies.get('jwt')
    const stateData = await API.post(`/show/version/${_id}`,{},{headers:{"Authorization" : `Bearer ${token}`}})
    setUser({
      Module:stateData.data.data.moduleName,
      Api:stateData.data.data.apiName,
      Version:stateData.data.data.version,
      id:stateData.data.data._id
    })
  }

  const getVal = (e) => {
    setUser({...user,[e.target.name]:e.target.value})
  }


  const saveData = async() => {
    if(user.Module==="" || user.Api==="" || user.Version===""){
      setValidate(true)
    }else{
      if(user.Module.match(/^[A-Za-z& ]+$/)){
        const Form = new FormData
        Form.append("moduleName",user.Module)
        Form.append("apiName",user.Api)
        Form.append("version",user.Version)
        const token = Cookies.get('jwt')
        const response = await API.post(`/edit/version/${_id}`,Form,{headers:{"Authorization" : `Bearer ${token}`}})
        if(response.data.status===true){
          toast.success("Version Updated Succesfully")
          navigate("/version")
        }else{
          if(response.data.response_code===429){
            navigate("/")
          }else{
            toast.error('something went wrong')
          }
        }
      }else{
        toast.warning('please enter valid Module Name')
      }
    }
  }

  useEffect(()=>{
    tokenAuth()
    getData()
  },[])

  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3>Edit Version</h3>
        <Breadcrumb>
          <Breadcrumb.Item><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Breadcrumb.Item>
          <Breadcrumb.Item>Version List</Breadcrumb.Item>
          <Breadcrumb.Item active>Edit Version</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="page-content">
      <Form noValidate validated={validate}>
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <Form.Group>
                      <Form.Label htmlFor='state-name'>Module</Form.Label>
                      <Form.Control type="text" min={0} className="my-2" id='state-name' value={user.Module} onChange={(e)=>{getVal(e)}} name="Module" placeholder="Enter Module Name" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group>
                      <Form.Label htmlFor='state-name'>API</Form.Label>
                      <Form.Control type="text" min={0} className="my-2" id='state-name' value={user.Api} onChange={(e)=>{getVal(e)}} name="Api" placeholder="Enter API" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group>
                      <Form.Label htmlFor='state-name'>Version</Form.Label>
                      <Form.Control type="number" min={0} className="my-2" id='state-name' value={user.Version} onChange={(e)=>{getVal(e)}} name="Version" placeholder="Enter Version" required/>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-end">
                <Button variant="primary" className="me-3" onClick={saveData}>Save</Button>
                <Link to="/version">
                <Button variant="secondary">Cancle</Button>
                </Link>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Form>
      </div>
    </Layout>
  )
}

export default StateEdit