import Cookies from "js-cookie";
import { API } from "../App";

export const SearchFunction = async(api,search)=>{
    try{
        const token = Cookies.get('jwt')
        const res = await API.post(api,search,{ headers: { "Authorization": `Bearer ${token}` } })
        if(res.data.status===true){
            return res.data.data
        }else{
            return []
        }
    }catch(err){
        console.log(err);
        return []
    }
}