import React, { useEffect, useState } from 'react'
import { Breadcrumb, Row, Col, Card, Form, Button } from 'react-bootstrap'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Layout from '../../layout/Layout'
import { API } from '../../App';
import { Link, useNavigate, useParams } from 'react-router-dom';
import $ from "jquery"
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

const AmcDetailEdit = () => {
    let fundNum = 0
    const tokenAuth = async () => {
        const token = Cookies.get('jwt')
        const response = await API.post("/home", {}, { headers: { "Authorization": `Bearer ${token}` } })
        if (response.data.status === false) {
            navigate("/")
        }
    }
    const navigate = useNavigate()
    const { _id } = useParams()
    const [data, setData] = useState([])
    let [status, setStatus] = useState(1)
    const [field, setField] = useState([])
    const [user, setUser] = useState({
        CompanyId: "",
        About: "",
        KeyInfo: "",
        HowCan: "",
        FundManager: "",
        CloserLook: "",
        status: "",
        id: ""
    })
    const getData = async () => {
        const token = Cookies.get('jwt')
        const amcDet = await API.post(`/show/amc/detail/${_id}`, {}, { headers: { "Authorization": `Bearer ${token}` } })
        if (amcDet.data.status === false) {
            if (amcDet.data.response_code === 429) {
                navigate("/")
            } else {
                toast.error('something went wrong')
            }
        } else {
            setUser({
                CompanyId: amcDet.data.data.detail.companyId,
                About: amcDet.data.data.detail.about,
                KeyInfo: amcDet.data.data.detail.keyInfo,
                HowCan: amcDet.data.data.detail.howCanInvest,
                FundManager: amcDet.data.data.detail.topFundMan,
                CloserLook: amcDet.data.data.detail.closerLook,
                status: amcDet.data.data.detail.status,
                id: amcDet.data.data.detail._id
            })
            setStatus(amcDet.data.data.detail.status)
            setFund(amcDet.data.data.topFund)
            setKey(amcDet.data.data.detail.keyInfo)
            setCloser(amcDet.data.data.detail.closerLook)
        }
        const response = await API.post("/amc/company/list", {}, { headers: { "Authorization": `Bearer ${token}` } })
        if (response.data.status === false) {
            if (response.data.response_code === 429) {
                navigate("/")
            } else {
                toast.error('something went wrong in Amc Company list')
            }
        } else {
            setData(response.data.data.data)
        }
        const fieldRes = await API.post("/get/amc/field", {}, { headers: { "Authorization": `Bearer ${token}` } })
        if (fieldRes.data.status === false) {
            if (fieldRes.data.response_code === 429) {
                navigate("/")
            } else {
                toast.error('something went wrong in Amc Field')
            }
        } else {
            setField(fieldRes.data.data.data)
        }
    }

    const getVal = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value })
    }

    //top performing fund
    const [fund, setFund] = useState([])
    const addFund = (tag1, name) => {
        fundNum++
        const fundTag = {
            fundName: "",
            category: "",
            risk: "",
            oneY: "",
            rating: "",
            fundSize: "",
            searchId: "",
            status: 1,
            _id: fundNum
        }
        setFund([...fund, fundTag])
    }
    const [delFund, setDelFund] = useState([])
    const removeFund = (index, id) => {
        setDelFund([...delFund, id])
        const data = [...fund]
        data.splice(index, 1)
        setFund(data)
    }

    const fundChange = (e, index) => {
        let data = [...fund];
        data[index][e.target.name] = e.target.value
        setFund(data)
    }

    const fundStatus = (e, index) => {
        let data = [...fund];
        data[index]['status'] = e === true ? 1 : 0
        setFund(data)
    }

    //key value

    const [key, setKey] = useState([])
    const addKey = (tag1, name) => {
        const keyTag = {
            keyName: "",
            keyValue: ""
        }
        setKey([...key, keyTag])
    }

    const removeKey = (index) => {
        const data = [...key]
        data.splice(index, 1)
        setKey(data)
    }

    const keyChange = (e, index) => {
        let data = [...key];
        data[index][e.target.name] = e.target.value
        setKey(data)
    }

    //closerv look

    const [closer, setCloser] = useState([])
    const addCloser = (tag1, name) => {
        const closerTag = {
            SchemeName: "",
            fundPerform: "",
            minInvAmt: "",
            minInvestmentAmt: "",
            AUM: "",
            oneYreturn: ""
        }
        setCloser([...closer, closerTag])
    }

    const removeCloser = (index) => {
        const data = [...closer]
        data.splice(index, 1)
        setCloser(data)
    }

    const closerChange = (e, index) => {
        let data = [...closer];
        data[index][e.target.name] = e.target.value
        setCloser(data)
    }

    const saveData = async () => {
        if (user.CompanyId === "") {
            toast.warning("please select company")
        } else {
            if (user.About === "" || user.HowCan === "" || user.FundManager === "") {
                toast.warning("please fill About fund, How can invest and Top fund Manager field")
            } else {
                let fundCounter = 0
                fund.map((fundVal) => {
                    if (fundVal.fundName === "" || fundVal.category === "" || fundVal.risk === "" || fundVal.oneY === "" || fundVal.rating === "" ||
                        fundVal.fundSize === "" || fundVal.searchId === "") {
                        fundCounter++
                    }
                })
                if (fundCounter === 0) {
                    let keyCounter = 0
                    key.map((keyVal) => {
                        if (keyVal.keyName === "" || keyVal.keyValue === "") {
                            keyCounter++
                        }
                    })
                    if (keyCounter === 0) {
                        let closerCounter = 0
                        closer.map((closerVal) => {
                            if (closerVal.SchemeName === "" || closerVal.fundPerform === "" || closerVal.minInvAmt === "" ||
                                closerVal.minInvestmentAmt === "" || closerVal.oneYreturn === "" || closerVal.AUM === "") {
                                closerCounter++
                            }
                        })
                        if (closerCounter === 0) {
                            const Form = new FormData()
                            Form.append('companyId', user.CompanyId)
                            Form.append('about', user.About)
                            Form.append('topFund', JSON.stringify(fund))
                            Form.append('keyInfo', JSON.stringify(key))
                            Form.append('how', user.HowCan)
                            Form.append('manager', user.FundManager)
                            Form.append('closer', JSON.stringify(closer))
                            Form.append('status', status)
                            Form.append('delFund', JSON.stringify(delFund))
                            const token = Cookies.get('jwt')
                            const response = await API.post(`/edit/amc/company/detail/${_id}`, Form, { headers: { "Authorization": `Bearer ${token}` } })
                            if (response.data.status === true) {
                                toast.success("Amc Detail updated succesfully")
                                navigate("/amcdetail")
                            } else {
                                if (response.data.response_code === 429) {
                                    navigate("/")
                                } else {
                                    toast.error(response.data.response_message)
                                }
                            }
                        } else {
                            toast.warning("please fill all fields in Fund Closer Look")
                        }
                    } else {
                        toast.warning("please fill all fields in Key Information")
                    }
                } else {
                    toast.warning("all fields in Top Performing Fund are required")
                }
            }
        }

    }

    useEffect(() => {
        tokenAuth()
        getData()
    }, [])

    const [button, setButton] = useState("")
    $(function () {
        var select = $('#select');
        var selected = $('#selected');
        select.on('change', function () {
            var selectedOptionText = $(this).children(':selected').text();
            selected.text(selectedOptionText);
            // if(selectedOptionText === "Top Performing Mutual Fund"){
            //     console.log(fund,"fund");
            //     if(fund.length===0){
            //         addFund()
            //     }
            // }
            // if(selectedOptionText === "Key Information"){
            //     if(key.length===0){
            //         addKey()
            //     }
            // }
            // if(selectedOptionText === "Lets's Have a Closer Look"){
            //     if(closer.length===0){
            //         addCloser()
            //     }
            // }
            setButton(selectedOptionText)
            selectedOptionText === "About Mutual Fund" ? document.getElementById("typed1").style.display = "block" : document.getElementById("typed1").style.display = "none"
            selectedOptionText === "Top Performing Mutual Fund" ? document.getElementById("typed2").style.display = "block" : document.getElementById("typed2").style.display = "none"
            selectedOptionText === "Key Information" ? document.getElementById("typed3").style.display = "block" : document.getElementById("typed3").style.display = "none"
            selectedOptionText === "How Can You Invest ?" ? document.getElementById("typed4").style.display = "block" : document.getElementById("typed4").style.display = "none"
            selectedOptionText === "Top Fund Managers" ? document.getElementById("typed5").style.display = "block" : document.getElementById("typed5").style.display = "none"
            selectedOptionText === "Lets's Have a Closer Look" ? document.getElementById("typed6").style.display = "block" : document.getElementById("typed6").style.display = "none"

        });
    });

    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3>Edit AMC Details</h3>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/amcdetail">AMC Details List</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Edit Details AMC</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="page-content">
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Body>
                                {/* 1. About The Axixs Mutual Fund */}
                                <Row>
                                    <Col lg={3} sm={12}>
                                        <Form.Label htmlFor='c-name'>Company Name</Form.Label>
                                        <Form.Select id="c-name" className='my-2' name="CompanyId" onChange={(e) => { getVal(e) }}>
                                            <option value="">Select Company Name</option>
                                            {
                                                data.map((dataVal, ind) => {
                                                    return (
                                                        <option selected={dataVal._id === user.CompanyId ? true : false} value={dataVal._id} key={ind}>{dataVal.name}</option>
                                                    )
                                                })
                                            }
                                        </Form.Select>
                                    </Col>

                                    <Col lg={3} sm={12}>
                                        <Form.Label htmlFor='c-name'>Fields</Form.Label>
                                        <Form.Select id="select" className='my-2'>
                                            <option value="">Select Field Name</option>
                                            {
                                                field.map((dataVal, ind) => {
                                                    return (
                                                        <option value={dataVal.name} key={ind}>{dataVal.name}</option>
                                                    )
                                                })
                                            }
                                        </Form.Select>
                                    </Col>
                                    {
                                        (button === "Top Performing Mutual Fund") ? <Col sm={2} className='d-flex align-self-end'>
                                            <Button variant="primary" size="sm" className="btn-icon my-3" onClick={addFund}><i class='bx bx-plus'></i></Button>
                                        </Col> : ""
                                    }
                                    {
                                        (button === "Key Information") ? <Col sm={2} className='d-flex align-self-end'>
                                            <Button variant="primary" size="sm" className="btn-icon my-3" onClick={addKey}><i class='bx bx-plus'></i></Button>
                                        </Col> : ""
                                    }
                                    {
                                        (button === "Lets's Have a Closer Look") ? <Col sm={2} className='d-flex align-self-end'>
                                            <Button variant="primary" size="l" className="btn-icon my-3" onClick={addCloser}><i class='bx bx-plus'></i></Button>
                                        </Col> : ""
                                    }
                                    <Col lg={4}>
                                        <Form.Label className="d-block mb-2" htmlFor='district-status'>Status </Form.Label>
                                        <BootstrapSwitchButton id="district-status" checked={user.status === 1 ? true : false} onlabel='Enable' onChange={(checked) => { if (checked === true) { setStatus(1) } else { setStatus(0) } }} offlabel='Disable' width={125} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} id="typed1" style={{ display: "none" }}>
                                        <Form.Label htmlFor='a-data'>About Data</Form.Label>
                                        <Form.Control as="textarea" rows={4} className="my-2" id='a-data' value={user.About} name="About" onChange={(e) => { getVal(e) }} placeholder="Enter Your Data" />
                                    </Col>
                                </Row>
                                {/* 2.Top Performing Mutual Fund */}
                                <Row id='typed2' style={{ display: "none" }}>
                                    <Col sm={12}>
                                        {
                                            fund.map((fundVal, ind) => {
                                                return (
                                                    <Row key={ind}>
                                                        <Col lg={3} sm={12}>
                                                            <Form.Label htmlFor='f-name'>Fund Name</Form.Label>
                                                            <Form.Control type="text" className="my-2" id='f-name' value={fundVal.fundName} name='fundName' onChange={(e) => { fundChange(e, ind) }} placeholder="Enter Your Fund Name" required />
                                                        </Col>
                                                        <Col lg={3} sm={12}>
                                                            <Form.Label htmlFor='category'>Category</Form.Label>
                                                            <Form.Control type="text" className="my-2" id='category' value={fundVal.category} name='category' onChange={(e) => { fundChange(e, ind) }} placeholder="Enter Your Category" required />
                                                        </Col>
                                                        <Col lg={3} sm={12}>
                                                            <Form.Label htmlFor='risk'>Risk</Form.Label>
                                                            <Form.Control type="text" className="my-2" id='risk' value={fundVal.risk} name='risk' onChange={(e) => { fundChange(e, ind) }} placeholder="Enter Your Risk" required />
                                                        </Col>
                                                        <Col lg={3} sm={12}>
                                                            <Form.Label htmlFor='one-year'>One Year</Form.Label>
                                                            <Form.Control type="text" className="my-2" id='one-year' value={fundVal.oneY} name='oneY' onChange={(e) => { fundChange(e, ind) }} placeholder="Enter Year" required />
                                                        </Col>
                                                        <Col lg={3} sm={12}>
                                                            <Form.Label htmlFor='rating'>Rating</Form.Label>
                                                            <Form.Control type="text" className="my-2" id='rating' value={fundVal.rating} name='rating' onChange={(e) => { fundChange(e, ind) }} placeholder="Enter Rating" required />
                                                        </Col>
                                                        <Col lg={3} sm={12}>
                                                            <Form.Label htmlFor='fund-size'>Fund Size</Form.Label>
                                                            <Form.Control type="text" className="my-2" id='fund-size' value={fundVal.fundSize} name='fundSize' onChange={(e) => { fundChange(e, ind) }} placeholder="Enter Fund Size" required />
                                                        </Col>
                                                        <Col lg={3} sm={12}>
                                                            <Form.Label htmlFor='s-id'>Search Id</Form.Label>
                                                            <Form.Control type="text" className="my-2" id='s-id' value={fundVal.searchId} name='searchId' onChange={(e) => { fundChange(e, ind) }} placeholder="Enter Your Id" required />
                                                        </Col>
                                                        <Col lg={2} sm={12} >
                                                            <Form.Label className="d-block mb-2" htmlFor='amc-status'>Status </Form.Label>
                                                            <BootstrapSwitchButton id="amc-status" checked={fundVal.status === 1 ? true : false} onChange={(e) => { fundStatus(e, ind) }} onlabel='Enable' offlabel='Disable' width={125} />
                                                        </Col>
                                                        {/* <Col lg={6} sm={12} className="align-self-end my-2">
                                                            {/* <Button variant="primary" size="sm" className="btn-icon" onClick={addFund}><i class='bx bx-plus'></i></Button> */}
                                                        {/* <Button variant="danger" size="sm" className="btn-icon" onClick={()=>{removeFund(ind,fundVal._id)}}><i class='bx bx-x' ></i></Button> */}
                                                        {/* </Col>  */}
                                                        {
                                                            (fund.length === 1 && ind === 0) ? null : <Col lg={1} sm={12} className="align-self-center mt-3">
                                                                <Button variant="danger" size="sm" className="btn-icon" onClick={() => { removeFund(ind, fundVal._id) }}><i class='bx bx-x' ></i></Button>
                                                            </Col>
                                                        }
                                                    </Row>
                                                )
                                            })
                                        }
                                    </Col>
                                    {/* <Col lg={6} sm={12} className="align-self-end my-2"> */}
                                    {/* <Form.Label htmlFor='c-name'>Add Top Performing Fund:- </Form.Label> */}
                                    {/* <Button variant="primary" size="sm" className="btn-icon" onClick={addFund}><i class='bx bx-plus'></i></Button> */}
                                    {/* <Button variant="danger" size="sm" className="btn-icon"><i class='bx bx-x' ></i></Button> */}
                                    {/* </Col> */}
                                </Row>
                                {/* 3. Key Information */}
                                <Row id='typed3' style={{ display: "none" }}>
                                    <Col sm={12}>
                                        {
                                            key.map((keyVal, ind) => {
                                                return (
                                                    <Row className='align-items-center'>
                                                        <Col sm={5}>
                                                            <Form.Control type="text" className="my-2" id='fund-size' value={keyVal.keyName} name='keyName' onChange={(e) => { keyChange(e, ind) }} placeholder="Enter Key Name" />
                                                        </Col>
                                                        <Col sm={5}>
                                                            <Form.Control type="text" className="my-2" id='fund-size' value={keyVal.keyValue} name='keyValue' onChange={(e) => { keyChange(e, ind) }} placeholder="Enter Key Value" />
                                                        </Col>
                                                        <Col sm={2}>
                                                            {
                                                                (key.length === 1 && ind === 0) ? null : <Col sm={4} className="align-self-center">
                                                                    <Button variant="danger" size="sm" className="btn-icon" onClick={() => { removeKey(ind) }}><i class='bx bx-x' ></i></Button>
                                                                </Col>
                                                            }
                                                        </Col>
                                                    </Row>
                                                )
                                            })
                                        }
                                    </Col>
                                    {/* <Col sm={4}> */}
                                    {/* <Form.Label htmlFor='c-name'>Add Key Information:- </Form.Label> */}
                                    {/* <Button variant="primary" size="sm" className="btn-icon" onClick={addKey}><i class='bx bx-plus'></i></Button> */}
                                    {/* <Button variant="danger" size="sm" className="btn-icon" onClick={()=>{removeKey(ind)}}><i class='bx bx-x' ></i></Button> */}
                                    {/* </Col> */}
                                </Row>
                                {/* 4 & 5 */}
                                <Row id='typed4' style={{ display: "none" }}>
                                    <Col lg={12} sm={12}>
                                        <Form.Label htmlFor='invest-desc'>How Can Invest</Form.Label>
                                        <Form.Control as="textarea" rows={4} className="my-2" id='invest-desc' value={user.HowCan} name='HowCan' onChange={(e) => { getVal(e) }} placeholder="Enter Your Discrption" />
                                    </Col>
                                </Row>
                                <Row id='typed5' style={{ display: "none" }}>
                                    <Col lg={12} sm={12}>
                                        <Form.Label htmlFor='fund-desc'>Top Fund Manager</Form.Label>
                                        <Form.Control as="textarea" rows={4} className="my-2" id='fund-desc' value={user.FundManager} name='FundManager' onChange={(e) => { getVal(e) }} placeholder="Enter Your Discrption" />
                                    </Col>
                                </Row>
                                {/* 6. Lets Have A closer Look */}
                                <Row id='typed6' style={{ display: "none" }}>
                                    <Col sm={12}>
                                        {
                                            closer.map((closerVal, ind) => {
                                                return (
                                                    <Row>
                                                        <Col lg={3} sm={12}>
                                                            <Form.Label htmlFor='scme-name'>Scheme Name</Form.Label>
                                                            <Form.Control type="text" className="my-2" id='scme-name' value={closerVal.SchemeName} name='SchemeName' onChange={(e) => { closerChange(e, ind) }} placeholder="Enter Scheme Name" />
                                                        </Col>
                                                        <Col lg={3} sm={12}>
                                                            <Form.Label htmlFor='fund-per'>Fund Perform</Form.Label>
                                                            <Form.Control type="text" className="my-2" id='fund-per' value={closerVal.fundPerform} name='fundPerform' onChange={(e) => { closerChange(e, ind) }} placeholder="Enter Fund Perform" />
                                                        </Col>
                                                        <Col lg={3} sm={12}>
                                                            <Form.Label htmlFor='min-invest'>MinInvest</Form.Label>
                                                            <Form.Control type="text" className="my-2" id='min-invest' value={closerVal.minInvAmt} name='minInvAmt' onChange={(e) => { closerChange(e, ind) }} placeholder="Enter MinInvest" />
                                                        </Col>
                                                        <Col lg={3} sm={12}>
                                                            <Form.Label htmlFor='m-amnt'>MinIvestAmount</Form.Label>
                                                            <Form.Control type="text" className="my-2" id='m-amnt' value={closerVal.minInvestmentAmt} name='minInvestmentAmt' onChange={(e) => { closerChange(e, ind) }} placeholder="Enter MinIvestAmount" />
                                                        </Col>
                                                        <Col lg={5} sm={12}>
                                                            <Form.Label htmlFor='AUM'>AUM</Form.Label>
                                                            <Form.Control as="textarea" rows={4} className="my-2" id='AUM' value={closerVal.AUM} name='AUM' onChange={(e) => { closerChange(e, ind) }} placeholder="Enter Your AUM" />
                                                        </Col>
                                                        <Col lg={5} sm={12}>
                                                            <Form.Label htmlFor='years-return'>One Year Return</Form.Label>
                                                            <Form.Control as="textarea" rows={4} className="my-2" id='years-return' value={closerVal.oneYreturn} name='oneYreturn' onChange={(e) => { closerChange(e, ind) }} placeholder="Enter Your One Year Return" />
                                                        </Col>
                                                        <Col lg={2} sm={12} className="align-self-start mt-4">
                                                            {
                                                                (closer.length === 1 && ind === 0) ? null : <Col lg={2} sm={12}>
                                                                    <Button variant="danger" size="sm" className="btn-icon" onClick={() => { removeCloser(ind) }} ><i class='bx bx-x' ></i></Button>
                                                                </Col>
                                                            }
                                                        </Col>
                                                    </Row>
                                                )
                                            })
                                        }
                                    </Col>
                                    {/* <Col lg={2} sm={12} className="align-self-end my-2"> */}
                                    {/* <Form.Label htmlFor='c-name'>Add Closer Look:- </Form.Label> */}
                                    {/* <Button variant="primary" size="sm" className="btn-icon" onClick={addCloser}><i class='bx bx-plus'></i></Button> */}
                                    {/* </Col> */}
                                </Row>
                                {/* <Row>
                                    <Col lg={4} className="align-self-end my-2">
                                        <Form.Label className="d-block me-3" htmlFor='district-status'>Status : </Form.Label>
                                        <BootstrapSwitchButton id="district-status" checked={true} onlabel='Enable' onChange={(checked)=>{if(checked==true){setStatus(1)}else{setStatus(0)}}} offlabel='Disable' width={125} />
                                    </Col>
                                </Row> */}
                            </Card.Body>
                            <Card.Footer className="text-end">
                                <Button variant="primary" className="me-3" onClick={saveData}>Save</Button>
                                <Link to="/amcdetail">
                                    <Button variant="secondary">Cancle</Button>
                                </Link>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Layout>
    )
}

export default AmcDetailEdit