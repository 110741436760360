import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import { Helmet } from 'react-helmet';

const NationalPS = () => {
    document.title = "National Pension Scheme";
    document.querySelector('meta[name="description"]').setAttribute('content', "National Pension Scheme");
    return (
        <div>
            {/* <Helmet>
                <title>National Pension Scheme</title>
            </Helmet> */}
            <div className="webview">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <p>The <strong>National Pension Scheme</strong> or <strong>NPS</strong> is one of the popular saving schemes prevailing in India which has a backing of the government of India. This saving scheme was started by the government after it decided to stop providing defined benefit pensions to all its employees who joined after January 1, 2004. The scheme was initially created to cater only for the government employees, but later starting from 2009, it was opened for all the citizens who are working in private sectors as well and are aged between 18 to 65 years. <a title="National Pension System (NPS): Important Things To Know!" href="https://www.goodreturns.in/classroom/2016/10/national-pension-system-nps-2016-7-important-things-know-509801.html" >National Pension System (NPS): Important Things To Know!</a></p>
                            <p>The <a title="National Pension Scheme" href="https://www.goodreturns.in/personal-finance/investment/14-easy-steps-to-open-nps-tier-ii-account-online-for-free-1162824.html" >National Pension Scheme</a> is administered and regulated by the Pension Fund Regulatory and Development Authority (PFRDA).</p>

                            <h2>About National Pension System</h2>
                            <p><strong>NPS</strong> is one of the voluntary defined contribution pension systems in the country. </p>
                            <p>This particular saving system is similar to that of other saving instruments such as employees provident fund, public provident fund and falls under EEE (Exempt-Exempt-Exempt) instruments in the country wherein the initial investment amount (principal amount), the interest amount earned on the investment, maturity amount completely escapes tax and the entire pension withdrawal amount is tax-free as per the <a title="Income Tax Act" href="https://www.goodreturns.in/section-80c-of-income-tax-act-s1.html" >Income Tax Act</a>.</p>
                            <p>The government of India on December 10, 2018, made a move to make NPS as an entire tax – free instrument in the country wherein the entire maturity amount escapes the income tax at maturity; the 40% annuity is also tax-free. The contribution made towards NPS under Tier – II is covered under Section 80C for deduction purpose and accordingly, individuals can get up to Rs 1,50,000 of income tax benefit provided if the amount is locked in for three years.</p>
                            <p>Changes made to the NPS account was notified through the changes made in the Income Tax Act of 1961 during the Union Budget of 2019. The National Pension System is a limited EEE to the extent of 60% and remaining 40% has to be compulsorily used for the purchase of an annuity which is taxable at the applicable tax slab as prescribed in the Income Tax Act.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default NationalPS