import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Form, Table, Col, Row } from 'react-bootstrap'
import Layout from '../../layout/Layout'
import Pagination from 'rc-pagination'
import Swal from 'sweetalert2'
import { Link, useNavigate } from 'react-router-dom'
import { API, AuthContext } from '../../App'
import Fancybox from '../../Component/FancyBox';
import { requestSearch1 } from '../Search'
import Cookies from 'js-cookie'


const MutualFundDetail = () => {
    const { mutualDetSearch,setMutualDetSearch,commonSearch,setCommonSearch} = useContext(AuthContext);
    const [dataLength, setDataLength] = useState(1)
    const navigate = useNavigate()
    const tokenAuth = async () => {
        const token = Cookies.get('jwt')
        const response = await API.post("/home", {}, { headers: { "Authorization": `Bearer ${token}` } })
        if (response.data.status === false) {
            navigate("/")
        }
    }
    const [iconcoror, Seticoncoror] = useState("id_up")
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [sData, setSData] = useState([])
    const getData = async () => {
        const token = Cookies.get('jwt')
        const response = await API.post("/getFundDetail", {}, { headers: { "Authorization": `Bearer ${token}` } })
        if (response.data.status === false) {
            if (response.data.response_code === 429) {
                navigate("/")
            } else {
                setDataLength(0)
                setData([])
                setSData([])
                setLoading(false)
            }
        } else {
            setData(response.data.data.data)
            setSData(response.data.data.data)
            setDataLength(1)
            setLoading(false)
            if(commonSearch[8].search!==""){
                requestSearch(commonSearch[8].search,response.data.data.data)
              }
        }
    }

    const deleteUser = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger me-2'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                const token = Cookies.get('jwt')
                const delFund = await API.post(`/deleteFundDetail/${id}`, {}, { headers: { "Authorization": `Bearer ${token}` } })
                if (delFund.data.status === false) {
                    if (delFund.data.response_code === 429) {
                        navigate("/")
                    } else {
                        swalWithBootstrapButtons.fire(
                            'Cancelled',
                            `${delFund.data.response_message}`,
                            'error'
                        )
                    }
                } else {
                    getData()
                    setCurrent(1)
                }

            }
        })
    }

    const sorting = (col, type = "string", order, e) => {
        Seticoncoror(e.target.id)
        if (order === "ASC") {
            if(col==="minInvestment" || col==="threeYReturns" || col==="catMinReturns" || col==="catMaxReturns"){
                const sorted = [...data].sort((a, b) =>
                   Number(a[col].replace("₹","").replace("%","").trim()) > Number(b[col].replace("₹","").replace("%","").trim()) ? 1 : -1
                );
                if (iconcoror !== e.target.id) {
                    setData(sorted)
                }
                
            }else{
                const sorted = [...data].sort((a, b) =>
                    a[col] > b[col] ? 1 : -1
                );
                if (iconcoror !== e.target.id) {
                    setData(sorted)
                }
            }
        }
        if (order === "DSC") {
            if(col==="minInvestment" || col==="threeYReturns" || col==="catMinReturns" || col==="catMaxReturns"){
                const sorted = [...data].sort((a, b) =>
                    Number(a[col].replace("₹","").replace("%","").trim()) < Number(b[col].replace("₹","").replace("%","").trim()) ? 1 : -1
                );
                if (iconcoror !== e.target.id) {
                    setData(sorted)
                }
            }else{
                const sorted = [...data].sort((a, b) =>
                  a[col] < b[col] ? 1 : -1
                );
                if (iconcoror !== e.target.id) {
                  setData(sorted)
                }
            }
        }
    }

    //ClearSearchFunction
    const clearSearchFunc = (searchEle)=>{
        let src = [...commonSearch]
        src.map((val)=>{
            if(val.name!==searchEle){
                if(val.name==="City"){
                    val.search={
                        state:"",
                        city:""
                      }
                }else if(val.name==="Fuel"){
                    val.search={
                        state:"",
                        city:"",
                        date:""
                      }
                }else if(val.name==="Branch"){
                    val.search={
                        bank:"",
                        state:"",
                        city:""
                      }
                }else{
                    val.search=""
                }
            }
            return val
        })
        setCommonSearch(src)
    }

    useEffect(() => {
        tokenAuth()
        getData()
        clearSearchFunc("Mutual Detail")
    }, [])

    const requestSearch = (searchedVal,mainData) => {
        // setMutualDetSearch(searchedVal)
        let srcVal = [...commonSearch]
        srcVal[8].search = searchedVal
        setCommonSearch(srcVal)
        const searchData = requestSearch1(searchedVal,mainData,mainData,"fundName")
        if(searchData.length===0){
            setData([])
            setDataLength(0)
        }else{
            setData(searchData)
            setDataLength(1)
        }
        setCurrent(1)
    };;

    // Paggintion Code // 
    const [size, setSize] = useState(10);
    const [current, setCurrent] = useState(1);

    const wetData = (current, pageSize) => {
        return data.slice((current - 1) * pageSize, current * pageSize);
    };

    const PerPageChange = (value) => {
        console.log(value)
        setSize(value);
        console.log(size)
        const newPerPage = Math.ceil(data.length / value);
        if (current > newPerPage) {
            setCurrent(newPerPage);
        }
    }

    const PaginationChange = (page, pageSize) => {
        setCurrent(page);
        setSize(pageSize)
    }


    const PrevNextArrow = (current, type, originalElement) => {
        if (type === 'prev') {
            return <button className='paggination-btn'>Previous</button>;
        }
        if (type === 'next') {
            return <button className='paggination-btn'>Next</button>;
        }
        return originalElement;
    }

    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3>Mutual Fund Details</h3>
                <Row className='align-items-center justify-content-end'>
                    <Col md="auto" xs={6}>
                        <Form.Select onChange={e => { setSize(Number(e.target.value)); setCurrent(1) }} className="my-1">
                        {
                            [10, 25, 50, 100].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                            ))
                        }
                        </Form.Select>
                    </Col>
                    <Col md="auto">
                        <Form.Control type="search" placeholder="Search By Name" value={commonSearch[8].search} onChange={(e) => requestSearch(e.target.value,sData)} className="form-control my-1" />
                    </Col>
                    <Col md="auto">
                        <Link className="btn btn-primary my-1" to="/mutualfunddetail/add">Add New</Link>
                    </Col>
                </Row>
            </div>
            <div className="page-content">
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Body>
                                <Fancybox>
                                    {
                                        loading ? <div className='loader table-loader' ></div> : <></>
                                    }
                                    <Table bordered responsive>
                                        <thead>
                                            <tr>
                                                <th width='5%'>
                                                <div className='table-sort-filter justify-content-center'>
                                                    No
                                                    {/* <span className='table-sort'>
                                                        <div className={`sort-down ${iconcoror === "id_down" ? "active" : ""}`} id="id_down" onClick={(e) => { sorting('_id', "", "DSC", e) }} ></div>
                                                        <div className={`sort-up ${iconcoror === "id_up" ? "active" : ""}`} id="id_up" onClick={(e) => { sorting('_id', "", "ASC", e) }} ></div>
                                                    </span> */}
                                                </div>    
                                                </th>
                                                <th width='24%'>
                                                <div className='table-sort-filter'>
                                                    Fund Name
                                                    <span className='table-sort'>
                                                        <div className={`sort-down ${iconcoror === "fundName_down" ? "active" : ""}`} id="fundName_down" onClick={(e) => { sorting('fundName', "", "DSC", e) }} ></div>
                                                        <div className={`sort-up ${iconcoror === "fundName_up" ? "active" : ""}`} id="fundName_up" onClick={(e) => { sorting('fundName', "", "ASC", e) }} ></div>
                                                    </span>
                                                </div>    
                                                </th>
                                                <th width='5%' className='text-center'>Image</th>
                                                <th width='8%'>
                                                <div className='table-sort-filter'>
                                                    Fund Type
                                                    <span className='table-sort'>
                                                        <div className={`sort-down ${iconcoror === "fundType_down" ? "active" : ""}`} id="fundType_down" onClick={(e) => { sorting('fundType', "", "DSC", e) }} ></div>
                                                        <div className={`sort-up ${iconcoror === "fundType_up" ? "active" : ""}`} id="fundType_up" onClick={(e) => { sorting('fundType', "", "ASC", e) }} ></div>
                                                    </span>
                                                </div>    
                                                </th>
                                                <th width='14%'>
                                                <div className='table-sort-filter'>
                                                    Fund Cap
                                                    <span className='table-sort'>
                                                        <div className={`sort-down ${iconcoror === "fundCap_down" ? "active" : ""}`} id="fundCap_down" onClick={(e) => { sorting('fundCap', "", "DSC", e) }} ></div>
                                                        <div className={`sort-up ${iconcoror === "fundCap_up" ? "active" : ""}`} id="fundCap_up" onClick={(e) => { sorting('fundCap', "", "ASC", e) }} ></div>
                                                    </span>
                                                </div>
                                                </th>
                                                <th width='9%'>
                                                <div className='table-sort-filter'>
                                                    Min Investment
                                                    <span className='table-sort'>
                                                        <div className={`sort-down ${iconcoror === "minInvestment_down" ? "active" : ""}`} id="minInvestment_down" onClick={(e) => { sorting('minInvestment', "", "DSC", e) }} ></div>
                                                        <div className={`sort-up ${iconcoror === "minInvestment_up" ? "active" : ""}`} id="minInvestment_up" onClick={(e) => { sorting('minInvestment', "", "ASC", e) }} ></div>
                                                    </span>
                                                </div>
                                                </th>
                                                <th width='9%'>
                                                <div className='table-sort-filter'>
                                                    Three Return
                                                    <span className='table-sort'>
                                                        <div className={`sort-down ${iconcoror === "threeYReturns_down" ? "active" : ""}`} id="threeYReturns_down" onClick={(e) => { sorting('threeYReturns', "", "DSC", e) }} ></div>
                                                        <div className={`sort-up ${iconcoror === "threeYReturns_up" ? "active" : ""}`} id="threeYReturns_up" onClick={(e) => { sorting('threeYReturns', "", "ASC", e) }} ></div>
                                                    </span>
                                                </div>
                                                </th>
                                                <th width='9%'>
                                                <div className='table-sort-filter'>
                                                    Cat Mini Returns
                                                    <span className='table-sort'>
                                                        <div className={`sort-down ${iconcoror === "catMinReturns_down" ? "active" : ""}`} id="catMinReturns_down" onClick={(e) => { sorting('catMinReturns', "", "DSC", e) }} ></div>
                                                        <div className={`sort-up ${iconcoror === "catMinReturns_up" ? "active" : ""}`} id="catMinReturns_up" onClick={(e) => { sorting('catMinReturns', "", "ASC", e) }} ></div>
                                                    </span>
                                                </div>    
                                                </th>
                                                <th width='9%'>
                                                <div className='table-sort-filter'>
                                                    Cat Max Returns
                                                    <span className='table-sort'>
                                                        <div className={`sort-down ${iconcoror === "catMaxReturns_down" ? "active" : ""}`} id="catMaxReturns_down" onClick={(e) => { sorting('catMaxReturns', "", "DSC", e) }} ></div>
                                                        <div className={`sort-up ${iconcoror === "catMaxReturns_up" ? "active" : ""}`} id="catMaxReturns_up" onClick={(e) => { sorting('catMaxReturns', "", "ASC", e) }} ></div>
                                                    </span>
                                                </div>    
                                                </th>
                                                <th width='8%' className='text-center'>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                wetData(current, size).map((dataVal, ind) => {
                                                    return (
                                                        <tr key={ind}>
                                                            <td className='text-center'>{(current === 1) ? ind + 1 : current * size + ind + 1 - size}</td>
                                                            {/* <td className='text-center'>{oid.indexOf(dataVal._id)+1}</td> */}
                                                            <td>{dataVal.fundName}</td>
                                                            <td className='text-center'>
                                                                <a data-fancybox="gallery" href={dataVal.imageLink}>
                                                                    <img src={dataVal.imageLink} className="hv-40" alt="img" />
                                                                </a>
                                                            </td>
                                                            <td>{dataVal.fundType}</td>
                                                            <td>{dataVal.fundCap}</td>
                                                            <td>{dataVal.minInvestment}</td>
                                                            <td>{dataVal.threeYReturns}</td>
                                                            <td>{dataVal.catMinReturns}</td>
                                                            <td>{dataVal.catMaxReturns}</td>
                                                            <td className='text-center'>
                                                                <Link className="btn btn-outline-primary me-2 btn-icon" size="sm" to={`/mutualfunddetail/edit/${dataVal._id}`}><i className='bx bx-edit-alt'></i></Link>
                                                                <Button variant="outline-danger" size="sm" className="btn-icon" onClick={() => deleteUser(dataVal._id)}><i className='bx bx-trash-alt' ></i></Button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            {
                                                dataLength === 0 ? <tr>
                                                    <td colSpan="100%" class="p-0">
                                                        <div className='no-found'>
                                                            <img src="../../not-found/mutual-details.svg" alt='Data Not Found'/>
                                                            <p>No Found Mutual Fund Details</p>
                                                        </div>
                                                    </td>
                                                </tr> : ""
                                            }
                                        </tbody>
                                    </Table>
                                    {data.length > size ?
                                        <div className="pagination-custom">
                                            <Pagination showTitle={false}
                                                className="pagination-data"
                                                onChange={PaginationChange}
                                                total={data.length}
                                                current={current}
                                                pageSize={size}
                                                showSizeChanger={false}
                                                itemRender={PrevNextArrow}
                                                onShowSizeChange={PerPageChange}
                                            />
                                        </div> : ""
                                    }
                                </Fancybox>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Layout>
    )
}

export default MutualFundDetail