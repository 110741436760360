import React, { useState } from 'react'
import { Breadcrumb, Row, Col, Card, Form, Button, InputGroup } from 'react-bootstrap'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Layout from '../../layout/Layout'
import {toast} from 'react-toastify'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { API } from '../../App';
import { useEffect } from 'react';
import Cookies from 'js-cookie'

const MutualFundEdit = () => {
  const tokenAuth = async () => {
    const token = Cookies.get('jwt')
    const response = await API.post("/home", {}, { headers: { "Authorization": `Bearer ${token}` } })
    if (response.data.status === false) {
      navigate("/")
    }
  }
  const [validate,setValidate] = useState(false)
  const navigate = useNavigate()
  const { _id } = useParams()
  const [user, setUser] = useState({
    name: "",
    status: "",
    show: "",
    Image:"",
    id: ""
  })

  const getData = async () => {
    const token = Cookies.get('jwt')
    const mutualData = await API.post(`/showMutualFund/${_id}`, {}, { headers: { "Authorization": `Bearer ${token}` } })
    if (mutualData.data.status === false) {
      if (mutualData.data.response_code === 429) {
        navigate("/")
      } else {
        toast.error('Something went wrong')
      }
    } else {
      setUser({
        name: mutualData.data.data.name,
        status: mutualData.data.data.status === 1 ? true : false,
        show:mutualData.data.data.show === 1 ? true : false,
        Image:mutualData.data.data.image,
        id: mutualData.data.data._id
      })
    }
  }

  const [selectedImage, setSelectedImage] = useState(null);

  const onImags = (event) => {
    console.log(event.target.files[0]);
    setSelectedImage(event.target.files[0]);
  }

  const getVal = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  const change = (e,name) => {
    if(name==='status'){
      setUser({ ...user, ['status']: e })
    }else{
      setUser({ ...user, ['show']: e })
    }
  }

  const saveData = async () => {
    if (user.name === "") {
      setValidate(true)
    } else {
      // if(user.name.match(/^[A-Za-z& ]+$/)){
        const Form = new FormData
        Form.append("name", user.name)
        Form.append("status", user.status === true ? 1 : 0)
        Form.append("show", user.show === true ? 1 : 0)
        Form.append('image', selectedImage === null ? user.Image : selectedImage)
        const token = Cookies.get('jwt')
        const response = await API.post(`/editMutualFund/${_id}`, Form, { headers: { "Authorization": `Bearer ${token}` } })
        if (response.data.status === true) {
          toast.success('Mutual Fund Updated Succesfully')
          navigate("/mutualfund")
        } else {
          if (response.data.response_code === 429) {
            navigate("/")
          } else {
            toast.error('Something went wrong')
          }
        }
      // }else{
      //   toast.warning('Please enter valid Mutual Fund Name')
      // }
    }
  }

  useEffect(() => {
    tokenAuth()
    getData()
  }, [])

  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3>Edit Mutual Fund</h3>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/mutualfund">Mutual Fund List</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Edit Mutual Fund</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="page-content">
      <Form noValidate validated={validate}>
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Row>
                  <Col lg={6} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='mf-name'>Name</Form.Label>
                      <Form.Control type="text" min={0} className="my-2" id='mf-name' value={user.name} onChange={(e) => { getVal(e) }} name="name" placeholder="Enter Your Name" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={2} sm={12} >
                    <Form.Group>
                      <Form.Label className="d-block mb-2" htmlFor='mf-status'>Status </Form.Label>
                      <BootstrapSwitchButton id="mf-status" checked={user.status} onChange={(e)=>{change(e,'status')}} onlabel='Enable' offlabel='Disable' width={125} />
                    </Form.Group>
                  </Col>
                  <Col lg={2} sm={12} >
                    <Form.Group>
                      <Form.Label className="d-block mb-2" htmlFor='mf-status'>Show </Form.Label>
                      <BootstrapSwitchButton id="mf-status" checked={user.show} onChange={(e)=>{change(e,'show')}} onlabel='Enable' offlabel='Disable' width={125} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                <Col lg={4} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='state-name'>Image</Form.Label>
                      <InputGroup className="my-2">
                        <Form.Control type="file" id='state-name' onChange={(e) => { { onImags(e) } }} placeholder="Enter Your Name" />
                        <img src={user.Image} alt="Logo" height={40} />
                      </InputGroup>
                    </Form.Group>
                </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-end">
                <Button variant="primary" className="me-3" onClick={saveData}>Save</Button>
                <Link to="/mutualfund">
                  <Button variant="secondary">Cancle</Button>
                </Link>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Form>
      </div>
    </Layout>
  )
}

export default MutualFundEdit