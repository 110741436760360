import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button, Form } from "react-bootstrap";
import { API } from "../../App";
import { useEffect } from "react";
import { useState } from "react";
import Hcp_webview from "./Hcp_webview";
import { Card } from "react-bootstrap";
import $ from "jquery";
import InputGroup from "react-bootstrap/InputGroup";

const CarLoan = () => {
  document.title = "Car Loan";
  document.querySelector('meta[name="description"]').setAttribute('content', "Car Loan");
  const [data, setData] = useState([]);
  const [sData,setSData] = useState([])
  const getData = async () => {
    const response = await API.post("/loan/table/data", { loan_type: "Car" });
    setData(response.data.data.table);
    setSData(response.data.data.table);
  };

  useEffect(() => {
    getData();
  }, []);

  const [button, setButton] = useState("Read More");
  const btnFunc = () => {
    if (button === "Read More") {
      setButton("Read Less");
      document.getElementById("type1").style.display = "block";
    } else {
      setButton("Read More");
      document.getElementById("type1").style.display = "none";
    }
  };

  // const requestSearch = (searchedVal) => {
  //   const filteredRows = data.filter((row) => {
  //     return row.bankName
  //       .toString()
  //       .toLowerCase()
  //       .includes(searchedVal.toString().toLowerCase());
  //   });
  //   if (searchedVal.length < 1) {
  //     $("#Data_not_found").css({ display: "none" });
  //     getData();
  //   } else {
  //     if (filteredRows.length === 0) {
  //       $("#Data_not_found").css({ display: "block" });
  //       setData(filteredRows);
  //     } else {
  //       setData(filteredRows);
  //     }
  //   }
  // };

  const requestSearch = (searchedVal) => {
    const filteredRows = data.filter((row) => {
        return row.bankName.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());

    });
    if (searchedVal.length < 1) {
        $('#Data_not_found').css({ display: "none" })
        getData()
    }
    else {
        if (filteredRows.length === 0) {
            const filteredRows1 = sData.filter((row) => {
                return row.bankName.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
            });
            if (filteredRows1.length === 0) {
                setData(filteredRows)
            }
            else {
                $('#remove_tr').empty()
                setData(filteredRows1)
            }
            // $('#Data_not_found').css({display:"block"})
            // setData(filteredRows)
            // setDataLength(0)
        }
        else {
            const filteredRows1 = sData.filter((row) => {
                return row.bankName.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
            });
            if (filteredRows1.length === 0) {
                $('#remove_tr').empty()
                $('#remove_tr').append('<td colSpan="100%" class="p-0"><div class="no-found">No Records Found</div></td>')
                setData(filteredRows)

            }
            else {
                $('#remove_tr').empty()
                setData(filteredRows1)
                // setCurrent(1)
            }
            // setData(filteredRows)
            // setCurrent(1)
        }
    }
};

  return (
    <div className="balance-webview">
      <Container>
        {/* ================= Search Bar ================= */}
        <Row>
          <Col xs={12}>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Search"
                aria-label="Search"
                onChange={(e) => requestSearch(e.target.value)}
                aria-describedby="basic-addon1"
              />
              <InputGroup.Text id="basic-addon1">
                <i className="bx bx-search"></i>
              </InputGroup.Text>
            </InputGroup>
          </Col>
        </Row>

        <Row>
        {/* ================= Description Bar ================= */}
          <Col xs={12}>
            <h2>Loan Details</h2>
            <p>
              In today’s world, the individual's income alone is not sufficient
              to meet all the needs. Uncertain scenarios force the individuals
              to look out for securing finance through one or other means which
              again is a daunting task. To ease out individuals at the crucial
              times, many banks in India have come forward to avail personal
              loan. These loans can be secured easily and just requires few
              procedures to be followed for the borrower. Let’s understand each
              and every term associated with the Personal Loan in detail.&nbsp;
            </p>
          </Col>
          {/* ================= Read More ================= */}
          <Col xs={12}>
            <Button
              className="w-100 btn-read-more"
              type="primary"
              onClick={btnFunc}
            >
              {button}
            </Button>
          </Col>
          {/* ================= Read More Content ================= */}
          <Col xs={12} id="type1" style={{ display: "none" }}>
              <h2>What is a Car Loan?</h2>
              <p>
                The term car loan refers to the kind of loan which a borrower
                uses to purchase a car.{" "}
              </p>
              <p>
                {" "}
                The individual who secures the loan amount has to pay back to
                the borrower (bank or financial institution) within a period of
                24 months – 60 months. Car loan usually includes various fees
                and taxes which will be added to the total loan amount.
              </p>
              <h2>Features of Car Loan</h2>
              <p>The following are the features of Car Loan</p>
              <p>
                <strong>• Loan Tenure</strong> – The tenure of the car loan is
                between 1 year to 7 years. The loan term mostly depends on the
                loan amount as well as the bank which offers the loan. Higher
                the loan tenure lesser the EMI amount on a car loan. <br />
                <strong>• Interest Rate</strong> – The interest rate usually
                depends on the bank with which the applicant is securing the
                loan. Higher interest rates will increase the EMI and vice
                versa. <br />
                <strong>• Loan Amount</strong> – In the case of car loans, the
                lender will lend the loan based on the road price of the car.
                The borrower can secure up to 3 times the annual salary in case
                of salaried professional or up to 6 times the annual income in
                case of employed.
              </p>
              <h2>Required Documents for Car Loan</h2>
              <p>
                An individual needs to submit the following list of documents to
                secure a car loan
              </p>
              <p>
                <strong>For Salaried Employees:</strong>
              </p>
              <p>
                <strong>• Proof of Identification –</strong> Birth Certificate,
                Passport, Election ID Card, PAN Card, Aadhaar Card, Driver’s
                Licence, Utility Bills like electricity bill or water bill.{" "}
                <br />
                <strong>• Proof of Income –</strong> Recent salary slips for 3
                months from the current employer. <br /> • Statement of
                Financial Accounts – Recent bank statement for a period of 6
                months, credit card statement from banks. <br />
                <strong>• Proof of Residence –</strong> Utility Bills
                (Electricity bill, Water bill), Passport.
                <br /> • Duly filled in application form along with recent
                passport size photograph.
              </p>
              <p>
                <strong>For Self – Employed:</strong>
              </p>
              <p>
                <strong>• Proof of Identification –</strong> Birth Certificate,
                Passport, Election ID Card, PAN Card, Aadhaar Card, Driver’s
                Licence, Utility Bills like electricity bill or water bill.{" "}
                <br /> •<strong> Proof of Income</strong> – Recent salary slips
                for 3 months from the current employer. <br /> • Proof of Office
                Address
                <br /> • Education Qualification Certificate <br /> •{" "}
                <strong>Income Proof of Self – Employed</strong> – Audited
                finance report for the last 3 years, recent bank statement for 6
                months.
                <br /> •<strong> Proof of Business –</strong> Shop Act License,
                Certificate of Practice, GST registration certificate, MOA &amp;
                AOA, Qualification Certificate. <br />
                <strong>• Proof of Residence –</strong> Utility Bills
                (Electricity bill, Water bill), Passport.
                <br /> • Duly filled in application form along with recent
                passport size photograph.
              </p>
              <h2>Do’s and Dont’s while choosing a Car Loan</h2>
              <p>The following are the list of Do’s for a car loan applicant</p>
              <p>
                <strong>• Opt for lower interest rate –</strong> The interest
                rates of the loan will add up to the total cost of the loan
                repaid by the borrower to the lender. The lesser interest rates
                will be a better option for the car loan applicant as it will
                help the applicant to save money.
              </p>
              <p>
                <strong>• Compare –</strong> One has to thoroughly compare the
                wide range of options available at the moment before zeroing on
                the best one when it comes to a car loan. With the advent of
                technology, one should utilize the best use of internet and
                check for the various offers provided by several banks and
                financial institutions.
              </p>
              <p>
                <strong>• Hidden Charges and Fees –</strong> The element of
                hidden charges and fees is largely present in all the kinds of
                loans offered by the banks to its consumers. One should
                carefully evaluate it and has to be cautious about the
                incentives and packages offered by the lender and choose the
                correct lender to avoid hidden charges.
              </p>
              <p>
                <strong>• Insurance –</strong> The insurance amount on the car
                has to be decided by the owner after evaluating the coverage and
                cost factors.
              </p>
              <p>
                The following are the list of Dont’s for a car loan applicant
              </p>
              <p>
                <strong>• Multiple Applications</strong> – One should avoid
                applying for many banks as this will impact the credit score
                negatively.
              </p>
              <p>
                <strong>• Eligibility</strong> – One should not apply for a car
                loan if it exceeds the eligibility criteria as this will surely
                result in rejection of the application.
              </p>
              <p>
                <strong>• Evaluating Dealers Carefully</strong> – One should
                always evaluate the dealers offer carefully before taking it as
                they may not offer the best interest rates.
              </p>
              <h2>EMI Calculators for Car Loans</h2>
              <p>
                If a borrower is taking a car loan then it is very essential to
                know the equated monthly instalment (EMI) amount that he/she
                will have to incur over a period of time until the completion of
                loan repayment. The EMI calculators helps the individuals to
                understand the interest amount charged on the total loan amount
                as well as the duration of the loan. The EMI Calculator acts as
                a financial tool which helps the borrower to decide the loan
                amount and accordingly to chalk out a financial plan for
                safeguarding the future expenses.
              </p>
              <p>
                <strong>
                  Car loans can be availed either for new cars or for used cars
                </strong>
                .
              </p>
              <h2>What is a Pre-owned Car Loan?</h2>
              <p>
                A Pre-owned car loan refers to the loan amount which a borrower
                used to purchase the used car. One can opt for repaying the same
                in equated monthly instalments (EMI) over the tenure of the loan
                which includes both principal amount and interest amount.
              </p>
              <h2>Eligibility Criteria to get Pre-Owned Car Loan</h2>
              <p>
                The following is the eligibility criteria to secure a pre-owned
                car loan
              </p>
              <p>
                • The borrower should be aged minimum of 21 years.
                <br /> • Should have at least 1 year of work or business
                experience. <br /> • Cars which are aged up to 10 years old are
                eligible to secure finance.
                <br /> • Required Identification Proofs and Proof of Address
                <br /> • Should have a Bank Account.
              </p>
          </Col>
          {/* ================= Cards ================= */}
          <Col className="mt-3" xs={12}>
              {data.map((dataVal, ind) => {
                return (
                  <Card key={ind}>
                    <Hcp_webview
                      key={ind}
                      Bank={dataVal.bankName}
                      Intrest={dataVal.interestRate}
                      Process={dataVal.processingFee}
                      Amount={dataVal.loanAmount}
                      Tenure={dataVal.tenure}
                    />
                  </Card>
                );
              })}
            </Col>
        </Row>
        
      </Container>
    </div>
  );
};

export default CarLoan;

