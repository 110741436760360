import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import { Helmet } from 'react-helmet';


const PublicPF = () => {
    // useEffect(() => {
        document.title = "Public Provident Fund";
        document.querySelector('meta[name="description"]').setAttribute('content', "Public Provident Fund");
    // }, [])
    return (
        <div>
            {/* <Helmet>
            <title>Public Provident Fund</title>
        </Helmet> */}
            <div className="webview">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <p>The <strong>Public Provident Fund</strong> or <strong>PPF</strong> as the name suggests this is a fund created by the central government to help people from all walks of life to invest in it which promises reasonable returns combined with income tax benefits. The <a title="Public Provident Fund account" href="https://www.goodreturns.in/classroom/2017/05/opening-ppf-account-post-office-you-need-know-572820.html" >Public Provident Fund account</a> is one of the savings-cum-tax-saving investment instruments introduced by the government during 1968, by the National Savings Institute of the Ministry of Finance.</p>
                            <p>The main aim behind rolling out this scheme is to mobilize small savings from investors for a long period. The scheme is fully guaranteed from the Central Government and this makes it best suitable for risk-averse investors who defer investing in riskier assets.</p>

                            <h2>About Public Provident Fund</h2>
                            <p>The unique feature of this scheme is it a <strong>PPF account</strong> is not subjected to attachment under any order or a decree from a court if in case an investor is bankrupt or has defaulted to repay the loan. </p>
                            <p> But only the Income Tax and other Government authorities can attach the account for recovering tax dues.</p>
                            <p>The government of India reintroduced the <strong>Public Provident Scheme</strong>, 2019 on December 12, 2019, replacing the earlier one which was introduced in 1968, as the scheme is subject to amendment periodically.</p>
                            <p>This <strong>PPF account</strong> is one of the popular forms of investment schemes amongst investors owing to its multiple investor-friendly features and its related benefits. The long-term investment features yet stable returns are suited for those who prefer higher returns. The prime target of individuals who opt for this account is the proper safekeeping of the principal amount.</p>

                            <h2>Why should One Open a PPF Account?</h2>
                            <p>The <strong>Public Provident Fund or PPF account</strong> is suitable for those investors who are risk-averse (prefer to take less risk). As the plan is introduced and endorsed by the government, it is backed up with guaranteed returns thus safeguarding the financial needs of the investors in India. An investment made in PPF account is not exposed to market volatility and hence this account remains popular amongst middle-class income people in India.</p>

                            <h2>Eligibility Criteria to Open PPF Account</h2>
                            <p>The Resident Indians are eligible to open a PPF Account in his or her name in any of the banks, post offices. Parents can open and operate a PPF account in the name of the minor kids.</p>
                            <p>However, the Non – Resident Indians cannot open a new public provident fund account. If in case, if they already have one PPF account then the same will remain active till the completion of the tenure and they cannot be extended for additional five years, a benefit which is solely available for the resident Indians.</p>

                            <h2>Nomination Facility</h2>
                            <p>An investor can opt for nomination facility in a PPF account in the name of one or more persons. The sharing ratio of the nominees can also be pre-defined by the account holder.</p>

                            <h2>Features of Public Provident Fund</h2>
                            <p>The following are the list of features of Public Provident Fund Account:</p>
                            <h3>1. Minimum Amount of Investment</h3>
                            <p>The main feature of the PPF account is it can be opened with a mere Rs 500 per annum and the investors can deposit up to a maximum of Rs 1,50,000 in a year. This investment can be done in one go (lump sum) or can be paid in instalments as per the convenience of the investor. But one has to keep in mind that an investor can pay for only 12 yearly instalments into a PPF account. The account holder has to ensure that the account remains active throughout the tenure of 15 years from the date of opening the account.</p>
                            <h3>2. Tenure of Investment</h3>
                            <p>The period of investment in case of a public provident fund is for 15 long years, which means the lock-in period is 15 – years. Before the end of the tenure, the account holder cannot withdraw the invested money fully. If required, then the investor can choose to extend the tenure of the PPF account by 5 more years post-lock-in period. PPF is one of the saving accounts in India which has long tenure but it guarantees higher returns and is safe as it is backed by the central government.</p>
                            <h3>3. Safety</h3>
                            <p>Depositing the hard-earned money in PPF account is safe as it is backed by the government and is one of the long – term tax-free saving schemes. An investor need not have to worry about his or her invested money as the returns from this scheme is guaranteed and are higher compared to other forms of investments available in the country. The money will be locked in the account for 15 long years and returns will be calculated based on the compound interest. Only post offices, nationalized banks and a few selected private banks offer this account for investors.</p>
                            <h3>4. Income Tax Benefits</h3>
                            <p>The principal amount invested in a PPF account is exempt from Income Tax. The entire value of an investment can be claimed as tax exemption under <a title="Section 80C of the Income Tax Act of 1961" href="https://www.goodreturns.in/section-80c-of-income-tax-act-s1.html" >Section 80C of the Income Tax Act of 1961</a>. The investor can invest up to a maximum of Rs 1,50,000 per annum in his or her public provident fund account and the principal amount cannot exceed the said limit.</p>
                            <p>In addition to this, the total interest accrued on this account is also exempt from the Income Tax. On the whole, the total amount (Principal + Interest) on maturity is not subject to taxation. This makes the investment fund even more attractive for many investors in India.</p>
                            <h3>5. Loan against Investment</h3>
                            <p>The Public Provident Fund account provides benefit to the investor in availing loans against the invested amount. But to avail this facility, one can apply for a loan at any time starting from the 3rd year till the end of 6th year from the date of opening of the account.</p>
                            <p>The maximum period of loans against a PPF account is 36 months. The account holder can take a loan of only 25% or less of the total amount available in the account.</p>
                            <h3>6. Interest Rate</h3>
                            <p>The interest rates of the public provident fund account are higher when compared to the savings account, fixed deposits offered by many banks, financial institutions and post offices. The interest rates of PPF account is determined by the central government and is subject to change every quarter. The government aims to provide a higher interest rate on this account than any other normal accounts maintained by several commercial banks in the country.</p>

                            <h2>How to Open a Public Provident Fund (PPF) Account?</h2>
                            <p>Interested investors who are looking to open a PPF account can do so by both online and offline method provided if he or she meets the eligibility criteria as stated by the central government to open a PPF account. Individuals who prefer to open a PPF account through online mode have to first visit the website of the chosen bank or post office. </p>
                            <p>Those who are opting for offline mode have to visit the nearest post offices or the chosen bank and fill in the PPF account application form and submit the same along with the list of supporting documents to open the account.</p>
                            <p><strong>List of required documents to open a PPF Account</strong></p>
                            <ul>
                                <li><a title="PAN Card" href="https://www.goodreturns.in/pan-card.html" >PAN Card</a></li>
                                <li>Residential Address Proof</li>
                                <li>KYC Documents to verify the identity of the individual (Aadhaar card, Driver’s License, Voter ID, Electricity Bill, Water Bill)</li>
                                <li>Nominee Declaration Form</li>
                                <li>Passport size Photograph</li>
                            </ul>

                            <h2>Public Provident Fund Tenure</h2>
                            <p>The tenure or duration of the PPF account is for 15 long years. After completion of 15 years, on application by the subscriber, the account can be extended for 1 or more in blocks of five years each.</p>

                            <h2>Withdrawal of Public Provident Fund Account</h2>
                            <p>An investor will have to adhere to several clauses if he/she wants to withdraw funds from the PPF account and they are as follows: </p>
                            <ul>
                                <li>Compulsory lock-in of 15 years will be imposed on the principal amount invested in such plans.</li>
                                <li>If in case of an emergency, only partial withdrawal of the invested amount is allowed. However, this can be extracted only after completing 5 years of the opening of the account. </li>
                                <li>An investor can withdraw up to 50% of the total balance in one go each financial year succeeding in the 4th year. </li>
                                <li>One should note, that the funds invested in PPF account cannot be liquified before completion of the maturity period. The ones who are looking for long – term risk – a free form of investment which provides a stable return can go for the government-backed investment instrument.</li>
                            </ul>
                            <p>Note: The account holder who is seeking for withdrawal of his/her PPF account will have to submit an application for withdrawal in Form C to the concerned bank branch or the post office wherein they hold their respective account.</p>

                            <h2>Withdrawals from a PPF Account</h2>
                            <p>The lock-in period for a public provident fund account is 15 years and only upon reaching maturity, the account holder can close the account and withdraw the proceedings. However, a pre-maturity facility for a PPF account can be made at the beginning of the seventh financial year but the maximum amount of pre-mature withdrawal should be equal to 50% of the amount which has been deposited into the account at the end of the fourth year preceding the year or at the end of the immediately preceding year whichever is lower.</p>

                            <h2>Defaulting on Payments and Revival of a PPF Account</h2>
                            <p>If an account holder defaults on making a payment towards a PPF account during any year and even the minimum amount is not invested, then the account will be deactivated. To reactivate the account, the investor should pay a penalty fee of Rs 50 for each of the inactive years and has to make a contribution of Rs 500 for each of the inactive years. </p>
                            <p>If in case of death of the account holder, then the balance amount accumulated in the account will be paid to the nominee or the legal heir before the maturity period of 15 years as neither a nominee nor a legal heir is eligible to continue the account of the deceased account holder. </p>
                            <p>If in case, the balance amount of the account of the deceased investor is greater than Rs 1,50,000, then the nominee or the legal heir has to prove the legal identity to claim the said balance amount.</p>

                            <h2>Premature Closure of a PPF Account</h2>
                            <p>An account holder can opt for premature closure of his or her PPF account subject to fulfilment of certain conditions laid out under the Public Provident Fund (Amendment) Scheme of 2016 which has made changes in Paragraph 9, for sub-rule 3(c) of the PPF Scheme of 1968. This amendment was made to facilitate the premature closure of the PPF Account. </p>
                            <p>The premature closure facility is permitted post completion of 5 years of opening the account to meet the medical treatment expenses of either self or of the family members or for meeting up the higher education of the PPF account holder. But the closure of such accounts attracts an interest rate penalty of 1%.</p>
                            <p>If in case, the account holder has changed his/her residency, then they will have to produce Visa or passport. For higher education of self or a dependent family member, one has to provide the fees bills or admission confirmation letter to close the account. </p>

                            <h2>Tax Benefits of Investing in Public Provident Fund Account</h2>
                            <p>Annual contributions made up to Rs 1,50,000 per year towards the public provident fund is qualified for tax deduction under Section 80C of the Income Tax Act of 1961. The Public Provident Fund (PPF) falls under the EEE (Exempt Exempt Exempt) category of Income Tax. This means, the principal amount, interest earned on the investment and the final maturity amount are completely exempt from the Income Tax and hence it offers triple exemption benefits. Usually, Wealth Tax is not applicable on a PPF account and its proceeds. </p>
                            <p>One thing, the investor has to keep in mind is that the account holder cannot close the PPF account before maturity. But it can be transferred from one point of destination to another as per the convenience of the individual. The only exemption under which a PPF account can be closed is on the death of the account holder. In such a case, a nominee can file for the closure of the account. </p>

                            <h2>FAQ's</h2>
                            <h4>Q: Can a senior citizen open a PPF account?</h4>
                            <p>Ans: Yes, every citizen can eligible to open a PPF account. Any Indian citizen at least 18 years old can open a PPF account. There is no maximum age limit. Adults can open a public pension account for minors.</p>
                            <h4>Q: How to create a savings plan?</h4>
                            <p>Ans: Your savings plan will depend on your financial goals, among other factors such as your profession, the section of society you belong to, gender, etc. Here is the list of the most popular savings plans you can consider, based on your financial goals and other parameters:</p>
                            <ul>
                                <li>National Savings Scheme</li>
                                <li>National Savings Certificate</li>
                                <li>Public Provident Fund</li>
                                <li>Post Office Savings Scheme</li>
                                <li>Senior Citizens Savings Scheme</li>
                                <li>Kisan Vikas Patra</li>
                                <li>Sukanya Samriddhi Yojana</li>
                                <li>Atal Pension Yojana</li>
                                <li>Employee Provident Fund</li>
                                <li>Nation Pension Scheme</li>
                                <li>Voluntary Provident Fund</li>
                                <li>Deposit Scheme for Retiring Government Employees</li>
                                <li>Pradhan Matri Jan Dhan Yojana</li>
                            </ul>
                            <h4>Q: Is the PPF a good investment?</h4>
                            <p>Ans: The PPF is by far the most common voluntary tax savings option for which all other employees opt. It is a good option for those who wish to make long term investments as the blocking period for PPF is 15 years. However, it's not the only plan that
                                helps you save on taxes, many other plans and schemes, such as the ELSS, also tend to offer high returns on investments.</p>
                            <h4>Q: Can I increase my investment under the PPF by opening 2 or more accounts in my name?</h4>
                            <p>Ans: No. Within the framework of the Public Provident Fund, a person can only hold and manage one account in his name.</p>
                            <h4>Q: How can I get maximum PPF profit?</h4>
                            <p>Ans: To obtain maximum PPF benefits, investments must always be made before the 5th of each month. Higher returns can be obtained when a lump sum investment is made at the beginning of the financial year, that is to say before April 5th of each year.</p>
                            <h4>Q: Can I continue to use an inactive PPF account?</h4>
                            <p>Ans: You can do this by paying the home branch a penalty of Rs.50 for each year the account was inactive. You will also need to deposit a minimum of Rs.500 for each year the account was inactive as well as Rs.500 for the year you activate the account.</p>
                            <h4>Q: Can I invest more than 1.5 lakh in my PPF account?</h4>
                            <p>Ans: More than 1.5 lakh can be invested in the PPF account in a given year, but no interest or tax benefit will be earned on the excess amount. Indeed, according to article 80 C, the total tax deduction per financial year is only 1.5 lakh.</p>
                            <h4>Q: Is a senior citizen eligible to apply for PPF account?</h4>
                            <p>Ans: There is no age limit for opening a PPF account. Any Indian resident can open a PPF account and start investing.</p>
                            <h4>Q: Is it mandatory to withdraw all the money from my PPF account after 15 years?</h4>
                            <p>Ans: No. It is not necessary to redeem all funds held in the account at maturity. The term of the account can be extended or continued as long as the investor wishes to operate it. The account can be maintained for 5 years by extension. Extensions can be done by depositing new funds or without making any other deposits.</p>
                            <h4>Q: Is it necessary to choose a nominee in my PPF account?</h4>
                            <p>Ans: It is not mandatory to nominate candidates for a PPF account. However, it is advisable to do this to avoid conflicts in the event of death and to have a clear transfer of funds to the desired person.</p>
                            <h4>Q: How can a nominee claim funds on a PPF account?</h4>
                            <p>Ans: Nominees or legal heirs can claim funds from a PPF account when the account holder has passed away. They must produce proof of death of the account holder. Applicants can claim funds in the proportion indicated by the account holder in the application form.</p>
                            <h4>Q: What is the minimum amount to be deposited in a PPF account?</h4>
                            <p>Ans. The minimum amount to open a PPF account is as low as Rs.500 per annum.</p>
                            <h4>Q: What is the eligibility for investment under the Public Provident Fund (PPF) Scheme?</h4>
                            <p>Ans: A Public Provident Fund (PPF) account can be opened by resident Indians and individuals on behalf of minors or an unconscious person.Only one account of the Public Provident Fund (PPF) can be kept by an individual, except for an account opened in the name of a minor.A public provident account (PPF) can be opened either by the mother or by the father in the name of their minor son or daughter; however, the mother and father cannot open public provident fund (PPF) accounts in the name of the same minor.</p>
                            <h4>Q: What is the maximum amount that can be invested within the framework of the PPF account during a financial year?</h4>
                            <p>Ans: The upper limit of the investment amount is Rs. 1,50,000 per year.</p>
                            <h4>Q: What is the interest earned on the PPF account</h4>
                            <p>Ans: The current interest rate of the Public Provident Fund (PPF) is 7.9%, compounded annually.</p>
                            <h4>Q: When does a PPF account will mature?</h4>
                            <p>Ans: An account of the Public Provident Fund (PPF) matures after 15 years from the end of the year in which the account was opened.</p>
                            <h4>Q: Where can I open a PPF account?</h4>
                            <p>Ans: Individuals can open a PPF account at:<br />Post office Nationalized banks Private banksSome banks are offering online banking facility to access the PPF account.</p>
                            <h4>Q: What are the documents required for the PPF account?</h4>
                            <p>Ans: The documents required to open a PPF account are as follows:<br />PPF account opening form Proof of identity Proof of address Photograph of the account holder Proof of signature.</p>
                            <h4>Q: Which bank is best for opening a PPF account?</h4>
                            <p>Ans: Most banks are just as good as they offer smooth account opening, online funds transfer and other facilities, for example, BOB or SBI PPF. You can consider the bank in which you already have a savings account.</p>
                            <h4>Q: Can we extend PPF account after 15 years?</h4>
                            <p>Ans: Yes, an individual can extend their PPF account but this extension must be in a group of 5 years.</p>
                            <h4>Q: Can I open a PPF account with my wife or my child?</h4>
                            <p>Ans: No. The option of jointly holding PPF accounts is not provided for under the PPF regime. A person can only hold and operate a PPF account by self only.</p>
                            <h4>Q: Can I re-deposit the withdrawal amount from my PPF account to meet the minimum annual investment requirement?</h4>
                            <p>Ans: Yes, you can withdraw money for personal use. It can be used to invest the required Rs.500 as an annual investment.</p>
                            <h4>Q: I deposited money into my parents' PPF accounts but was not eligible for the U/S 80C tax deduction. Why?</h4>
                            <p>Ans: Only contributions made to self PPF account can be claimed as a U/S 80C deduction under income tax law. The total contribution to any of the above-mentioned person's accounts is subject to the investment limit of 1.5 lakhs per year.</p>
                            <h4>Q: How long can I extend my PPF account?</h4>
                            <p>Ans: PPF accounts have a term of 15 years. However, this can be extended as long as the account holder wishes to continue it. Extensions can be done for 5 years at a time. <br />For example, if an account expires on March 31, 2020, it can be extended until March 31, 2025. The next extension will be until March 31, 2030, and so on.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default PublicPF