import React, { useState } from 'react'
import { useEffect } from 'react'
import { Card, Col, Row, Form, Button, Breadcrumb } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {toast} from 'react-toastify'
import { API } from '../../App'
import Layout from '../../layout/Layout'
import Cookies from 'js-cookie'
const BranchEdit = () => {
  const tokenAuth = async() => {
    const token = Cookies.get('jwt')
    const response = await API.post("/home",{},{headers:{"Authorization" : `Bearer ${token}`}})
    if(response.data.status===false){
        navigate("/")
    }
  }
  const [validate,setValidate] = useState(false)
  const navigate = useNavigate()
  const {_id} = useParams()
  const [data,setData] = useState([])
  const [city,setCity] = useState([])
  const [bank,setBank] = useState([])
  const [user,setUser] = useState({
    Bank : "",
    State : "",
    District : "",
    Branch : "",
    BranchCode : "",
    IFSCCode : "",
    MICRCode : "",
    Address : "",
    Disclaimer : ""
  })

  const getData = async() => {
    const token = Cookies.get('jwt')
    const response = await API.post(`/showBranch/${_id}`,{},{headers:{"Authorization" : `Bearer ${token}`}})
    if(response.data.status===false){
      if(response.data.response_code===429){
          navigate("/")
      }else{
        toast.error('Something went wrong')
      }
    }else{
      setUser({
        Bank : response.data.data.bankId,
        State : response.data.data.stateId,
        District : response.data.data.cityId,
        Branch : response.data.data.branchName,
        BranchCode : response.data.data.branchCode,
        IFSCCode : response.data.data.IFSC_Code,
        MICRCode : response.data.data.MICR_Code,
        Address : response.data.data.address,
        Disclaimer : response.data.data.disclaimer
      })
    
      const res = await API.post("/getState",{},{headers:{"Authorization" : `Bearer ${token}`}})
      if(res.data.status===false){
        if(res.data.response_code===429){
            navigate("/")
        }else{
          toast.error('Something went wrong in State List')
        }
      }else{
          setData(res.data.data.data)
      }
      const Form = new FormData
      Form.append("stateId",response.data.data.stateId)
      const cityRes = await API.post("/cityByState",Form,{headers:{"Authorization" : `Bearer ${token}`}})
      if(cityRes.data.status===false){
        if(cityRes.data.response_code===429){
            navigate("/")
        }else{
          toast.error('Something went wrong in City List')
        }
      }else{
          setCity(cityRes.data.data)
      }
      const bankRes = await API.post("/ifsc/bank/list",{},{headers:{"Authorization" : `Bearer ${token}`}})
      if(bankRes.data.status===false){
        if(bankRes.data.response_code===429){
            navigate("/")
        }else{
          toast.error('Something went wrong in Bank List')
        }
      }else{
          setBank(bankRes.data.data)
      }
    
    }
  }
  const saveData = async() => {
    if(user.Bank==="" || user.State === "" || user.District === "" || user.Branch === "" || user.BranchCode === "" ||
    user.IFSCCode === "" || user.MICRCode === "" || user.Address === "" || user.Disclaimer === "")
    {
      setValidate(true)
    }else{
      const Form = new FormData()
      Form.append('bankId',user.Bank)
      Form.append('stateId',user.State)
      Form.append('cityId',user.District)
      Form.append('branch',user.Branch)
      Form.append('branchCode',user.BranchCode)
      Form.append('ifsc',user.IFSCCode)
      Form.append('micr',user.MICRCode)
      Form.append('address',user.Address)
      Form.append('disclaimer',user.Disclaimer)
      const token = Cookies.get('jwt')
      const response = await API.post(`/editBranch/${_id}`,Form,{headers:{"Authorization" : `Bearer ${token}`}})
      if(response.data.status===true){
        toast.success('Branch Updated Succesfully')
        navigate("/branch")
      }else{
        if(response.data.response_code===429){
          navigate("/")
        }else{
          toast.error('Something went wrong')
        }
      }
    }
  }

  const getValue = async(e) => {
    setUser({...user,[e.target.name]:e.target.value})
    if(e.target.name==="State"){
      if(e.target.value!==""){
        const Form = new FormData
        Form.append("stateId",e.target.value)
        const token = Cookies.get('jwt')
        const response = await API.post("/cityByState",Form,{headers:{"Authorization" : `Bearer ${token}`}})
        console.log(response,"--------res");
        setUser({...user,['District']:"",[e.target.name]:e.target.value})
        if(response.data.status===true){
          setCity(response.data.data)
        }else{
          if(response.data.response_code===429){
            navigate("/")
          }else{
            setCity([])
          }
         
        }
      }
    }
  }

  useEffect(()=>{
    tokenAuth()
    getData()
  },[])

  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3>Edit Branch</h3>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/branch">Branch List</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Edit Branch</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="page-content">
      <Form noValidate validated={validate}>
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Row className='align-items-center'>
                  <Col lg={4}>
                    <Form.Group>
                      <Form.Label htmlFor='Branch-sid'>State Name</Form.Label>
                      <Form.Select id="Branch-sid" className="my-2" name='State' onChange={(e)=>{ getValue(e) }} required>
                        <option value="">Select State Name</option>
                        {
                          data.map((dataVal,ind)=>{
                            return(
                              <option selected={dataVal._id===user.State ? true : false} value={dataVal._id} key={ind}>{dataVal.name}</option>
                            )
                          })
                        }
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group>
                      <Form.Label htmlFor='District-id'>District Name</Form.Label>
                      <Form.Select id="District-id" className="my-2" name='District' onChange={(e)=>{ getValue(e) }} required>
                        <option value="">Select District Name</option>
                        {
                          city.map((dataVal,ind)=>{
                            return(
                              <option selected={dataVal._id===user.District ? true : false} value={dataVal._id} key={ind}>{dataVal.name}</option>
                            )
                          })
                        }
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group>
                      <Form.Label htmlFor='Branch-sid'>Bank Name</Form.Label>
                      <Form.Select id="Branch-sid" className="my-2" name='Bank' onChange={(e)=>{ getValue(e) }} required>
                        <option value="">Select Bank Name</option>
                        {
                          bank.map((dataVal,ind)=>{
                            return(
                              <option selected={dataVal._id===user.Bank ? true : false} value={dataVal._id} key={ind}>{dataVal.name}</option>
                            )
                          })
                        }
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={3} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='Branch'>Branch</Form.Label>
                      <Form.Control type="text" className="my-2" id='Branch' name='Branch' value={user.Branch} onChange={(e)=>{ getValue(e) }} placeholder="Enter Your Branch" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={3} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='Branch-code'>Branch Code</Form.Label>
                      <Form.Control type="text" className="my-2" id='Branch-code' name='BranchCode' value={user.BranchCode} onChange={(e)=>{ getValue(e) }} placeholder="Enter Your Branch Code" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={3} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='IFSC-code'>IFSC Code</Form.Label>
                      <Form.Control type="text" className="my-2" id='IFSC-code' name='IFSCCode' value={user.IFSCCode} onChange={(e)=>{ getValue(e) }} placeholder="Enter Your IFSC Code" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={3} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='MICR-code'>MICR Code</Form.Label>
                      <Form.Control type="text" className="my-2" id='MICR-code' name='MICRCode' value={user.MICRCode} onChange={(e)=>{ getValue(e) }} placeholder="Enter Your MICR Code" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={6} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='Address'>Address</Form.Label>
                      <Form.Control as="textarea" rows={4} className="my-2" id='Address' name='Address' value={user.Address} onChange={(e)=>{ getValue(e) }} placeholder="Enter Your Address" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={6} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='Disclaimer'>Disclaimer</Form.Label>
                      <Form.Control as="textarea" rows={4} className="my-2" id='Disclaimer' name='Disclaimer' value={user.Disclaimer} onChange={(e)=>{ getValue(e) }} placeholder="Enter Your Disclaimer" required/>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-end">
                <Button variant ="primary" className="me-3" onClick={saveData}>Save</Button>
                <Link to="/branch">
                <Button variant="secondary">Cancle</Button>
                </Link>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Form>
      </div>
    </Layout>
  )
}

export default BranchEdit