import React, { useState } from 'react'
import { Breadcrumb, Row, Col, Card, Form, Button} from 'react-bootstrap'
import Layout from '../../layout/Layout'
import { API } from '../../App';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie'

const StateAdd = () => {
  const tokenAuth = async () => {
    const token = Cookies.get('jwt')
    const response = await API.post("/home", {}, { headers: { "Authorization": `Bearer ${token}` } })
    if (response.data.status === false) {
      navigate("/")
    }
  }
  const [validate,setValidate] = useState(false)
  const navigate = useNavigate()
  const [inpData, setInpData] = useState({
    Module: "",
    Api:"",
    Version:"",
  })

  const getValue = (e) => {
    setInpData({ ...inpData, [e.target.name]: e.target.value })
  }

  const saveData = async (event) => {
    if (inpData.Module === "" || inpData.Api==="" || inpData.Version==="") {
        setValidate(true)
    }else {
      if(inpData.Module.match(/^[A-Za-z& ]+$/)){
        const Form = new FormData()
        Form.append('moduleName', inpData.Module)
        Form.append('apiName', inpData.Api)
        Form.append('version', inpData.Version)
        const token = Cookies.get('jwt')
        const response = await API.post("/add/version", Form, { headers: { "Authorization": `Bearer ${token}` } })
        if (response.data.status === true) {
          toast.success("New Version Added Succesfully")
          navigate("/version")
        } else {
          if (response.data.response_code === 429) {
            navigate("/")
          } else {
            toast.error('something went wrong')
          }
        }
      }else{
        toast.warning('please enter valid Module Name')
      }
    }
  }

  useEffect(() => {
    tokenAuth()
  }, [])

  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3>Add Version</h3>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/version">Version List</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Add New Version</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="page-content">
        <Form noValidate validated={validate}>
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Row>
                  <Col lg={4}>
                    <Form.Group>
                      <Form.Label htmlFor='state-name'>Module Name</Form.Label>
                      <Form.Control type="text" min={0} className="my-2" id='state-name' value={inpData.Module} name="Module" onChange={(e) => { getValue(e) }} placeholder="Enter Module Name" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group>
                      <Form.Label htmlFor='state-name'>API Name</Form.Label>
                      <Form.Control type="text" min={0} className="my-2" id='state-name' value={inpData.Api} name="Api" onChange={(e) => { getValue(e) }} placeholder="Enter API" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group>
                      <Form.Label htmlFor='state-name'>Version</Form.Label>
                      <Form.Control type="number" min={0} className="my-2" id='state-name' value={inpData.Version} name="Version" onChange={(e) => { getValue(e) }} placeholder="Enter Version" required/>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-end">
                <Button variant="primary"type='button' className="me-3" onClick={saveData}>Save</Button>
                <Link to="/version">
                  <Button variant="secondary">Cancle</Button>
                </Link>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        </Form>
      </div>
    </Layout>
  )
}

export default StateAdd