import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { API } from '../../App';
import { Button, Card, Form, InputGroup } from 'react-bootstrap';
import Hcp_webview from './Hcp_webview';
import $ from 'jquery'

const PersonalLoan = () => {
    document.title = "Personal Loan";
    document.querySelector('meta[name="description"]').setAttribute('content', "Personal Loan");
    const [data, setData] = useState([])
    const [sData,setSData] = useState([])
    const getData = async () => {
        const response = await API.post("/loan/table/data", { loan_type: "Personal" })
        setData(response.data.data.table)
        setSData(response.data.data.table);
    }


    useEffect(() => {
        getData()
    }, [])

    const [button,setButton] = useState("Read More")
    const btnFunc = () => {
        if(button==="Read More"){
            setButton("Read Less")
            document.getElementById('type1').style.display="block"
        }else{
            setButton("Read More")
            document.getElementById('type1').style.display="none"
        }
    }

    const requestSearch = (searchedVal) => {
        const filteredRows = data.filter((row) => {
            return row.bankName.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
    
        });
        if (searchedVal.length < 1) {
            $('#Data_not_found').css({ display: "none" })
            getData()
        }
        else {
            if (filteredRows.length === 0) {
                const filteredRows1 = sData.filter((row) => {
                    return row.bankName.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
                });
                if (filteredRows1.length === 0) {
                    setData(filteredRows)
                }
                else {
                    $('#remove_tr').empty()
                    setData(filteredRows1)
                }
                // $('#Data_not_found').css({display:"block"})
                // setData(filteredRows)
                // setDataLength(0)
            }
            else {
                const filteredRows1 = sData.filter((row) => {
                    return row.bankName.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
                });
                if (filteredRows1.length === 0) {
                    $('#remove_tr').empty()
                    $('#remove_tr').append('<td colSpan="100%" class="p-0"><div class="no-found">No Records Found</div></td>')
                    setData(filteredRows)
    
                }
                else {
                    $('#remove_tr').empty()
                    setData(filteredRows1)
                    // setCurrent(1)
                }
                // setData(filteredRows)
                // setCurrent(1)
            }
        }
    };
    return (
        <div className='balance-webview'>
            <Container>
                {/* ================= Search Bar ================= */}
            <Row>
                <Col xs={12}>
                    <InputGroup className="mb-3">
                    <Form.Control
                        placeholder="Search"
                        aria-label="Search"
                        onChange={(e) => requestSearch(e.target.value)}
                        aria-describedby="basic-addon1"
                    />
                    <InputGroup.Text id="basic-addon1">
                        <i className="bx bx-search"></i>
                    </InputGroup.Text>
                    </InputGroup>
                </Col>
            </Row>
                <Row>
                    {/* ================= Description Bar ================= */}
                    <Col xs={12}>
                        <h2>Loan Details</h2>
                        <p>In today’s world, the individual's income alone is not sufficient to meet all the needs. Uncertain scenarios force the individuals to look out for securing finance through one or other means which again is a daunting task. To ease out individuals at the crucial times, many banks in India have come forward to avail personal loan. These loans can be secured easily and just requires few procedures to be followed for the borrower. Let’s understand each and every term associated with the Personal Loan in detail.&nbsp;</p>
                    </Col>
                    {/* ================= Read More ================= */}
                    <Col xs={12}>
                        <Button
                        className="w-100 btn-read-more"
                        type="primary"
                        onClick={btnFunc}
                        >
                        {button}
                        </Button>
                    </Col>
                    {/* ================= Read More Content ================= */}
                    <Col xs={12} id='type1' style={{display:"none"}}>
                            <h2>What is a Personal Loan?</h2>
                            <p>A Personal Loan is an unsecured loan which is issued by the lender and is supported only by the borrower’s creditworthiness rather than any other type of collateral. </p>
                            <p> A personal loan is also referred to as a signature loan. These loans can be secured without the use of any kind of collateral. A borrower needs to have a good credit rating in order to secure a personal loan.</p>
                            <p>Interest rates in case of a personal loan are usually high when compared to other types of loans as the risk associated with lending such loans is more as against the home loans wherein there will be some collateral against which loans will be granted.</p> <h2>Features of Personal Loan</h2> <p>• <strong>More Flexibility for End – User</strong>: The Personal Loan provides its users full freedom to use the loan amount as per their wishes as against the car loan and home loan wherein the borrower has to strictly use the loan amount for purchase of car and house or for construction of house only. <br />• <strong>No Need for Collateral:</strong> The Personal Loan is referred to as unsecured loan which means one need not have to provide any kind of security to the lender to secure the loan. This kind of loans works out for those who do not have an asset for pledging. <br />• <strong>Flexible Loan Term:</strong> The loan term in case of a personal loan is usually more and it depends on the bank and also on the loan amount. A personal loan is usually available in the range of 12 months – 60 months. </p> 
                            <h2>Factors which helps for quick approval of Personal Loan</h2> <p>• <strong>Credit Score</strong>: If an individuals credit score is 750 or above, then he/she will be qualified to secure a personal loan. Higher the credit score, higher will be the chances to get approval. <br />
                                <br />• <strong>Employment:</strong> The term Car Loan is also known as auto title loan. It is a short term loan, under which the borrower’s car title is used as collateral by the lender (bank or financial institutions). The borrower should be the ultimate owner of the vehicle. <br />
                                <br />• <strong>Annual Income</strong>: The annual income of an individual also plays an important role in securing a personal loan. As the borrower of personal loan need not have to provide any collateral against the loan, the lender will check for the income of the individual before approving the personal loan.
                            </p> <h2>&nbsp;Eligibility for securing Personal Loan</h2> <p>The following are the list of common criteria which an individual has to meet to secure Personal Loan. Both Salaried, as well as Self Employed, can avail personal loans.</p> <h2>For Self Employed individuals</h2> <p>• The age group of the borrower should be between 28 years – 68 years. <br />• The credit score (CIBIL score) should be above 750 points. <br />• The minimum amount of loan is Rs 50,000. <br />• The maximum amount of loan stands at Rs 30,00,000. <br />• Net monthly income of the individual should be Rs 25,000. </p> <h2>For Salaried Employees</h2> <p>• The individuals should be in the age bracket of 23 years – 58 years. <br />• One should have a credit (CIBIL) score of more than 750 points. <br />• The minimum loan amount is Rs 50,000 <br />• The maximum loan amount for salaried employee stands at Rs 15,00,000. <br />• Net monthly income of the salaried employee should be Rs 15,000 (Rs 20,000 in metro cities). </p> <h2>Required Documents for Personal Loan</h2> <p>
                                <strong>In case of Salaried Employees</strong>
                            </p> <p>• <strong> Proof of Identity</strong>: Election ID Card, Driving License, PAN Card, Passport <br />• Salary Slip for the last 3 months <br />• <strong>Proof of Residence</strong>: Utility Bills (Electricity bill, Water bill), Passport <br />• Bank statement of Salary Account for the latest 3 months </p> <p>
                                <strong>In case of Self Employed</strong>
                            </p> <p>• <strong>Proof of Residence</strong>: Utility Bills including Electricity bill, Water bill or Passport <br />• <strong>Proof of Identity</strong>: Election Identity Card, PAN Card, Driving License, Passport <br />• Proof of Office Address <br />• <strong>Income Proof of the self-employed</strong>: Audited financial report for the last two years, last six months bank statements are required. <br />• <strong>Proof of Business</strong>: Certificate of Practice, GST Registration Certificate, Partnership Deed, Shop Act License, MOA &amp; AOA, Qualification Certificate. </p> <h2>Why should one avail a personal loan?</h2> <p>An individual can avail for a personal loan for a wide range of reasons be it for consumer durable, weddings, emergencies, pension, renovation of a home, holiday and so on. The personal loan is a kind of loan which comes in handy for an individual to immediately address the financial needs.</p> <h2>Types of Personal Loans in India</h2> <p>
                                <strong>Wedding Loan</strong> – Wedding is a costly affair in India. People go and spend every bit of their saved money on their wedding day as it is one of the most important auspicious occasion in one’s life (read as life time event). One can avail a personal loan for a wedding and it can be taken either by a bride or groom or any of the family members.
                            </p> <p>
                                <strong>Festival Loan</strong> – India has a huge list of festivals which are celebrated around the year. If an individual fails short for celebrating the festival in a grand manner then they can avail for the festival loan which is offered as a form of personal loan. But there is a limit for securing festival loans in India and it stands at Rs 50,000.
                            </p> <p>
                                <strong>Home Renovation Loan</strong> – If a house needs repair and renovation, then one can secure home renovation loan as this will not pinch the savings and one can also claim for tax redemption for interest paid amount. One can avail home renovation loan to fix the leaking roof or any kind of minimal repair and renovation work of the house.
                            </p> <p>
                                <strong>Holiday Loan</strong> – If an individual is a travel enthusiast and wishes to visit places without draining the savings then they can opt for a holiday loan. The loan amount, in this case, will depend on travel destination as well as the creditworthiness of the individual.
                            </p> <h2>Why is it important to check EMI for personal loans?</h2> <p>It is important for the borrower to understand the equated monthly instalments (EMI) by using the EMI calculators. The EMI calculator helps the individuals an option to calculate the EMI by changing the tenure and loan amount. The tool calculates the monthly instalment which the borrower has to pay towards the loan and it helps an individual to plan his finances in advance.</p>
                    
                        {/* <Col xs={12}>
                            <Col xs={12}>
                                <Button className='w-100 btn-read-more' type='primary' onClick={btnFunc}>{button}</Button>
                            </Col>
                        </Col> */}
                    </Col>
                            {/* ================= Cards ================= */}
                            <Col xs={12} className="mt-3">
                                {
                                    data.map((dataVal, ind) => {
                                        return (
                                            <Card key={ind}>
                                                <Hcp_webview key={ind}
                                                    Bank={dataVal.bankName}
                                                    Intrest={dataVal.interestRate}
                                                    Process={dataVal.processingFee}
                                                    Amount={dataVal.loanAmount}
                                                    Tenure={dataVal.tenure}
                                                />
                                            </Card>
                                        )
                                    })
                                }
                            </Col>
                </Row>
            </Container>
        </div>
    )
}
export default PersonalLoan