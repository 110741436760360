
exports.requestSearch1 = (searchedVal,data,sData,name) => {
    const filteredRows = data.filter((row) => {
        return row[name].toString().toLowerCase().includes(searchedVal.toString().toLowerCase());

    });
    if (searchedVal.length < 1) {
        return sData
    }
    else {
        if (filteredRows.length === 0) {
            const filteredRows1 = sData.filter((row) => {
                return row[name].toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
            });
            if (filteredRows1.length === 0) {
                return filteredRows
            }
            else {
                return filteredRows1
            }
        }
        else {
            const filteredRows1 = sData.filter((row) => {
                return row[name].toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
            });
            if (filteredRows1.length === 0) {
                return filteredRows

            }
            else {
                return filteredRows1
            }
        }
    }
};