import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import { Helmet } from 'react-helmet';


const SeniorCS = () => {
    document.title = "Senior Citizen Savings Scheme";
    document.querySelector('meta[name="description"]').setAttribute('content', "Senior Citizen Savings Scheme");
    return (
        <div>
        {/* <Helmet>
            <title>Senior Citizen Savings Scheme</title>
        </Helmet> */}
        <div className="webview">
            <Container>
                <Row>
                    <Col xs={12}>
                        <p><strong>Senior Citizen Savings Scheme</strong> is a government-backed savings method for Indian citizens over the age of 60. <a title="Senior Citizen Savings Scheme" href="https://www.goodreturns.in/classroom/2012/11/all-about-senior-citizen-savings-scheme-154769.html">Senior Citizen Savings Scheme</a> <strong>(SCSS)</strong> is a government-sponsored savings method for people over 60. In 2004, the government of India launched this scheme to deliver a sustained and stable income source for senior individuals for their post-retirement stage. This is one of India's most competitive investment plans, which gives its investors fairly good returns. Though it is a government-sponsored savings instrument the risk of the capital loss is minimal. The investment matures after 5 years from the date of issuance and which can be expanded periodically by 3 years additionally. </p>
                        <p>This extension is generally valid only once, and the request for an extension must be submitted within 1 year of the SCSS account maturity. <strong>SCSS</strong> is accessible through the Public / Private Banks and Post Offices throughout India. To be a government-backed saving method, the terms and conditions applicable to the SCSS are the same, irrespective of the bank / post office through which you invest. This is an innovative and long-term saving method that offers security and additional benefits that are strongly correlated with every savings or investment scheme funded by the government. The scheme's sole purpose is to enable retirees with financial assistance in terms of providing a continuous stream of income. The applicable interest rate is guaranteed and received quarterly per fiscal year. <a title="Few Best Investment Options For Senior Citizens" href="https://www.goodreturns.in/personal-finance/investment/4-best-investment-options-for-senior-citizens-1161687.html" >Few Best Investment Options For Senior Citizens</a>!!!</p>

                        <h2>Eligibility Criteria for SCSS Scheme</h2>
                        <p>To reap the benefits of the Senior Citizens Savings Scheme, you have to meet the following criteria.</p>
                        <ul>
                            <li>He/She must be a citizen of India.</li>
                            <li>This scheme is not available for non-residential Indians (NRIs) or an individual of Indian origin (PIOs). </li>
                            <li>Hindu Undivided Family (HUFs) are also not eligible for the scheme.</li>
                            <li>Since this is a senior citizen savings scheme, every Indian resident aged 60 years are eligible for SCSS. </li>
                            <li>Retired people in the 55-60 year age range who have qualified for Voluntary Retirement Scheme (VRS) or Superannuation are entitled to take advantage of the scheme if they register for the same after one month of receiving their pension benefits.</li>
                            <li>Retired defence personnel aged 50 years or more</li>
                            <li>With a minimum deposit amount of Rs.<p> 1000 this scheme can be made viable.</p></li>
                        </ul>

                        <h2>Documents Required to Apply SCSS Scheme</h2>
                        <p>To open an account under SCSS an individual must submit the following documents with self-attested. </p>
                        <ul>
                            <li><a title="Aadhaar Card" href="https://www.goodreturns.in/aadhar-card.html" >Aadhaar Card</a></li>
                            <li>Voter ID card</li>
                            <li>Passport</li>
                            <li><a title="PAN Card" href="https://www.goodreturns.in/pan-card.html" >PAN Card</a></li>
                            <li>Utility bills such as telephone bill, electricity bill etc.</li>
                            <li>Birth certificate/senior citizen card</li>
                            <li>2 recent passport-sized colour photographs</li>
                        </ul>

                        <h2>List of Banks Where you can Open SCSS Account.</h2>
                        <p>Below listed are the top public sector banks providing the facility to generate a <strong>Senior Citizen's Savings Scheme account</strong>. ICICI bank is a major private-sector bank providing this account to senior citizens. The following list is illustrative and the scheme can even be proposed by other banks not mentioned on this list. </p>
                        <ul>
                            <li>Allahabad Bank</li>
                            <li>Andhra bank</li>
                            <li>State Bank of India</li>
                            <li>Bank of Maharashtra</li>
                            <li>Bank of Baroda</li>
                            <li>Bank of India</li>
                            <li>Corporation Bank</li>
                            <li>Canara Bank</li>
                            <li>Central Bank of India</li>
                            <li>Dena Bank</li>
                            <li>Syndicate Bank</li>
                            <li>UCO Bank</li>
                            <li>Union Bank of India</li>
                            <li>Vijaya Bank</li>
                            <li>IDBI Bank</li>
                            <li>Indian Bank</li>
                            <li>Indian Overseas Bank</li>
                            <li>Punjab National Bank</li>
                            <li>United Bank of India</li>
                        </ul>

                        <h2>Features of Senior Citizens Savings Scheme</h2>
                        <p>You must keep in mind the below-listed benefits and characteristics of SCSS before investing.</p>
                        <p><strong>Interest rates are revised quarterly:</strong> The interest rate issued under the Senior Citizen Savings Scheme is assessed every quarter, and its conjugation depends on many parameters such as market, inflation level and more. Interest rates remain the same after revision due to static economic conditions or no significant change at all.</p>
                        <p><strong>Fixed income:</strong> The interest rate announced at investment period remains fixed during the term of maturity and is not influenced by subsequent quarter modifications.</p>
                        <p><strong>Minimum and maximum deposit:</strong> Under the Senior Citizen Scheme, eligible persons require a minimum deposit of Rs.1,000 to open an account. At the same period, the quantum investment is fixed at Rs. 15 Lakh or the amount earned as a retirement advantage, whichever is lower. One can only open a joint account with his/her spouse under this scheme. Moreover, if a person maintains different accounts under this scheme, the cumulative amount deposited in all such accounts shall not cross the maximum cap.</p>
                        <p><strong>Tenure of the scheme:</strong> For the SCSS scheme the maturity term is 5 years. This may be prolonged for another 3 years, thereby significantly extending the period to 8 years. If a person wants to prolong this time by 3 years, he / she must submit Form B after duly filling it out. An extension is permitted only once. Moreover, interest rates applicable at that quarter would apply upon extension. </p>
                        <p><strong>Premature withdrawal:</strong> Under the Senior Citizen Savings Scheme an individual can withdraw from their account prematurely one year after issuance of account.1.5% of penalty from the deposited amount will be deducted in case an individual closes his/her account before the 2 years of completion. 1% of the deposited amount is levied as a penalty if an individual closes his/her account after completion of 2 years.  However, no penalty will be charged if the investor dies before the maturity of his/her account. </p>
                        <p><strong>Quarterly disbursal:</strong> Account holder of the Senior Citizen Savings Scheme is entitled for a quarterly disbursement towards their accumulated amount. On the first day of April, July, October, and January the interest payment will be applied to an individual’s SCSS account. </p>
                        <p><strong>Method of deposit:</strong> If the amount is less than Rs . 1 Lakh, an individual may opt to deposit their money in cash, but if it exceeds Rs. 1 Lakh the individual has to pay via cheque.</p>
                        <p><strong>Adding of nominee:</strong> While opening an account under Senior Citizen Savings Scheme one can nominate an applicant. In case the primary account holder dies before the maturity of his/her account, the nominee will be entitled to claim the due amount.</p>
                        <p><strong>Capital security:</strong> SCSS is approved by the government, and thus, money invested in it maintains truly outstanding security and assurance.</p>
                        <p><strong>Stable returns:</strong> SCSS has been identified to reimburse its investors with high-interest rates as compared to other saving schemes such as bank FD, RD etc. </p>

                        <h2>Minimum and Maximum Deposit Limits on SCSS</h2>
                        <p>Borrowers with a minimum deposit of Rs.1000 are permitted to render a lump sum deposit. Deposits in multiples of Rs.1000 greater than Rs.1000 must be generated. The cumulative investment on the SCSS cap is Rs.15 lakh. Although deposits may be made in cash in the SCSS accounts, this is not permitted for amounts below than Rs . 1 lakh. If the deposit sum for Senior Citizens Savings Scheme reaches Rs. 1 lakh, it is compulsory to use a cheque or demand draft to make the deposit. </p>

                        <h2>Download SCSS Application Form Online</h2>
                        <p>The Senior Citizen's Application Form is accessible through the offline mode as well as online. For offline mode you have to visit your nearest India Post Office to take or fill the form. Or else you can download the SCSS application form online from the official India Post website for opening a SCSS account at an India Post Office. A range of public and private sector financial institutions do have the service of downloading SCSS Application Form from their official websites. Additionally, at selected branches of participating banks in India, you may opt to get the SCSS application form physically. </p>
                        <p><strong>Process to fill the Senior Citizen’s Savings Scheme Application Form</strong></p>
                        <p>A SCSS account can not currently be opened through the online method. You need to download the FORM A from the official India Post website, make a print out, fill up and submit. After filling the form will all the mandated details you have to attach all the self attested documents with the form such as identity proof, address proof, age proof, and 2 Passport size photographs. While filling up the SCSS application form you need to submit some key details such as:</p>
                        <ul>
                            <li>Name of the applicant</li>
                            <li>PAN Number</li>
                            <li>Name, age, and address of your spouse in case of joint account </li>
                            <li>Name, age and address of the nominee (if any)</li>
                            <li>Details of Cheque/Demand draft (if applicable)</li>
                        </ul>

                        <h2>Registration of SCSS Account at Banks</h2>
                        <p>The SCSS account is also available in select Public / Private Sector banks apart from the post offices. Main advantages of opening a Senior Citizen's Savings Scheme account at certified banks are as follows:</p>
                        <ul>
                            <li>The accumulated interest can be paid directly into the savings bank account maintained by the depositor with the branch of the bank.</li>
                            <li>Regular account statements are delivered via post or email to depositors.</li>
                            <li>24/7 Customer support via mobile banking.</li>
                            <li>In case of a senior citizen, he / she will have to follow the basic account opening procedure, in order to complete the SCSS registration at a bank.</li>
                        </ul>

                        <h2>Premature Withdrawal of SCSS</h2>
                        <p>Premature withdrawal of Senior Citizen's Savings Scheme is authorized but in these situations, charges are applied depending on the period between opening and withdrawing of the account. SCSS's penalties for premature withdrawal are listed below:</p>
                        <ul>
                            <li>In case an exit from the scheme occurs before completion of 2 years from the date of account opening a 1.5 per cent of deposit amount deducted as a penalty</li>
                            <li>If a withdrawal initiated between 2 years to less than 5 years from the date of account opening a 1% of SCSS deposit penalty will be deducted.</li>
                        </ul>

                        <h2>Tax implications under SCSS</h2>
                        <p>Subject to Section 80C of the Income Tax Act, 1961, contributions made in a Senior Citizen Savings Scheme qualify for income tax deduction benefit up to Rs. 1.5 Lakh.</p>
                        <p>Interest earned on SCSS is completely taxable but TDS will be applied if the interest amount earned is more than Rs. 50,000 per annum. </p>

                        <h2>Calculation method of SCSS</h2>
                        <p>Deposits made to an account of the Senior Citizens Savings Scheme are accrued and paid out quarterly. Such reimbursements are automatically transferred to the savings account carried with the post office / bank, where this savings account has been initiated for senior citizens. Currently, the SCSS interest rate is 7.4 per cent (as of Q1 FY 20-21). Considering you have deposited Rs. 15 lakh (the maximum allowable amount) in SCSS, the maturity value at the end of the tenure will be Rs.20,55,000 (based on the 1-year interest rate =  Rs.1,11,000)</p>

                        <h2>Tax provisions applicable for deposits and interest under SCSS</h2>
                        <p>Deposits made under SCSS with other eligible items are liable for deduction under Section 80C up to Rs 1.50 lakh per year. This clause becomes important as other options for seeking tax benefits under Section 80C such as life insurance policy, pension plan payments, EPF account contributions, ULIP etc. are no more satisfactory for senior citizens. And instead of holding the entire Rs 15 lakh sum in a single year, you can distribute the same over the years to reap more tax benefits under Section 80C. </p>
                        <p>The interest earned is entirely taxable under SCSS. The bank would subtract tax @ 10 per cent if the interest rate for senior citizen account holders reaches Rs 50,000 in a year. For others, the TDS threshold is Rs.40,000 per annum. A senior citizen can submit Form 15H or 15G in other cases to receive interest without tax deduction in case the interest for the entire year exceeds the TDS threshold. Under this scheme, a senior citizen is allowed to claim a deduction up to Rs 50,000 for interest received by the bank or post office under Section 80 TTB. </p>

                        <h2>5 Things To Consider Before Investing In SCSS</h2>
                        <p>Due to the double-dip recession of high inflation followed by falling interest rates, all senior citizens find it tough to reach all ends. The interest rates on fixed deposits provided by banks have dropped to about 6 per cent. The govt-guaranteed Senior Citizen Savings Scheme (SCSS) provides a safer solution in such a scenario. Only an individual citizen over 60 years under the FEMA (Foreign Exchange Management Act) can set up an account under the SCSS scheme. Both single or a joint account with your spouse only can be opened under SCSS. Since eligibility under this scheme is determined in regard only to the primary holder, a spouse below the age of 60 can also be nominated.</p>
                        <p>Also, the spouse can open her / his account as the primary holder under SCSS, unless the conditions are met. You can open an SCSS account through any post office or the approved branches of permitted banks. Under SCSS a HUF is not eligible to open an account. This account can be opened for those who have taken voluntary retirement or retired on superannuation between the age of 55 to 60. In such situations, the account must be activated within one month from the date from which the retirement benefits are received. The same can be accessed even after 50 years of age in the case of a retired defence services staff. Deposits made by those under the age of 60 shall be entitled to pension benefits, up to a cap of 15 lakhs. </p>

                        <h2>FAQ's</h2>
                        <h4>Q: How to open senior citizen savings account online?</h4>
                        <p>Ans: To open an SCSS account, the customer must go to the post office or bank branch and fill out the corresponding form. The same form should be attached with KYC documents, proof of age, proof of identity, proof of address and a check for the deposit amount.</p>
                        <h4>Q: What is the maximum age to open a senior citizen savings account?</h4>
                        <p>Ans: Anyone over the age of 60 can open senior citizen savings account with all the required documents.</p>
                        <h4>Q: Can anyone open a joint SCSS account with any family member?</h4>
                        <p>Ans: An SCSS joint account can be opened by investing a maximum of Rs.15 lakh only with the spouse.</p>
                        <h4>Q: Is the 80C applicable to senior citizen savings plans?</h4>
                        <p>Ans: Yes, investments made in the SCSS are eligible for tax deductions under section 80C of the Income Tax Act, 1961.</p>
                        <h4>Q: Can I open senior citizen savings account with SBI Bank?</h4>
                        <p>Ans: Yes, any senior citizen can open senior citizen savings account with banks such as the State Bank of India. However, according to SBI guidelines, a depositor can only hold two or more SCSS accounts if the deposits in all accounts taken together do not exceed Rs.15 lakh. </p>
                        <h4>Q: What is the share of the joint account holder in the deposit in SCSS account?</h4>
                        <p>Ans: The total amount is allocated to the first depositor or applicant. Adding a spouse as a joint account does not matter in this case.</p>
                        <h4>Q: Can the two spouses open separate accounts?</h4>
                        <p>Ans: Yes, individual accounts can also be opened, as long as the deposit limit is at most Rs.15 lakh. Of course, he must respect the rules of the regime.</p>
                        <h4>Q: Is TDS applicable to the system?</h4>
                        <p>Ans: Yes, if the interest exceeds Rs 10,000 per year, TDS is applicable. In this scheme, interest payments are not exempt from withholding tax deduction.</p>
                        <h4>Q: Has a minimum limit been prescribed for the deduction of withholding tax?</h4>
                        <p>Ans: As per the Government regulations, the tax must be deducted at source according to the minimum balance.</p>
                    </Col>
                </Row>
            </Container>
        </div>
        </div>
    )
}

export default SeniorCS