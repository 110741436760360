import React, { useEffect } from 'react'
import { Breadcrumb, Card, Col, Row, Form, Button } from 'react-bootstrap'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Layout from '../../layout/Layout'
import { API } from '../../App'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {toast} from 'react-toastify'
import Cookies from 'js-cookie'

const DistrictAdd = () => {
  const tokenAuth = async () => {
    const token = Cookies.get('jwt')
    const response = await API.post("/home", {}, { headers: { "Authorization": `Bearer ${token}` } })
    if (response.data.status === false) {
      navigate("/")
    }
  }
  const [validate,setValidate] = useState(false)
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const getData = async () => {
    const token = Cookies.get('jwt')
    const response = await API.post("/getState", {}, { headers: { "Authorization": `Bearer ${token}` } })
    if (response.data.status === false) {
      if (response.data.response_code === 429) {
        navigate("/")
      } else {
        toast.error('Something went wrong')
      }
    } else {
      setData(response.data.data.data)
    }
  }
  const [status, setStatus] = useState(1)
  const [inpData, setInpData] = useState({
    State: "",
    District: ""
  })

  const getValue = (e) => {
    setInpData({ ...inpData, [e.target.name]: e.target.value })
  }

  const saveData = async () => {
    if (inpData.State === "") {
      setValidate(true)
    } else if (inpData.District === "") {
      setValidate(true)
    } else {
      if(inpData.District.match(/^[A-Za-z& ]+$/)){
        const Form = new FormData()
        Form.append('state', inpData.State)
        Form.append('name', inpData.District)
        Form.append('status', status)
        const token = Cookies.get('jwt')
        const response = await API.post("/addCity", Form, { headers: { "Authorization": `Bearer ${token}` } })
        if (response.data.status === true) {
          toast.success('District Added Succesfullly')
          navigate("/district")
        } else {
          if (response.data.response_code === 429) {
            navigate("/")
          } else {
            toast.error('Something went wrong')
          }
        }
      }else{
        toast.warning('Please enter valid District Name')
      }
    }
  }
  useEffect(() => {
    tokenAuth()
    getData()
  }, [])
  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3>Add District</h3>
        <Breadcrumb>
          <Breadcrumb.Item href='/#/home'>
            <i className='bx bx-home-alt me-2 fs-5' ></i> Home
          </Breadcrumb.Item>
          <Breadcrumb.Item href='/#/district'>
            District List
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Add New District</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="page-content">
      <Form noValidate validated={validate}>
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Row className='align-items-center'>
                  <Col lg={4}>
                    <Form.Group >
                      <Form.Label htmlFor='district-sid'>State Name</Form.Label>
                      <Form.Select id="district-sid" className="my-2" name='State' onChange={(e) => { getValue(e) }} required>
                        <option value="">Select State Name</option>
                        {
                          data.map((dataVal, ind) => {
                            return (
                              <option value={dataVal._id} key={ind}>{dataVal.name}</option>
                            )
                          })
                        }

                        {/* <option value="video">Video Id</option> */}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={4} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='district-name'>Name</Form.Label>
                      <Form.Control type="text" className="my-2" id='district-name' name="District" placeholder="Enter Your Name" onChange={(e) => { getValue(e) }} required/>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group >
                      <Form.Label className="d-block mb-2" htmlFor='district-status'>Status</Form.Label>
                      <BootstrapSwitchButton id="district-status" checked={true} onlabel='Enable' onChange={(checked) => { if (checked === true) { setStatus(1) } else { setStatus(0) } }} offlabel='Disable' width={125} />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-end">
                <Button variant="primary" className="me-3" onClick={saveData} >Save</Button>
                <Link to="/district">
                  <Button variant="secondary">Cancle</Button>
                </Link>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Form>
      </div>
    </Layout>
  )
}

export default DistrictAdd