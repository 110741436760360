import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import { Helmet } from 'react-helmet';


const EmployeePF = () => {
    // useEffect(() => {
        document.title = "Employee Provident Fund";
        document.querySelector('meta[name="description"]').setAttribute('content', "Employee Provident Fund");
    // }, []) // wait ..

    return (
        <div>
            {/* <Helmet>
                <title>Employee Provident Fund</title>
            </Helmet> */}
            <div className="webview">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <p>The <strong>Employees Provident Fund</strong> or <strong>EPF</strong> is one of the prominent saving schemes available for employees in India. The scheme was rolled out by the government of India and is under the supervision of the <strong>Employees Provident Fund Organization (EPFO)</strong>.</p>
                            <p>The EPFO is a firm which has been tasked by the central government of India to assist the Central Board of Trustees. The EPF is established as a statutory body by the Employees Provident Fund and Miscellaneous Provisions Act of 1952 and comes under the administrative control of the Ministry of Labour and Employment, Government of India. <a title="Important Points You Need To Know About Your EPF!" href="https://www.goodreturns.in/news/2019/03/06/important-points-you-need-know-about-your-epf-867285.html" >Important Points You Need To Know About Your EPF!</a></p>
                            <p>The scheme was initiated with an intension to assist the workforce in India which is engaged in the organized sector. The question of providing a long-term relief to the working people in the industrial sector had bothered government for a long time. With an aim to provide relief to the workers who are engaged in industries, to their dependents in case of premature death or on retirement of employees made the government to roll out the EPF scheme. <a title="How to Check EPF or Provident Fund Balance With UAN Number?" href="https://www.goodreturns.in/personal-finance/planning/2014/11/how-check-epf-or-provident-fund-balance-with-uan-number-321768.html" >How to Check EPF or Provident Fund Balance With UAN Number?</a></p>

                            <h2>About Employees Provident Fund</h2>
                            <p>The <a title="Employees Provident Fund" href="https://www.goodreturns.in/classroom/4-ways-to-check-epf-balance-1162783.html" >Employees Provident Fund</a><strong> or EPF</strong> is one of the <a title="saving schemes" href="https://www.goodreturns.in/saving-schemes.html" >saving schemes</a> which is directed towards the salaried class of people. </p>
                            <p> It aims to facilitate the manner of saving money to build a corpus which is sufficient for meeting their expenses post-retirement.</p>
                            <p>As of now, the scheme has facilitated more than 5 crore individuals and is directed by three different acts and they are Employees Provident Fund Scheme Act of 1952, Employees Deposit Linked Insurance Scheme Act of 1976 and the Employees Pension Scheme Act of 1995.</p>
                            <p>The first <strong>Provident Fund Act</strong> was passed during 1925 to regulate the provident funds of few private concerns and this had a limited scope. But the Royal Commission of Labour during 1929 stressed the need for creating a scheme for setting up a provident fund for industrial workers.</p>
                            <p>During 1948, the participants of the Indian Labour Conference generally agreed on setting up a statutory fund scheme to benefit the industrial workers and to test such a scheme in a particular field, the Coal Mines Provident Fund Scheme was initiated during 1948, following its success, the demand for expansion of the scheme across all the other industries grew.</p>
                            <p>In 1951, the Employees Provident Funds Ordinance was promulgated. The ordinance which was promulgated on November 15, 1951, was replaced by the <strong>Employees Provident Funds Act of 1952</strong> which was effective throughout India except in the state of Jammu and Kashmir. This act was bought into force in stages and was established in its entirety by November 1, 1952.</p>

                            <h2>What is an Employees Provident Fund (EPF)?</h2>
                            <p>The <strong>Employees Provident Fund or EPF</strong> as the name suggests is one of the saving schemes introduced to the working-class people in India by the government of India to inculcate and increase the habit of saving which will help them to build a sufficient retirement corpus. The EPF Scheme was introduced under the Employees Provident Fund and Miscellaneous Act of 1952.</p>
                            <p>The EPF as a whole is managed and administered by the Central Board of Trustees which consists of representatives from three parties – employers, employees and the government.</p>
                            <p>The Employees Provident Fund Organization (EPFO) assists the board of EPF in all its activities and it works under the direct discretion of the government and is managed through the Ministry of Labour and Employment.</p>
                            <p>The Employees Provident Fund or EPF is a collection of funds with the contribution made by both the employer and employee every month based on the salary of an individual. The employer and employee have to contribute each at 12% of the employee’s basic salary and dearness allowances towards the employee provident fund.</p>
                            <p>The contributions made towards the fund will earn a fixed level of interest which is determined by the EPFO. The amount of interest earned on the EPF along with the total accumulated amount in the fund is completely tax-free.</p>
                            <p>Post-retirement, the total amount accumulated in EPF can be withdrawn by an employee or it can even be withdrawn by the legal heir or the nominee after the death of the employee and the entire amount is tax-free and the employee need not have to worry about paying tax on the withdrawn funds.</p>

                            <h2>Eligibility Criteria to Become an EPF Member</h2>
                            <p>The <strong>Employee Provident Fund (EPF)</strong> can be opened by any of the employees who are working either in public or private organizations in India.</p>
                            <ul>
                                <li>Any organization in India which employs at least 20 people is deemed to be liable to extend benefits as mentioned in EPF to all of its employees.</li>
                                <li>Any organization which have less than 20 employees can also join the EPF scheme on a voluntary basis.</li>
                                <li>It is mandatory for salaried employees whose monthly income is less than Rs 15,000 to register for an EPF account.</li>
                                <li>Those whose monthly income is more than Rs 15,000 can also register for an EPF account, after receiving the due approval from the Assistant PF Commissioner.</li>
                                <li>When an employee becomes an active member of the scheme, they will be automatically eligible for a range of benefits which can be availed in the form of Employees Provident Fund benefits, pension benefits and insurance benefits.</li>
                            </ul>
                            <p><strong>Note:</strong> That all the employees across India (only exception is Jammu and Kashmir State) are eligible to get benefits from the provisions of the EPF scheme.</p>

                            <h2>Interest Rate of EPF Scheme</h2>
                            <ul>
                                <li>The Employee Provident Fund offers a pre-fixed interest rate which stands at 8.55% for the fiscal year 2019 – 2020. The interest amount accumulated on the investments made in the PF online account is tax-free.</li>
                                <li>The rate of interest will apply only on EPF deposits which are made between the financial year April month of this year to March month of the next year.</li>
                                <li>The interest amount on the EPF is calculated monthly and will be transferred to the Employees’ Provident Fund account every year on March 31 of the applicable financial year.</li>
                                <li>If any kind of contribution is not made into an EPF account for thirty-six months continuously, then that account will become dormant or non-operative.</li>
                                <li>The transferred interest amount of the EPF (yearly ones) will be summed up with the next month i.e. April’s balance and it will again be used for calculating the interest.</li>
                                <li>The interest will be offered on the non-operative accounts of employees who have still not attained the retirement age.</li>
                                <li>The interest earned on the non-operative accounts is subject to income tax based on the member’s tax slab rate.</li>
                                <li>Interest will not be provided on the amount which will be deposited in an inoperative or non-operative account of the retired employees.</li>
                                <li>Any kind of contributions made towards the Employees’ Pension Scheme by the employer, the employee will not fetch any interest. But a pension is paid out of this amount after an employee attains 58 years.</li>
                                <li>Employees can calculate the interest amount accumulated in the EPF account at the end of a given financial year and this amount will be added along with the contributions made by the employer and employee at the end of the year to arrive at the total balance accumulated in the account.</li>
                                <li>The interest in the operative provident fund account of employees will be paid only to those who are yet to retire. But the accrued interest on such accounts is eligible to be taxed based on the EPF employee member’s tax slab.</li>
                            </ul>
                            <p>One should keep in mind, that the employees who have made contributions towards the Employees Provident Fund are entitled to receive a pension from the accumulated sum once they reach 58 years.</p>
                            <p>As per the rules of the Employees’ Provident Funds and Miscellaneous Provisions Act of 1952, an employee shall cease to be a member of the Pension Fund from the date he/she turns 58 years or from the date of vesting admissible benefits mentioned under the scheme, whichever is earlier.</p>

                            <h2>Benefits of Employees’ Provident Fund (EPF) Scheme</h2>
                            <p>The Employees’ Provident Fund which is backed by the government of India provides a range of benefits to all its members as it imbibes a sense of financial security and stability amongst members.</p>
                            <p>Check out the List of benefits available under the Employees’ Provident Fund Scheme.</p>
                            <h4>Builds Corpus for Retirement</h4>
                            <p>The base idea to start an employees’ provident fund by the government was to build a corpus for employees’ after their retirement. As per the scheme, around 8.33% of an employees’ contribution will be directed towards the Employee Pension Scheme. This contribution in long term will be deposited towards the employee provident fund and this will help to build a strong and healthy retirement fund. Such an accumulated fund will extend a sense of financial security and independence for employees’ post retirement.</p>
                            <h4>Long – Term Financial Security</h4>
                            <p>The amount deposited in the EPF scheme cannot be withdrawn easily and thus it helps to ensure savings on a long term basis.</p>
                            <h4>Tax – Free Savings</h4>
                            <p>The <strong>EPF scheme</strong> offers interest on the deposits to the employees at a particular rate which is pre-decided by the organization. The actual deposited amount and the interest received on it is deemed to be tax-free as per the <a title="Income Tax Act" href="https://www.goodreturns.in/section-80c-of-income-tax-act-s1.html" >Income Tax Act</a>.</p>
                            <p>If a withdrawal is made during maturity or after completing 5 years of having availed the scheme, the amount is 100% tax exempted from the income tax. If in case, the amount is withdrawn prematurely (before completion of 5 years), then it is not tax-free. This particular feature will help an employee to receive special benefits in the form of additional income to his/her savings in the form of interest.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default EmployeePF