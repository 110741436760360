import React, { useState } from 'react'
import { Card, Col, Row, Form, Button, Breadcrumb } from 'react-bootstrap'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Layout from '../../layout/Layout'
import {toast} from 'react-toastify'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { API } from '../../App'
import Cookies from 'js-cookie'
const HolidayEdit = () => {
  const tokenAuth = async () => {
    const token = Cookies.get('jwt')
    const response = await API.post("/home", {}, { headers: { "Authorization": `Bearer ${token}` } })
    if (response.data.status === false) {
      navigate("/")
    }
  }
  const [validate,setValidate] = useState(false)
  const navigate = useNavigate()
  const { _id } = useParams()
  const [data, setData] = useState([])
  const [user, setUser] = useState({
    State: "",
    Name: "",
    Day: "",
    Date: "",
    status: "",
    id: ""
  })

  const getData = async () => {
    const token = Cookies.get('jwt')
    const holidayData = await API.post(`/showHoliday/${_id}`, {}, { headers: { "Authorization": `Bearer ${token}` } })
    if (holidayData.data.status === false) {
      if (holidayData.data.response_code === 429) {
        navigate("/")
      } else {
        toast.error('Something went wrong')
      }
    } else {
      setUser({
        State: holidayData.data.data.stateId,
        Name: holidayData.data.data.name,
        Day: holidayData.data.data.day,
        Date: holidayData.data.data.date,
        status: holidayData.data.data.status === 1 ? true : false,
        id: holidayData.data.data._id
      })
    }

    const response = await API.post("/getState", {}, { headers: { "Authorization": `Bearer ${token}` } })
    if (response.data.status === false) {
      if (response.data.response_code === 429) {
        navigate("/")
      } else {
        toast.error('Something went wrong in State List')
      }
    } else {
      setData(response.data.data.data)
    }
  }

  const change = (e) => {
    setUser({ ...user, ['status']: e })
    console.log(user);
  }

  const getVal = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  const saveData = async () => {
    if (user.State === "" || user.Name === "" || user.Date === "" || user.Day === "") {
      if (user.State === "") {
        setValidate(true)
      } else if (user.Name === "") {
        setValidate(true)
      } else if (user.Date === "") {
        setValidate(true)
      } else if(user.Day===""){
        setValidate(true)
      }
    } else {
      if(user.Name.match(/^[A-Za-z& ]+$/) && user.Day.match(/^[A-Za-z& ]+$/) ){
        const Form = new FormData
        Form.append("stateId", user.State)
        Form.append("name", user.Name)
        Form.append("date", user.Date)
        Form.append("day", user.Day)
        Form.append("status", user.status === true ? 1 : 0)
        const token = Cookies.get('jwt')
        const response = await API.post(`/editHoliday/${_id}`, Form, { headers: { "Authorization": `Bearer ${token}` } })
        if (response.data.status === true) {
          toast.success('Holiday Updated Succesfully')
          navigate("/holiday")
        } else {
          if (response.data.response_code === 429) {
            navigate("/")
          } else {
            toast.error('Something went wrong')
          }
        }
      }else{
        if(!user.Name.match(/^[A-Za-z& ]+$/)){
          toast.warning('Please enter valid Reason')
        }else{
          toast.warning('Please enter valid Day')
        }
      }
    }
  }

  useEffect(() => {
    tokenAuth()
    getData()
  }, [])


  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3>Edit Holiday</h3>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/holiday">Holiday List</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Edit Holiday</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="page-content">
      <Form noValidate validated={validate}>
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Row className='align-items-center'>
                  <Col lg={4}>
                    <Form.Group>
                      <Form.Label htmlFor='holiday-sid'>State Name</Form.Label>
                      <Form.Select id="holiday-sid" className="my-2" name='State' onChange={(e) => { getVal(e) }} required>
                        <option value="">Select State Name</option>
                        {
                          data.map((dataVal, ind) => {
                            return (
                              <option selected={dataVal._id === user.State ? true : false} value={dataVal._id} key={ind}>{dataVal.name}</option>
                            )
                          })
                        }
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={4} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='holiday-name'>Name</Form.Label>
                      <Form.Control type="text" className="my-2" id='holiday-name' value={user.Name} name="Name" onChange={(e) => { getVal(e) }} placeholder="Enter Your Name" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={4} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='holiday-date'>Date</Form.Label>
                      <Form.Control type="text" className="my-2" id='holiday-date' value={user.Date} name="Date" onChange={(e) => { getVal(e) }} placeholder="Enter Your Date" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={4} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='holiday-day'>Day</Form.Label>
                      <Form.Control type="text" className="my-2" id='holiday-day' value={user.Day} name="Day" onChange={(e) => { getVal(e) }} placeholder="Enter Your Day" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group>
                      <Form.Label className="d-block mb-2" htmlFor='holiday-status'>Status </Form.Label>
                      <BootstrapSwitchButton id="holiday-status" checked={user.status} onChange={change} onlabel='Enable' offlabel='Disable' width={125} />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-end">
                <Button variant="primary" className="me-3" onClick={saveData}>Save</Button>
                <Link to="/holiday">
                  <Button variant="secondary">Cancle</Button>
                </Link>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Form>
      </div>
    </Layout>
  )
}

export default HolidayEdit