import React, { useState } from 'react'
import {Breadcrumb, Row, Col, Card, Form, Button, InputGroup} from 'react-bootstrap'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Layout from '../../layout/Layout'
import {toast} from 'react-toastify'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { API } from '../../App';
import { useEffect } from 'react';
import Cookies from 'js-cookie'
const BankEdit = () => {
  const tokenAuth = async () => {
    const token = Cookies.get('jwt')
    const response = await API.post("/home", {}, { headers: { "Authorization": `Bearer ${token}` } })
    if (response.data.status === false) {
      navigate("/")
    }
  }
  const [validate,setValidate] = useState(false)
  const navigate = useNavigate()
  const { _id } = useParams()
  const [user, setUser] = useState({
    Name: "",
    Color: "",
    Status: "",
    LoanStatus: "",
    Image: ""
  })

  const getData = async () => {
    const token = Cookies.get('jwt')
    const bankData = await API.post(`/showBank/${_id}`, {}, { headers: { "Authorization": `Bearer ${token}` } })
    if (bankData.data.status === false) {
      if (bankData.data.response_code === 429) {
        navigate("/")
      } else {
        toast.error('Something went wrong')
      }
    } else {
      setUser({
        Name: bankData.data.data.name,
        Color: bankData.data.data.colour,
        Status: bankData.data.data.status,
        LoanStatus: bankData.data.data.loanStatus,
        Image: bankData.data.data.image
      })
    }
  }

  const getVal = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  const [selectedImage, setSelectedImage] = useState(null);

  const onImags = (event) => {
    console.log(event.target.files[0]);
    setSelectedImage(event.target.files[0]);
  }

  const saveData = async () => {
    if (user.Name === "" || user.Color === "") {
      if (user.Name === "") {
        setValidate(true)
      } else {
        setValidate(true)
      }
    } else {
      const Form = new FormData()
      Form.append('name', user.Name)
      Form.append('status', user.Status)
      Form.append('loanStatus', user.LoanStatus)
      Form.append('color', user.Color)
      Form.append('image', selectedImage === null ? user.Image : selectedImage)
      const token = Cookies.get('jwt')
      const response = await API.post(`/editBank/${_id}`, Form, { headers: { "Authorization": `Bearer ${token}` } })
      if (response.data.status === true) {
        toast.success('Bank Updated Succesfully')
        navigate("/bank")
      } else {
        if (response.data.response_code === 429) {
          navigate("/")
        } else {
          toast.error('Something went wrong')
        }
      }
    }
  }

  useEffect(() => {
    tokenAuth()
    getData()
  }, [])

  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3>Edit Bank</h3>
        <Breadcrumb>
          <Breadcrumb.Item href='/#/home'>
            <i className='bx bx-home-alt me-2 fs-5' ></i> Home
          </Breadcrumb.Item>
          <Breadcrumb.Item href='/#/bank'>
            Bank List
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Edit Bank</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="page-content">
      <Form noValidate validated={validate}>
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Row>
                  <Col lg={4} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='state-name'>Name</Form.Label>
                      <Form.Control type="text" className="my-2" id='state-name' value={user.Name} name="Name" onChange={(e) => { getVal(e) }} placeholder="Enter Your Name" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={4} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='state-name'>Image</Form.Label>
                      <InputGroup className="my-2">
                        <Form.Control type="file" id='state-name' onChange={(e) => { { onImags(e) } }} placeholder="Enter Your Name" />
                        <img src={user.Image} alt="Logo" height={40} />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col lg={4} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='state-name'>Color Code</Form.Label>
                      <Form.Control type="text" className="my-2" id='state-name' value={user.Color} name="Color" onChange={(e) => { getVal(e) }} placeholder="Enter Color Code" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={2} sm={12} >
                    <Form.Group >
                      <Form.Label className="d-block mb-2" htmlFor='bank-status'>Status </Form.Label>
                      <BootstrapSwitchButton id="bank-status" checked={user.Status === 1 ? true : false} onChange={(checked) => { if (checked === true) { setUser({ ...user, Status: 1 }) } else { setUser({ ...user, Status: 0 }) } }} onlabel='Enable' offlabel='Disable' width={125} />
                    </Form.Group>
                  </Col>
                  <Col lg={2} sm={12} >
                    <Form.Group >
                      <Form.Label className="d-block mb-2" htmlFor='bank-status'>Loan Status </Form.Label>
                      <BootstrapSwitchButton id="bank-status" checked={user.LoanStatus === 1 ? true : false} onChange={(checked) => { if (checked === true) { setUser({ ...user, LoanStatus: 1 }) } else { setUser({ ...user, LoanStatus: 0 }) } }} onlabel='Enable' offlabel='Disable' width={125} />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-end">
                <Button variant="primary" className="me-3" onClick={saveData}>Save</Button>
                <Link to="/bank">
                  <Button variant="secondary">Cancle</Button>
                </Link>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Form>
      </div>
    </Layout>
  )
}

export default BankEdit