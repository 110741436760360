import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { API } from '../../App'

const AmcAbout = () => {
    document.title = "About Fund";
    document.querySelector('meta[name="description"]').setAttribute('content', "About Fund");
    const [data,setData] = useState("")
    const {_id} = useParams()
    const getData = async() => {
        const response = await API.post("/amc/detail/about",{amc_id:_id})
        setData(response.data.data.about)
    }
    useEffect(()=>{
        getData()
    },[])
    return (
        <div className='balance-webview'>
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="content" dangerouslySetInnerHTML={{__html: data}}></div>
                    </Col>
                </Row>
            </Container >
        </div >
    )
}

export default AmcAbout