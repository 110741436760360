import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import LogoMini from "../Component/Logo-mini";
import Logo from "../Component/Logo";
// import Dropdown from "react-bootstrap/Dropdown";
// import { API } from "../App";
// import { useToasts } from "react-toast-notifications";

const Sidebar = ({ sidebar }) => {

  // 
  const [classActive, setClassActive] = useState({
    drp1: false,
    drp2: false,
    drp3: false,
    drp4: false,
  });

  const handleActiveClass = (name) => {
    let data = {
      drp1: false,
      drp2: false,
      drp3: false,
      drp4: false,
    }
    data[name] = classActive[name] === true ? false : true
    setClassActive(data)
  };

  return (
    <>
      <div className={sidebar ? "sidebar-wrapper active" : "sidebar-wrapper"}>
        <div className="sidebar-header">
          <div className="d-flex justify-content-between">
            <div className='sidebar-logo'>
              <Link to="/home">
                <Logo />
                <LogoMini />
              </Link>
            </div>
            <div className="toggler">
              <NavLink to="" className="sidebar-hide d-xl-none d-block">
                <i className="bi bi-x bi-middle"></i>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="sidebar-menu">
          <ul className="menu">
            <li className="sidebar-item">
              <NavLink to="/home" className="sidebar-link">
                <i className="bx bxs-home"></i>
                <span>Home</span>
              </NavLink>
            </li>

            <li className="sidebar-item">
              <NavLink to="/state" className="sidebar-link">
                <i className="bx bxs-city"></i>
                <span>State</span>
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink to="/district" className="sidebar-link">
                <i className="bx bxs-buildings"></i>
                <span>District</span>
              </NavLink>
            </li>
            {/* Bank */}
            <li className="sidebar-item has-sub">
              <div onClick={() => { handleActiveClass("drp1") }} className="sidebar-link">
                <i className="bx bxs-bank"></i>
                <span>Bank</span>
              </div>
              <ul className={`${"submenu"} ${classActive.drp1 ? "active" : "inactive"}`} >
                <li className="submenu-item">
                  <NavLink to="/bank">
                    <span>Bank</span>
                  </NavLink>
                </li>
                <li className="submenu-item">
                  <NavLink to="/bank-detail">
                    <span>Bank Details</span>
                  </NavLink>
                </li>
                <li className="submenu-item">
                  <NavLink to="/branch">
                    <span>Branch</span>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="sidebar-item">
              <NavLink to="/holiday" className="sidebar-link">
                <i className="bx bxs-calendar-event"></i>
                <span>Holiday</span>
              </NavLink>
            </li>
            <li className="sidebar-item has-sub">
              <div onClick={() => { handleActiveClass("drp2") }} className="sidebar-link">
                <i className='bx bx-money-withdraw'></i>
                <span>Loan</span>
              </div>
              <ul className={`${"submenu"} ${classActive.drp2 ? "active" : "inactive"}`} >
                <li className="submenu-item">
                  <NavLink to="/loan">
                    <span>Loan</span>
                  </NavLink>
                </li>
                <li className="submenu-item">
                  <NavLink to="/loandetail">
                    <span>Loan Details</span>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="sidebar-item has-sub">
              <div onClick={() => { handleActiveClass("drp3") }} className="sidebar-link">
                <i className="bx bx-rupee"></i>
                <span>Mutual Fund</span>
              </div>
              <ul className={`${"submenu"} ${classActive.drp3 ? "active" : "inactive"}`} >
                <li className="submenu-item">
                  <NavLink to="/mutualfund">
                    <span>Mutual Fund</span>
                  </NavLink>
                </li>
                <li className="submenu-item">
                  <NavLink to="/mutualfunddetail">
                    <span>Mutual Fund Details</span>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="sidebar-item has-sub">
              <div onClick={() => { handleActiveClass("drp4") }} className="sidebar-link">
                <i className='bx bxs-building'></i>
                <span>AMC</span>
              </div>
              <ul className={`${"submenu"} ${classActive.drp4 ? "active" : "inactive"}`} >
                <li className="submenu-item">
                  <NavLink to="/amc">
                    <span>AMC</span>
                  </NavLink>
                </li>
                <li className="submenu-item">
                  <NavLink to="/amcdetail">
                    <span>AMC Deatils</span>
                  </NavLink>
                </li>
                <li className="submenu-item">
                  <NavLink to="/mutualfundcompany">
                    <span>MF Company</span>
                  </NavLink>
                </li>
                <li className="submenu-item">
                  <NavLink to="/funddetail">
                    <span>Top Fund Details</span>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="sidebar-item">
              <NavLink to="/fuel" className="sidebar-link">
                <i className='bx bxs-gas-pump' ></i>
                <span>Fuel</span>
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink to="/version" className="sidebar-link">
              <i className='bx bxl-nodejs' ></i>
                <span>Version</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
