import React from 'react'
import Layout from '../layout/Layout'
import { Row, Col, Card } from 'react-bootstrap';
import { useEffect } from 'react';
import { API } from '../App';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    defaults
  } from 'chart.js';
import { Bar, Doughnut, Pie } from 'react-chartjs-2';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useState } from 'react';
import Cookies from 'js-cookie';

defaults.font.family = 'Maven Pro';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const Home = () => {
    const navigate = useNavigate()
    const tokenAuth = async() => {
        const token = Cookies.get('jwt')
        const response = await API.post("/home",{},{headers:{"Authorization" : `Bearer ${token}`}})
        if(response.data.status===false){
            navigate("/")
        }
    }
    const [data,setData] = useState({
      state:"",
      district:"",
      bank:"",
      loan:""
    })
    const [loan,setLoan] = useState({
      label:"",
      data:""
    })
    const [fund,setFund] = useState({
      label:"",
      data:""
    })
    const [amc,setAmc] = useState({
      label:"",
      data:""
    })
    const getData = async(req,res) => {
        const token = Cookies.get('jwt')
        const response = await API.post("/home/data",{},{headers:{"Authorization" : `Bearer ${token}`}})
        if (response.data.status === false) {
            if (response.data.response_code === 429) {
                navigate("/")
            } else {
                toast.error("Something went wrong")
            }
        }else{
            setData({
                state:response.data.data.state,
                district:response.data.data.district,
                bank:response.data.data.bank,
                loan:response.data.data.loan
            })
            let loanLabel = []
            let loanData = []
            response?.data?.data?.loanChart?.map((val,ind)=>{
                loanLabel.push(val.name)
                loanData.push(val.count)
            })
            setLoan({
              label:loanLabel,
              data:loanData
            })
            let fundLabel = []
            let fundData = []
            response?.data?.data?.paytmChart?.map((val,ind)=>{
                fundLabel.push(val.name)
                fundData.push(val.count)
            })
            setFund({
              label:fundLabel,
              data:fundData
            })
            let amcLabel = []
            let amcData = []
            response?.data?.data?.amcChart?.map((val,ind)=>{
                amcLabel.push(val.name)
                amcData.push(val.count)
            })
            setAmc({
              label:amcLabel,
              data:amcData
            })
        }
    }

    const mfoptions = {
        responsive: true,
        plugins: {
          title: {
            display: false,
          },
          legend: {
            display: true,
            position: "bottom",
            labels: {
              boxWidth: 10,
              boxHeight: 10,
              usePointStyle: true,
              pointStyle: 'rectRounded',
              font: {
                family: "Maven Pro",
                size: 14
              }
            },
            tooltip: {
              bodyFont: {
                family: "Maven Pro"
              },
              titleFont: {
                family: "Maven Pro"
              }
            }
          },
        },
      }

      const mfdata = {
        labels: amc.label,
        datasets: [
          {
            label: 'AMC',
            data: amc.data,
            backgroundColor: ['#1FD9A3', '#FFB15C', '#6A9BF4', '#FF5C84', '#DB73FF' ,'#dd3c3c','#c9e358']
          },
        ],
      }

      // Loan Doughnut Chart
      const loanoptions = {
        responsive: true,
        plugins: {
          title: {
            display: false,
          },
          legend: {
            display: true,
            position: "bottom",
            labels: {
              boxWidth: 10,
              boxHeight: 10,
              usePointStyle: true,
              pointStyle: 'rectRounded',
              font: {
                family: "Maven Pro",
                size: 14
              }
            },
            tooltip: {
              bodyFont: {
                family: "Maven Pro"
              },
              titleFont: {
                family: "Maven Pro"
              }
            }
          },
        },
      };
      const loandata = {
        labels: loan.label,
        datasets: [
          {
            label: 'Loan',
            data: loan.data,
            backgroundColor: ['#1FD9A3', '#FFB15C', '#6A9BF4', '#FF5C84', '#DB73FF']
          },
        ],
      };

      // Fuel Bar Chart
    const fueloptions = {
      responsive: true,
      plugins: {
        title: {
          display: false,
        },
        legend: {
          display: false,
          tooltip: {
            bodyFont: {
              family: "Maven Pro"
            },
            titleFont: {
              family: "Maven Pro"
            }
          }
        },
      },
      scales: {
        y: {
          ticks: {
            stepSize: 2,
            font: {
              family: "Maven Pro",
              size: 14
            }
          },
          grid: {
            display: true,
          },
          scaleLabel: {
            display: true,
            font: {
              family: "Maven Pro",
              size: 14
            }
          }
        },
        x: {
          ticks: {
            font: {
              family: "Maven Pro",
              size: 14
            }
          },
          grid: {
            display: false,
          },
          scaleLabel: {
            display: true,
            font: {
              family: "Maven Pro",
              size: 14
            }
          }
        }
      }
    };

    // const fueldata = {
    //   labels: ['Petrol', 'Diesel', 'CNG', 'LPG', 'Gold 22C', 'Gold 24C'],
    //   datasets: [
    //     {
    //       label: 'Fuel',
    //       data: [90, 100, 105, 89, 102, 101],
    //       barThickness: 20,
    //       backgroundColor: '#DB73FF',
    //     }
    //   ],
    // };

    const mutualFundData = {
      labels: fund.label,
      datasets: [
        {
          label: 'Mutual Fund',
          data: fund.data,
          barThickness: 20,
          backgroundColor: '#DB73FF',
        }
      ],
    };


    useEffect(()=>{
        tokenAuth()
        getData()
    },[])
  return (
    <Layout sidebar={true}>
        <div className="vv-dashboard">
            <Row>
                <Col xxl={3} xl={4} md={6}>
                    <Link to="/state">
                    <Card>
                        <Card.Body>
                            <div className="counter orange">
                                <div className="counter-media">
                                    <i className="bx bxs-city"></i>
                                </div>
                                <div className="counter-content">
                                    <h3>{data.state}</h3>
                                    <p>State</p>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    </Link>
                </Col>
                <Col xxl={3} xl={4} md={6}>
                    <Link to="/district">
                    <Card>
                        <Card.Body>
                            <div className="counter pink">
                                <div className="counter-media">
                                    <i className="bx bxs-buildings"></i>
                                </div>
                                <div className="counter-content">
                                    <h3>{data.district}</h3>
                                    <p>District</p>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    </Link>
                </Col>
                <Col xxl={3} xl={4} md={6}>
                    <Link to="/bank">
                    <Card>
                        <Card.Body>
                            <div className="counter green">
                                <div className="counter-media">
                                    <i className="bx bxs-bank"></i>
                                </div>
                                <div className="counter-content">
                                    <h3>{data.bank}</h3>
                                    <p>Bank</p>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    </Link>
                </Col>
                <Col xxl={3} xl={4} md={6}>
                    <Link to="/loan">
                    <Card>
                        <Card.Body>
                            <div className="counter blue">
                                <div className="counter-media">
                                    <i className='bx bx-money-withdraw'></i>
                                </div>
                                <div className="counter-content">
                                    <h3>{data.loan}</h3>
                                    <p>Loan</p>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    </Link>
                </Col>
            </Row>
            <Row>
                <Col xxl={6} md={6}>
                    <Card>
                        <Card.Body>
                            <div className="chart-title">
                                <h4>Mutual Fund</h4>
                            </div>
                            <Bar options={fueloptions} data={mutualFundData} height="137"/>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xxl={3} md={6}>
                    <Card>
                        <Card.Body>
                            <div className="chart-title">
                                <h4>Loan</h4>
                            </div>
                            <Doughnut options={loanoptions} data={loandata} />
                        </Card.Body>
                    </Card>
                </Col>
                <Col xxl={3} md={6}>
                    <Card>
                        <Card.Body>
                            <div className="chart-title">
                            <h4>AMC</h4>
                            </div>
                            <Pie options={mfoptions} data={mfdata} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    </Layout>
  )
}

export default Home