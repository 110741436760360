import React, { useEffect, useState } from 'react'
import { Breadcrumb, Row, Col, Card, Form, Button } from 'react-bootstrap'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Layout from '../../layout/Layout'
import { API } from '../../App';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
const AmcEdit = () => {

  const tokenAuth = async () => {
    const token = Cookies.get('jwt')
    const response = await API.post("/home", {}, { headers: { "Authorization": `Bearer ${token}` } })
    if (response.data.status === false) {
      navigate("/")
    }
  }
  const [validate,setValidate] = useState(false)
  const navigate = useNavigate()
  const { _id } = useParams()
  const [user, setUser] = useState({
    name: "",
    status: "",
    webview:"",
    id: ""
  })

  const getData = async () => {
    const fieldData = await API.post(`/show/amc/field/${_id}`)
    setUser({
      name: fieldData.data.data.name,
      status: fieldData.data.data.status === 1 ? true : false,
      webview: fieldData.data.data.webview === 1 ? true : false,
      id: fieldData.data.data._id
    })
  }

  const getVal = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  const change = (e,name) => {
    setUser({ ...user, [name]: e })
  }

  const saveData = async () => {
    if (user.name === "") {
      setValidate(true)
    } else {
      const Form = new FormData
      Form.append("name", user.name)
      Form.append("status", user.status === true ? 1 : 0)
      Form.append("webview", user.webview === true ? 1 : 0)
      const token = Cookies.get('jwt')
      const response = await API.post(`/edit/amc/field/${_id}`, Form, { headers: { "Authorization": `Bearer ${token}` } })
      if (response.data.status === true) {
        toast.success("AMC Field Updated Succesfully")
        navigate("/amc")
      } else {
        if (response.data.response_code === 429) {
          navigate("/")
        } else {
          toast.error('something went wrong')
        }
      }
    }
  }

  useEffect(() => {
    tokenAuth()
    getData()
  }, [])

  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3>Edit AMC</h3>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/amc">AMC List</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Edit AMC</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="page-content">
      <Form noValidate validated={validate}>
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Row>
                  <Col lg={6} sm={4}>
                    <Form.Group>
                      <Form.Label htmlFor='state-name'>Name</Form.Label>
                      <Form.Control type="text" min={0} className="my-2" id='state-name' value={user.name} onChange={(e) => { getVal(e) }} name="name" placeholder="Enter Your Name" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={2} sm={4} >
                    <Form.Group>
                      <Form.Label className="d-block mb-2" htmlFor='state-status'>Status </Form.Label>
                      <BootstrapSwitchButton id="state-status" checked={user.status} onChange={(e)=>{change(e,'status')}} onlabel='Enable' offlabel='Disable' width={125} />
                    </Form.Group>
                  </Col>
                  <Col lg={2} sm={4} >
                    <Form.Group>
                      <Form.Label className="d-block mb-2" htmlFor='state-status'>Webview </Form.Label>
                      <BootstrapSwitchButton id="state-status" checked={user.webview} onChange={(e)=>{change(e,'webview')}} onlabel='Enable' offlabel='Disable' width={125} />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-end">
                <Button variant="primary" className="me-3" onClick={saveData}>Save</Button>
                <Link to="/amc">
                  <Button variant="secondary">Cancle</Button>
                </Link>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Form>
      </div>
    </Layout>
  )
}

export default AmcEdit