import React, { useState } from 'react'
import { Button, Form, Card, Breadcrumb, Col, Row } from 'react-bootstrap'
import Layout from '../../layout/Layout'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import {toast} from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import { API } from '../../App'
import { useEffect } from 'react'
import Cookies from 'js-cookie'

const BankDetailAdd = () => {
  const tokenAuth = async () => {
    const token = Cookies.get('jwt')
    const response = await API.post("/home", {}, { headers: { "Authorization": `Bearer ${token}` } })
    if (response.data.status === false) {
      navigate("/")
    }
  }
  const [validate,setValidate] = useState(false)
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const getData = async () => {
    const token = Cookies.get('jwt')
    const response = await API.post("/getActiveBank", {}, { headers: { "Authorization": `Bearer ${token}` } })
    if (response.data.status === false) {
      if (response.data.response_code === 429) {
        navigate("/")
      } else {
        toast.error('Something went wrong')
      }
    } else {
      setData(response.data.data)
    }
  }
  const [inpData, setInpData] = useState({
    Bank: "",
    Tollfree: "",
    Statement: "",
    StateSms: "",
    CustCare: "",
    SMS: "",
    URL: "",
    Status: 1
  })

  const getValue = (e) => {
    setInpData({ ...inpData, [e.target.name]: e.target.value })
  }

  const saveData = async () => {
    if (inpData.Bank === "" || inpData.Tollfree === "" || inpData.Statement === "" || inpData.StateSms === "" ||
      inpData.CustCare === "" || inpData.SMS === "" || inpData.URL === "") {
      if (inpData.Bank === "") {
        setValidate(true)
      } else if (inpData.Tollfree === "") {
        setValidate(true)
      } else if (inpData.Statement === "") {
        setValidate(true)
      } else if (inpData.StateSms === "") {
        setValidate(true)
      } else if (inpData.CustCare === "") {
        setValidate(true)
      } else if (inpData.SMS === "") {
        setValidate(true)
      }else if(inpData.URL===""){
        setValidate(true)
      }
    } else {
      const Form = new FormData()
      Form.append('bank', inpData.Bank)
      Form.append('tollfree', inpData.Tollfree)
      Form.append('statement', inpData.Statement)
      Form.append('stateSms', inpData.StateSms)
      Form.append('custCare', inpData.CustCare)
      Form.append('sms', inpData.SMS)
      Form.append('url', inpData.URL)
      Form.append('status', inpData.Status)
      const token = Cookies.get('jwt')
      const response = await API.post("/addBankDetail", Form, { headers: { "Authorization": `Bearer ${token}` } })
      if (response.data.status === true) {
        toast.success('Bank Detail Added Succesfully')
        navigate("/bank-detail")
      } else {
        if (response.data.response_code === 429) {
          navigate("/")
        } else {
          toast.error('Something went wrong')
        }
      }
    }
  }

  useEffect(() => {
    tokenAuth()
    getData()
  }, [])

  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3>Add Bank Details</h3>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/bank-detail">Bank Details List</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Add New Bank Details</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="page-content">
      <Form noValidate validated={validate}>
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Row>
                  <Col lg={4} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='bank-name'>Bank Name</Form.Label>
                      <Form.Select id="bank-name" className="my-2" name='Bank' onChange={(e) => { getValue(e) }} required>
                        <option value="">Select Bank Name</option>
                        {
                          data.map((dataVal, ind) => {
                            return (
                              <option value={dataVal._id} key={ind}>{dataVal.name}</option>
                            )
                          })
                        }
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={4} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='tollfree-no'>Tollfree Number</Form.Label>
                      <Form.Control type="number" className="my-2" id='tollfree-no' value={inpData.Tollfree} name="Tollfree" onChange={(e) => { getValue(e) }} placeholder="Enter Your Tollfree Number" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={4} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='ministatement'>Ministatement</Form.Label>
                      <Form.Control type="number" className="my-2" id='ministatement' value={inpData.Statement} name="Statement" onChange={(e) => { getValue(e) }} placeholder="Enter Your Ministatement" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={4} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='ministatement-sms'>Ministatement SMS</Form.Label>
                      <Form.Control type="text" className="my-2" id='ministatement-sms' value={inpData.StateSms} name="StateSms" onChange={(e) => { getValue(e) }} placeholder="Enter Your Ministatement SMS" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={4} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='tollfree-no'>Customer Care Number</Form.Label>
                      <Form.Control type="number" className="my-2" id='tollfree-no' value={inpData.CustCare} name="CustCare" onChange={(e) => { getValue(e) }} placeholder="Enter Your Number" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={4} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='sms-inqry'>SMS Inquiry</Form.Label>
                      <Form.Control type="text" className="my-2" id='sms-inqry' value={inpData.SMS} name="SMS" onChange={(e) => { getValue(e) }} placeholder="Enter Your SMS Inquiry" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={4} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='n-url'>Netbanking URL</Form.Label>
                      <Form.Control type="text" className="my-2" id='n-url' value={inpData.URL} name="URL" onChange={(e) => { getValue(e) }} placeholder="Enter Your Netbanking URL" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={4} sm={12}>
                    <Form.Group>
                      <Form.Label className="d-block mb-2" htmlFor='bank-status'>Status </Form.Label>
                      <BootstrapSwitchButton id="bank-status" checked={inpData.Status === 1 ? true : false} onChange={(checked) => { if (checked === true) { setInpData({ ...inpData, Status: 1 }) } else { setInpData({ ...inpData, Status: 0 }) } }} onlabel='Enable' offlabel='Disable' width={125} />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-end">
                <Button variant="primary" className="me-3" onClick={saveData}>Save</Button>
                <Link to="/bank-detail">
                  <Button variant="secondary">Cancle</Button>
                </Link>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Form>
      </div>
    </Layout>
  )
}

export default BankDetailAdd