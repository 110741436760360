import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import { Helmet } from 'react-helmet';


const PMVaya = () => {
    document.title = "Pradhan Mantri Vaya Vandana Yojana";
    document.querySelector('meta[name="description"]').setAttribute('content', "Pradhan Mantri Vaya Vandana Yojana");
    return (
        <div>
        {/* <Helmet>
            <title>PM Vaya Vandana Yojna</title>
        </Helmet> */}
        <div className="webview">
            <Container>
                <Row>
                    <Col xs={12}>
                        <p><strong>Pradhan Mantri Vaya Vandana Yojana</strong> is a pension scheme which provides the country's senior citizens with sustainable income avenues. This pension programme, supported by the Indian Government, tackles one's need for post-retirement financial preparation under National Savings Schemes.  Individuals more than 60 years of age can avail this scheme and the scheme has now been extended for a further period of three years beyond 31 March 2020 until 31 March 2023. <a title="Pradhan Mantri Vaya Vandana Yojana Modified 2020" href="https://www.goodreturns.in/personal-finance/investment/lic-pradhan-mantri-vaya-vandana-yojana-pmvvy-modified-20-1161378.html">Pradhan Mantri Vaya Vandana Yojana</a> is provided by India's Life Insurance Corporation (LIC) offering a fixed 10-year pension payout at a specified rate. It also provides a life cover to the nominee in the form of the return of purchase price.</p>
                        <p>The fixed pension rates for policies sold within a year will be evaluated and settled by the Ministry of Finance, Government of India, at the beginning of each year, in compliance with the terms and conditions under this scheme. The scheme will provide a guaranteed pension of 7.40% p.a. for the first financial year, i.e. up to 31 March 2021. In the 2018-19 fiscal year's annual budget presentation, the government had also raised the investment threshold for the policy at Rs. 15 Lakh. However, before entrusting in it, it is necessary to perceive thorough knowledge of the desired investment parkway. So, if you're a senior citizen and have a lump sum amount of cash then you can consider purchasing this initial annuity scheme until March 31, 2023.</p>

                        <h2>Eligibility Criteria and other Limitations</h2>
                        <p>To apply for <strong>Pradhan Mantri Vaya Vandana Yojana</strong> you must have to meet the following eligibility criteria. </p>
                        <ul>
                            <li>Minimum age 60 years (completed), no limit on maximum entry age</li>
                            <li>Tenure of the policy 10 years</li>
                            <li>Investment limit up to Rs 15 lakh per senior citizen</li>
                            <li>Minimum pension amount: Rs 1,000/- per month, Rs 3,000/- per quarter, Rs 6,000/- per half-year and Rs 12,000/- per year.</li>
                            <li>Maximum pension amount: Rs 12,000/- per month, Rs 30,000/- per quarter, Rs 60,000/- per half-year and Rs 1,20,000/- per year</li>
                        </ul>
                        <p>The overall pension cap is for a family as a whole, i.e. </p>
                        <p>the cumulative pension amount should not be to surpass the maximum pension allowance for all policies allowed to a household in this scheme. The family will be composed of the pensioner, his / her spouse and eligible dependents for this reason. </p>
                        <p>The Scheme can be acquired online and offline via Life Insurance Corporation (LIC) of India which has been granted the primary right of running this scheme. Individuals have to consider the size of the family while considering a pension amount. Here, an individual 's family covers spouse and family members such as an unmarried child and a jobless son with an age cap of up to 25 years. The individual must be prepared to acquire benefit of the ten-year policy term. The fixed purchase price is Rs 1.5 lakh, and it gives a monthly pension benefit of Rs 1000. The maximum purchase cap is up to Rs 15 lakh and it gives a monthly pension benefit of Rs 10,000.</p>

                        <h2>Documents Required for Pradhan Mantri Vaya Vandana Yojana</h2>
                        <p>In order to apply for this scheme, you must submit the below-listed documents.</p>
                        <ul>
                            <li>Aadhaar card</li>
                            <li>PAN Card</li>
                            <li>Driving License</li>
                            <li>Bank passbook</li>
                            <li>Proof of address</li>
                            <li>Recent passport size photographs</li>
                            <li>Proof of retirement</li>
                        </ul>

                        <h2>Tax Benefits under Pradhan Mantri Vaya Vandana Yojana</h2>
                        <p>This saving plan also comes with tax exemptions of up to Rs. 1.5 Lakh on the deposit amount under section 80C. This saving plan also comes with tax exemptions of up to Rs. 1.5 Lakh on the amount of the deposit under section 80C. Similarly, as per the income tax act, the tax will be applicable on the interest received. However, GST will not be included in the service. Furthermore, if, in due time, the central or state government or some other approved local authority imposes legal taxes on this scheme, the tax is payable by the purchaser. </p>

                        <h2>Key Benefits of Pradhan Mantri Vaya Vandana Yojana</h2>
                        <p>Go through the below-listed to know more about benefits of <strong>Pradhan Mantri Vaya Vandana Yojana</strong></p>
                        <h4>Pension Payment </h4>
                        <p>Pension in arrears (at the end of each cycle as per the selected mode) shall be payable on existence of the Pensioner during the policy term of 10 years.</p>
                        <h4>Death benefit</h4>
                        <p>The Purchase Price shall be refunded to the beneficiary after the Pensioner 's death within the policy period of 10 years.</p>
                        <h4>Maturity benefit</h4>
                        <p>Purchase price along with final pension installment shall be payable on the existence of the pensioner up to the completion of the policy period of 10 years. </p>

                        <h2>Features of Pradhan Mantri Vaya Vandana Yojana</h2>
                        <p>The main <strong>advantages under the Pradhan Mantri Vaya Vandana Yojana</strong> are:</p>
                        <p><strong>1.</strong> The scheme allows the pension to be paid as, monthly, quarterly, half-yearly &amp; yearly basis. Pension payment will be via the payment method NEFT or Aadhaar Enabled. The pensioner will obtain the first pension payout after 1 year, 6 months, 3 months or 1 month from the day the policy is obtained as per the frequency of pension payment i.e. monthly/ quarterly/ half-yearly/ yearly. </p>
                        <p><strong>2.</strong> Under exceptional conditions such as the Pensioner requiring funds to cure some health related issue of self or spouse, the scheme allows for premature exit within the policy period. For these situations the surrender value payable is 98 per cent of the purchasing price. </p>
                        <p><strong>3.</strong> Individuals who have chosen this pension scheme are entitled to earn a fixed amount for a fixed term of 10 years at the end of a certain span selected by an individual.</p>
                        <p><strong>4.</strong> Additionally, Pradhan Mantri Vaya Vandana Yojna provides with the maturity benefit of getting the plan's lump-sum purchase price along with the final instalment reimbursement. This benefit is applicable for a pensioner 's survival to the completion of the policy term. </p>
                        <p><strong>5.</strong> In the case of the death of the pensioner during the policy period, his / her beneficiary is eligible to claim a complete purchase amount by submitting the necessary documents.</p>
                        <p><strong>6.</strong> If individuals are not satisfied with the terms and conditions stated in the contract after purchasing a policy, they are authorized to quit or return this scheme within 30 days of online purchasing date. However, you will get lock-in-period of 15 days starting from the policy purchase date in case of offline purchase of the scheme. Total purchase amount following a reduction of any relevant stamp duty or released pension payment must be refunded during the free lock-in period.</p>
                        <p><strong>7.</strong> After 3 successful years of the policy, individuals can take advantage of a loan against investment from Pradhan Mantri Vaya Vandana Yojana. Retirees can borrow up to 75 per cent of the amount of the purchase as a loan.  Applicable interest rate is recovered from the pension payment according to the frequency of loan payment chosen by the pensioner. The interest amount is due on the settlement date of the pension. </p>

                        <h2>Pradhan Mantri Vaya Vandana Yojana Pension Policy</h2>
                        <ul>
                            <li>Under the scheme, the minimum pension guaranteed is Rs.1000 per month and can rise up to Rs.10,000 per month based on the capital invested.</li>
                            <li>Under the scheme, one must spend Rs. 1,50,000 in order to get a minimum pension of Rs.1,000 a month. Additionally, for investment of Rs.1,50,000 one will get a Rs. 10,000 pension benefit</li>
                            <li>As the policy period is 10 years one will get back his / her principal after completing the tenure</li>
                            <li>In the event of the buyer 's death before completing the tenure, the total sum shall be transferred to the account of the nominated beneficiary.</li>
                            <li>Surprisingly, the pension amount isn't reliant on the recipient's age.</li>
                        </ul>

                        <h2>Loans under PMVVY</h2>
                        <ul>
                            <li>The scheme enables the pensioners who are enrolled under the scheme to receive loans in the occurrence of a medical incident such as critical self or partner illness.</li>
                            <li>75% of the purchase price is granted as the maximum loan benefit</li>
                            <li>One can apply for loan only after completing 3 years under the policy</li>
                            <li>The interest on loan will be recovered from the pension amount payable under the scheme and the outstanding loan shall, therefore, be recovered at the time of exit from the claim proceeds. </li>
                        </ul>

                        <h2>Mode of pension payment</h2>
                        <p>Pension payment modes are monthly, quarterly, half-yearly and yearly The pension payment is initiated through NEFT or Aadhaar Enabled Payment System. The purchase of the policy funded under this Policy scheme allows precise validation of the Aadhaar number. The first pension installment shall be levied after 1 year, 6 months, 3 months or 1 month from the date of issuance of the same, relying on the mode of payment of the pension, i.e. annually, half-yearly, quarterly or monthly. </p>

                        <h2>Free Lock-in Period:</h2>
                        <p>Whenever a policyholder is not pleased with the policy's "Terms and Conditions," he / she may return the policy to the Company within 15 days (30 days if this policy is purchased online) from the date of issuance of the policy specifying the reason for the disagreements. The amount to be reimbursed during the duration of free look shall be the purchase price paid by the policyholder upon deduction of the Stamp Duty and pension paid if any. </p>

                        <h2>Surrender Value</h2>
                        <p>In rare circumstances such as the Pensioner seeking funds to cure some critical / terminal self or partner disease, the scheme allows for a premature exit within the policy period. For these cases, the surrender value payable is 98 per cent of the purchasing price. </p>

                        <h2>Process to apply for PMVVY Scheme</h2>
                        <p>Individuals can purchase Pradhan Mantri Vaya Vandana Yojana from Life Insurance Corporation of India both offline and online. </p>
                        <p><strong>Via Online</strong></p>
                        <ul>
                            <li>One can go through the following steps for applying PMVVY scheme online</li>
                            <li>Visit the official website of LIC and under ‘Buy Policy Online’ section, click on “Pradhan Mantri Vaya Vandana Yojana”</li>
                            <li>Now you will be redirected to a new page and under the same page click on the designated button no. 842 for the ‘Buy Online’ option. </li>
                            <li>Now again you will be redirected to a new page where you have to select the ‘Click to Buy Online’ option.</li>
                            <li>Now you have to generate an ‘Access ID’ to proceed further</li>
                            <li>Now you need to enter your name, email ID, mobile number, date of birth, address and servicing unit to generate the ID. Once your submitted details are verified, you will get 9 digit Access ID on your registered mobile number or email.</li>
                            <li>Now enter the Access ID on the required field and click on ‘Proceed’ to proceed further with your application</li>
                            <li>Now select your desired pension plan under Pradhan Mantri Vaya Vandana Yojana, and fill up the application form with required details correctly.</li>
                            <li>Now upload your scanned KYC documents and make payment. </li>
                            <li>After successful submission you will get an acknowledgement and policy number. </li>
                        </ul>

                        <h2>Premature withdrawal</h2>
                        <p>A Pradhan Mantri Vaya Vandana Yojana (PMVVY) account holder is also eligible for premature exit in case of serious health issues. n such a scenario, 98 per cent of the invested capital would be refunded to the policy buyer. The remaining 2 per cent will however be charged as a penalty for the premature exit. 100 per cent of the purchase price will be reimbursed to the nominee, in case the primary policy purchaser commits suicide. </p>

                        <h2>Should Senior Citizens Consider PMVVY For Retirement Benefits?</h2>
                        <p>There's now a new chance to invest in the Pradhan Mantri Vaya Vandana Yojana (PMVVY) for senior citizens looking for regular income, security and fair returns. However there are a few modifications in the system. The interest rate has now dropped to 7.4 per cent, down from the previous year's 8 per cent. And presently there is a clause to adjust the interest rate payable each year. Considering the stability of the sovereign and the competitive interest rate – which is better than that provided by small-savings schemes and bank FDs, however, PMVVY will be your perfect investment option. </p>

                        <h2>New rules for the subscribers of PMVVY</h2>
                        <p>Newly modified, Pradhan Mantri Vaya Vandana Yojana (PMVVY) is accessible to senior citizens. The Life Insurance Corporation ( LIC) has emerged out with specifics of the Pradhan Mantri Vaya Vandana Yojana (Modified 2020) as the primary entity authorized to accumulate funds in the scheme. PMVVY, which had originally closed on 31 March 2020, was subsequently renewed by the government until March 2023 for a subsequent 3 fiscal years. In order to invest, one should contact any LIC agent or purchase it directly from the official site of the insurer. PMVVY 's extension will come as a consolation to senior citizens. Reduced pension rates are the main improvement of the latest updated form.</p>
                        <p>The Updated PMVVY will bear a lower interest rate than earlier on the investment. Unlike in the older edition of PMVVY, the interest rate in the Updated PMVVY will tend to differ based on the fiscal year (FY) in which the funds are invested. The plan is for 10 years and on deposits made in the FY 20-21 until March 31, 2021, the government has decided the interest rate payable on a monthly basis of 7.4 per cent, i.e. 7.66 per cent per annum for the complete term of 10 years. The Government will announce the PMVVY interest rate at the beginning of each FY for investments made in the next two FY, i.e. 2021-22 and 2022-23. </p>
                    </Col>
                </Row>
            </Container>
        </div>
        </div>
    )
}

export default PMVaya