import React, { useState } from 'react'
import { Card, Col, Row, Form, Button, Breadcrumb } from 'react-bootstrap'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Layout from '../../layout/Layout'
import {toast} from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { API } from '../../App'
import Cookies from 'js-cookie'
const HolidayAdd = () => {
  const tokenAuth = async () => {
    const token = Cookies.get('jwt')
    const response = await API.post("/home", {}, { headers: { "Authorization": `Bearer ${token}` } })
    if (response.data.status === false) {
      navigate("/")
    }
  }
  const [validate,setValidate] = useState(false)
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const getData = async () => {
    const token = Cookies.get('jwt')
    const response = await API.post("/getState", {}, { headers: { "Authorization": `Bearer ${token}` } })
    if (response.data.status === false) {
      if (response.data.response_code === 429) {
        navigate("/")
      } else {
        toast.error('Something went wrong in State List')
      }
    } else {
      setData(response.data.data.data)
    }
  }

  const [status, setStatus] = useState(1)
  const [inpData, setInpData] = useState({
    State: "",
    Name: "",
    Day: "",
    Date: ""
  })

  const getValue = (e) => {
    setInpData({ ...inpData, [e.target.name]: e.target.value })
  }

  const saveData = async () => {
    if (inpData.State === "" || inpData.Name === "" || inpData.Day === "" || inpData.Date === "") {
      if (inpData.State === "") {
        setValidate(true)
      } else if (inpData.Name === "") {
        setValidate(true)
      } else if (inpData.Date === "") {
        setValidate(true)
      } else if(inpData.Day){
        setValidate(true)
      }
    } else {
      if(inpData.Name.match(/^[A-Za-z& ]+$/) && inpData.Day.match(/^[A-Za-z& ]+$/) ){
        const Form = new FormData()
        Form.append('stateId', inpData.State)
        Form.append('name', inpData.Name)
        Form.append('date', inpData.Date)
        Form.append('day', inpData.Day)
        Form.append('status', status)
        const token = Cookies.get('jwt')
        const response = await API.post("/addHoliday", Form, { headers: { "Authorization": `Bearer ${token}` } })
        if (response.data.status === true) {
          toast.success('Holiday Added Succesfully')
          navigate("/holiday")
        } else {
          if (response.data.response_code === 429) {
            navigate("/")
          } else {
            toast.error('Something went wrong')
          }
        }
      }else{
        if(!inpData.Name.match(/^[A-Za-z& ]+$/)){
          toast.warning('Please enter valid Reason')
        }else{
          toast.warning('Please enter valid Day')
        }
      }
    }
  }

  useEffect(() => {
    tokenAuth()
    getData()
  }, [])

  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3>Add Holiday</h3>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/holiday">Holiday List</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Add New Holiday</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="page-content">
      <Form noValidate validated={validate}>
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Row className='align-items-center'>
                  <Col lg={4}>
                    <Form.Group>
                      <Form.Label htmlFor='holiday-sid'>State Name</Form.Label>
                      <Form.Select id="holiday-sid" className="my-2" val name='State' onChange={(e) => { getValue(e) }} required>
                        <option value="">Select State Name</option>
                        {
                          data.map((dataVal, ind) => {
                            return (
                              <option value={dataVal._id} key={ind}>{dataVal.name}</option>
                            )
                          })
                        }
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={4} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='holiday-name'>Name</Form.Label>
                      <Form.Control type="text" className="my-2" id='holiday-name' value={inpData.Name} name='Name' onChange={(e) => { getValue(e) }} placeholder="Enter Your Name" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={4} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='holiday-date'>Date</Form.Label>
                      <Form.Control type="text" className="my-2" id='holiday-date' value={inpData.Date} name='Date' onChange={(e) => { getValue(e) }} placeholder="Enter Your Date" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={4} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='holiday-day'>Day</Form.Label>
                      <Form.Control type="text" className="my-2" id='holiday-day' value={inpData.Day} name='Day' onChange={(e) => { getValue(e) }} placeholder="Enter Your Day" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={4} >
                    <Form.Group>
                      <Form.Label className="d-block mb-2" htmlFor='holiday-status'>Status </Form.Label>
                      <BootstrapSwitchButton id="holiday-status" checked={true} onChange={(checked) => { if (checked === true) { setStatus(1) } else { setStatus(0) } }} onlabel='Enable' offlabel='Disable' width={125} />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-end">
                <Button variant="primary" className="me-3" onClick={saveData}>Save</Button>
                <Link to="/holiday">
                  <Button variant="secondary">Cancle</Button>
                </Link>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Form>
      </div>
    </Layout>
  )
}

export default HolidayAdd