import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Hcp_webview from "./Hcp_webview";
import { Button, Card, Form, InputGroup } from "react-bootstrap";
import { API } from "../../App";
import $ from "jquery";

const HomeLoan = () => {
  document.title = "Home Loam";
  document.querySelector('meta[name="description"]').setAttribute('content', "Home Loan");
  const [data, setData] = useState([]);
  const [sData,setSData] = useState([])
  const getData = async () => {
    const response = await API.post("/loan/table/data", { loan_type: "Home" });
    setData(response.data.data.table);
    setSData(response.data.data.table);
  };

  useEffect(() => {
    getData();
  }, []);

  const [button, setButton] = useState("Read More");
  const btnFunc = () => {
    if (button === "Read More") {
      setButton("Read Less");
      document.getElementById("type1").style.display = "block";
    } else {
      setButton("Read More");
      document.getElementById("type1").style.display = "none";
    }
  };

  // const requestSearch = (searchedVal) => {
  //   const filteredRows = data.filter((row) => {
  //     return row.bankName
  //       .toString()
  //       .toLowerCase()
  //       .includes(searchedVal.toString().toLowerCase());
  //   });
  //   if (searchedVal.length < 1) {
  //     $("#Data_not_found").css({ display: "none" });
  //     getData();
  //   } else {
  //     if (filteredRows.length === 0) {
  //       $("#Data_not_found").css({ display: "block" });
  //       setData(filteredRows);
  //     } else {
  //       setData(filteredRows);
  //     }
  //   }
  // };

  const requestSearch = (searchedVal) => {
    const filteredRows = data.filter((row) => {
        return row.bankName.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());

    });
    if (searchedVal.length < 1) {
        $('#Data_not_found').css({ display: "none" })
        getData()
    }
    else {
        if (filteredRows.length === 0) {
            const filteredRows1 = sData.filter((row) => {
                return row.bankName.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
            });
            if (filteredRows1.length === 0) {
                setData(filteredRows)
            }
            else {
                $('#remove_tr').empty()
                setData(filteredRows1)
            }
            // $('#Data_not_found').css({display:"block"})
            // setData(filteredRows)
            // setDataLength(0)
        }
        else {
            const filteredRows1 = sData.filter((row) => {
                return row.bankName.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
            });
            if (filteredRows1.length === 0) {
                $('#remove_tr').empty()
                $('#remove_tr').append('<td colSpan="100%" class="p-0"><div class="no-found">No Records Found</div></td>')
                setData(filteredRows)

            }
            else {
                $('#remove_tr').empty()
                setData(filteredRows1)
                // setCurrent(1)
            }
            // setData(filteredRows)
            // setCurrent(1)
        }
    }
};

  return (
    <div className="balance-webview">
      <Container>
        {/* ================= Search Bar ================= */}
        <Row>
          <Col xs={12}>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Search"
                aria-label="Search"
                onChange={(e) => requestSearch(e.target.value)}
                aria-describedby="basic-addon1"
              />
              <InputGroup.Text id="basic-addon1">
                <i className="bx bx-search"></i>
              </InputGroup.Text>
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {/* ================= Description Bar ================= */}
            <h2>Loan Details</h2>
            <p>
              <p>
                Every individual in today’s generation dreams to own a house of
                their own but the challenge is to garner the required funds to
                buy one. To help individuals realize their dream of owing a
                house, most of the banks in India including public sector,
                private and commercial banks are availing home loans. An
                individual has to fulfill certain criteria to become eligible to
                secure home loans. Let’s understand each and every aspects
                associated with home loans to ease the process.
              </p>
            </p>
          </Col>
          {/* ================= Read More ================= */}
          <Col xs={12}>
            <Button
              className="w-100 btn-read-more"
              type="primary"
              onClick={btnFunc}
            >
              {button}
            </Button>
          </Col>
          {/* ================= About ================= */}
          <Col xs={12} id="type1" style={{ display: "none" }}>
              <h2>What is a Home Loan?</h2>
              <p>
                The term home loan refers to the loan given by a bank or a
                financial institution either for the purchase of the primary
                house or for investing in a residence.{" "}
              </p>
              <p>
                {" "}
                In case of a home loan, the borrower of the loan who also
                happens to be the owner transfers the title to the lender on a
                condition that the title will be transferred back to the owner
                on completion of the payment of the principal amount along with
                the interest amount.
              </p>
              <p>
                It is mandatory for the borrower to have a collateral security
                to obtain a home loan. With respect to interest rates, home
                loans has two types of interest rates namely fixed interest
                rates and floating interest rates.
              </p>
              <h2>Features of Home Loans</h2>
              <p>
                <strong>Loan Tenure</strong> – The tenure availed to repay the
                loan plays a crucial role as the short terms loans helps the
                borrower to pay less interest amount but has to end up paying
                high principal amount.
              </p>
              <p>
                <strong>Interest Rate –</strong> The home loan offers the
                borrower to choose either from the fixed or floating interest
                rates during the tenure of the loan period.
              </p>
              <p>
                Under the floating rate of interest, the interest rates move
                along with the market rates and is subject to RBI’s decision
                related to the national cash rate. Whereas under the fixed
                interest rates, the interest rates remains firm and fixed
                irrespective of market movements. The only backdrop of fixed
                interest rate is that the borrower may end up paying high
                interest rates where as others has a chance to pay lesser
                interest rates based on market interest rates.
              </p>
              <p>
                <strong>Frequency of Loan Repayment</strong> – The period chosen
                by the borrower to repay the loan is an important factor as this
                has to be done after carefully evaluating the financial plan of
                the individual. The maximum tenure within an individual has to
                repay a home loan in India is 30 years.
              </p>
              <p>
                <strong>Purpose of Loan</strong> – The borrower can avail home
                loan from banks or financial institutions either for purchase of
                a new house from builder or a house meant for resale or for
                construction of house or for extension of an existing house.
              </p>
              <p>
                <strong>Collateral</strong> – Housing Loan is a secured loan and
                hence collateral or security is a must to avail the loan.
              </p>
              <p>
                <strong>Processing Fees</strong> – The processing fee is a one
                time charge which a bank or a financial institution levies on
                the borrower at the time of processing of the loan. This fee
                differs from one bank to another and it either depends on
                specific percentage of loan amount or it is a fixed amount of
                money. The processing fee is non – refundable in nature. This
                particular fee has to be borne by the customers even if the loan
                is not sanctioned. Some of the banks have introduced zero
                processing fee home loan for a limited period of time to attract
                more customers.
              </p>
              <p>
                <strong>Loan Amount -&nbsp;</strong>&nbsp;The total amount of
                housing loan prominently depends on the income of the borrower,
                age of the borrower, credit history of the borrower, repayment
                capacity as well as eligibility of the individual.
              </p>
              <p>
                <strong>Equated Monthly Installments (EMI)</strong> – The term
                equated monthly instalments refers to the fixed payment amount
                which the borrower has to make on a monthly basis to the lender
                on a specified date during every month. The EMI is used to pay
                both the principal amount as well as the interest amount over a
                period of time, untill the completion of the loan in full. The
                EMI usually varies on the loan amount as well as its tenure
                which is chosen by the borrower.
              </p>

              <h2>Types of Home Loans in India</h2>
              <p>
                <strong>Home Purchase Loans</strong>
              </p>
              <p>
                These loans are offered to those borrowers who are looking for
                the purchase of either a house or a flat.
              </p>
              <p>
                <strong>Plot Loans</strong>
              </p>
              <p>
                This kind of loan is offered to those who are looking to buy a
                piece of land or plot for construction of a house on it.
              </p>
              <p>
                <strong>New Home Loans</strong>
              </p>
              <p>
                This loan is offered for those individuals who are looking to
                buy a house or flat for the first time.
              </p>
              <p>
                <strong>Housing Loan for Construction</strong>
              </p>
              <p>
                This kind of loan is offered for those who are willing to
                construct a house of their own over an existing piece of land.
              </p>
              <p>
                <strong>Home Loan Top Up</strong>
              </p>
              <p>
                It is a kind of facility which is offered by most of the banks
                and non – banking financial institutions which allows the
                existing customer to borrow a certain amount over and above the
                existing home loan.
              </p>
              <p>
                <strong>Renovation Loan</strong>
              </p>
              <p>
                This is a kind of loan which is offered to those borrowers who
                are planning to renovate or extend their existing house or
                property.
              </p>
              <p>
                <strong>Balance Transfer Home Loan</strong>
              </p>
              <p>
                This kind of loan is available for the individuals who opt to
                transfer their remaining balance amount of home loan from one
                bank to another. This is usually done to avail better interest
                rates (read as lesser interest rates).
              </p>
              <p>
                <strong>Pre – Approved Home Loan</strong>
              </p>
              <p>
                This kind of housing loan is offered to the eligible borrowers
                after taking into consideration the borrowers creditworthiness,
                financial position, income and so on.
              </p>
              <h2>Eligibility for Securing Home Loans</h2>
              <p>
                <strong>For Salaried Employees</strong>
              </p>
              <p>
                <strong>Age</strong>: The applicant should be in the age group
                between 18 years – 70 years. <br />
                <strong>Income</strong>: The applicant should have a minimum
                salary of Rs 1,20,000 per annum. <br />
                <strong>Tenure of Loan</strong>: The tenure of loan can be
                chosen between 5 years – 30 years. <br />
                <strong>Employment Status:</strong> The applicant should be a
                salaried employee and should be working for at least 3 years out
                of which the current employment should account for atleast 1
                year. <br />
                <strong>Credit Rating:</strong> The applicant should have a good
                credit score (CIBIL score) of 750 – 900 points.
              </p>
              <p>
                <strong>For Self Employed Individuals</strong>
              </p>
              <p>
                <strong>Age:</strong> The applicant should be in the age group
                between 21 years – 70 years. <br />
                <strong>Income:</strong> The applicant should have a minimum
                salary of Rs 2,00,000 per annum. <br />
                <strong>Tenure of Loan:</strong> The tenure of loan can be
                chosen between 5 years – 30 years. <br />
                <strong>Credit Rating:</strong> The applicant should have a good
                credit score (CIBIL score) of 750 – 900 points.
              </p>
              <h2>Required Documents for Home Loans</h2>
              <p>
                <strong>In case of Salaried Employees</strong>
              </p>
              <p>
                • <strong>Proof of Identity</strong>: Election ID Card, Driving
                License, PAN Card, Passport <br /> • Salary Slip for the last 3
                months <br /> • <strong>Proof of Residence</strong>: Utility
                Bills (Electricity bill, Water bill), Passport <br /> • Bank
                statement of Salary Account for the latest 3 months <br /> •
                Duly filled in application form along with recent passport size
                photograph <br /> • Bank Salary Credit Statement for last 6
                months{" "}
              </p>
              <p>
                <br />
                <strong>In case of Self Employed</strong>
              </p>
              <p>
                • Duly filled in application form along with recent passport
                size photograph <br /> • <strong>Proof of Residence</strong>:
                Utility Bills including Electricity bill, Water bill or Passport{" "}
                <br /> • <strong>Proof of Identity</strong>: Election Identity
                Card, PAN Card, Driving License, Passport <br /> • Proof of
                Office Address <br /> • Education Qualification Certificate{" "}
                <br /> • Income Proof of the self-employed: Audited financial
                report for the last three years, last six months bank statements
                are required. <br /> • <strong>Proof of Business</strong>:
                Certificate of Practice, GST Registration Certificate,
                Partnership Deed, Shop Act License, MOA &amp; AOA, Qualification
                Certificate.{" "}
              </p>
              <h2>Things to look out for while borrowing a Home Loan</h2>
              <p>
                It is advised for the borrower to do little groundwork before
                borrowing a home loan, as this is one of the most important
                financial decision. One needs to carefully evaluate all the
                factors before zeroing on the best option while availing a home
                loan.
              </p>
              <p>
                <strong>Know the Interest Rates</strong> – Interest rates play a
                key role in deciding the home loan, a borrower can either opt
                for fixed or floating interest rates in case of a home loan. One
                needs to evaluate carefully before taking a major decision as
                both have their own share of pros and cons.
              </p>
              <p>
                <strong>CIBIL Score</strong> – The CIBIL score or credit score
                plays a major role in deciding the eligibility for a home loan.
                Applicants with good credit score are more likely to get the
                home loan quickly as against those who have a bad credit score.
              </p>
              <p>
                <strong>Read the Documents Carefully</strong> – The loan
                applicant needs to read all the terms and conditions which are
                associated with the loan carefully before duly signing the same
                to avoid discrepancies at a later point of time.
              </p>
              <p>
                <strong>Figure out the Financial Status</strong> – An individual
                who is willing to avail housing loan should first understand
                his/her financial status and then according to should apply for
                the home loan which is well aligned to match the financial
                capacity to ensure that the loan repayment sails smoothly during
                the tenure of the loan. Sometimes, customers commit a mistake by
                availing huge amount of loans which is too expensive for them
                and run into trouble. This, in turn, leads to a delay in
                repayment of a loan which again increases the interest rates and
                fees.
              </p>
              <p>
                <strong>Know about Fees and Charges</strong> – Different banks
                in India charge different amount as loan processing fee and
                other kinds of charges over the entire period of the loan. It is
                essential for an individual (borrower) to understand these
                charges properly before applying for a home loan as this will
                save their hard earned money.
              </p>
              <h2>
                Factors that determine the interest rates of a housing loan
              </h2>
              <p>
                The following factors determine the interest rates of the home
                loan in India
              </p>
              <p>
                <strong>• Prime Lending Rate</strong> – It is the interest rate
                which is charged by the commercial banks on their creditworthy
                customers. It is mainly determined by the federal fund rates.
              </p>
              <p>
                <strong>• Repo Rate –</strong> The term repo rate refers to the
                rate of interest at which the country’s Central Bank – Reserve
                Bank of India will lend money to other banks.
              </p>
              <p>
                <strong>• Reverse Repo Rate –</strong> The term reverse repo
                rate refers to the rate at which the Reserve Bank of India
                borrows money from the commercial banks within the country. It
                is one of the monetary policy instrument which can be used as a
                tool to control money supply in the country.
              </p>
              <p>
                <strong>• Cash Reserve Ratio –</strong> The term cash reserve
                ratio refers to the minimum percentage of the total deposits
                made by the customers which the bank has to hold as a reserve to
                meet the future uncertainties rather than lending it or
                investing it.
              </p>
              <p>
                <strong>• Statutory Liquidity Ratio –</strong> The Statutory
                Liquidity Ratio is the reserve which is required for the
                commercial banks in India to maintain. It can be either in terms
                of gold or cash or RBI approved securities before providing
                credit to the customers.
              </p>
              <p>
                <strong>• Benchmark Prime Lending Rate (BPLR) –</strong> The
                term Benchmark Prime Lending Rate refers to the rate at which
                commercial banks lends money to its customers (in the form of
                loans). Usually, all the commercial banks in India secure loan
                from RBI at a lower rate of interest and hence none of them are
                allowed to lend its customers below the BPLR.
              </p>
              <h2>Reasons for Rejection of home loan</h2>
              <p>
                • Unstable employment of the applicant. <br /> • Age of the
                applicant (borrower). <br /> • The applicant earlier had been a
                defaulter as a tenant. <br /> • The property has not been
                approved by the concerned authorities despite the builder
                securing approval. <br /> • The builder has not secured approval
                from the bank which is offering a home loan. <br /> • Rejections
                related to valuation of the property. <br /> • Low income of the
                applicant. <br /> • Improper documentation. <br /> • If the
                applicant fails to secure no dues certificate from previous
                lenders. <br /> • If the applicant acts as a guarantor for other
                loans, then banks view him/her as equally responsible to repay
                the existing loan and hence may or may not grant a home loan.{" "}
              </p>
            {/* <Col xs={12}>
              <Col xs={12}>
                <Button
                  className="w-100 btn-read-more"
                  type="primary"
                  onClick={btnFunc}
                >
                  {button}
                </Button>
              </Col>/*}
            
            {/* ================= Cards ================= */}
            </Col>
              <Col className="mt-3" xs={12}>
                {data.map((dataVal, ind) => {
                  return (
                    <Card key={ind}>
                      <Hcp_webview
                        key={ind}
                        Bank={dataVal.bankName}
                        Intrest={dataVal.interestRate}
                        Process={dataVal.processingFee}
                        Amount={dataVal.loanAmount}
                        Tenure={dataVal.tenure}
                      />
                    </Card>
                  );
                })}
              </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HomeLoan;
