import React, { useContext, useEffect } from 'react'
import { Button, Card, Form, Table, Col, Row } from 'react-bootstrap'
import Layout from '../../layout/Layout'
import { Link, useNavigate } from 'react-router-dom'
import Pagination from 'rc-pagination'
import Swal from 'sweetalert2'
import { API, AuthContext } from '../../App'
import { useState } from 'react'
import { requestSearch1 } from '../Search'
import Cookies from 'js-cookie'


const State = () => {
    const { versionSearch,setVersionSearch,commonSearch,setCommonSearch} = useContext(AuthContext);
    const [dataLength, setDataLength] = useState(1)
    const tokenAuth = async () => {
        const token = Cookies.get('jwt')
        const response = await API.post("/home", {}, { headers: { "Authorization": `Bearer ${token}` } })
        if (response.data.status === false) {
            navigate("/")
        }
    }
    const requestSearch = (searchedVal,mainData) => {
        // setVersionSearch(searchedVal)
        let srcVal = [...commonSearch]
        srcVal[12].search = searchedVal
        setCommonSearch(srcVal)
        const searchData = requestSearch1(searchedVal,mainData,mainData,"moduleName")
        if(searchData.length===0){
            setData([])
            setDataLength(0)
        }else{
            setData(searchData)
            setDataLength(1)
        }
        setCurrent(1)
    };

    // Paggintion Code // 
    const [size, setSize] = useState(10);
    const [current, setCurrent] = useState(1);

    const wetData = (current, pageSize) => {
        return data.slice((current - 1) * pageSize, current * pageSize);
    };

    const PerPageChange = (value) => {
        console.log(value)
        setSize(value);
        console.log(size)
        const newPerPage = Math.ceil(data.length / value);
        if (current > newPerPage) {
            setCurrent(newPerPage);
        }
    }

    const PaginationChange = (page, pageSize) => {
        setCurrent(page);
        setSize(pageSize)
    }


    const PrevNextArrow = (current, type, originalElement) => {
        if (type === 'prev') {
            return <button className='paggination-btn'>Previous</button>;
        }
        if (type === 'next') {
            return <button className='paggination-btn'>Next</button>;
        }
        return originalElement;
    }

    const [iconcoror, Seticoncoror] = useState("id_up")
    const [oid,setOid] = useState([])
    const [loading,setLoading] = useState(true)
    const navigate = useNavigate()
    const [data, setData] = useState([])
    const [sData, setSData] = useState([])
    const getData = async () => {
        const token = Cookies.get('jwt')
        const response = await API.post("/api/get_version", {}, { headers: { "Authorization": `Bearer ${token}` } })
        if (response.data.status === false) {
            if (response.data.response_code === 429) {
                navigate("/")
            } else {
                setData([])
                setSData([])
                setDataLength(0)
                setLoading(false)
            }
        } else {
            setData(response.data.data.data)
            setSData(response.data.data.data)
            setDataLength(1)
            setLoading(false)
            if(commonSearch[12].search!==""){
                requestSearch(commonSearch[12].search,response.data.data.data)
              }
        }
    }

    const deleteUser = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger me-2'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                const token = Cookies.get('jwt')
                const delCity = await API.post(`/delete/version/${id}`, {}, { headers: { "Authorization": `Bearer ${token}` } })
                if (delCity.data.status === false) {
                    if (delCity.data.response_code === 429) {
                        navigate("/")
                    } else {
                        swalWithBootstrapButtons.fire(
                            'Cancelled',
                            `${delCity.data.response_message}`,
                            'error'
                        )
                    }
                } else {
                    getData()
                    setCurrent(1)
                }

            }
        })
    }

    const sorting = (col, type = "string", order, e) => {
        Seticoncoror(e.target.id)
        if (order === "ASC") {
          const sorted = [...data].sort((a, b) =>
            a[col] > b[col] ? 1 : -1
          );
          if (iconcoror !== e.target.id) {
            setData(sorted)
          }
        }
        if (order === "DSC") {
          const sorted = [...data].sort((a, b) =>
            a[col] < b[col] ? 1 : -1
          );
          if (iconcoror !== e.target.id) {
            setData(sorted)
          }
        }
    }

    //ClearSearchFunction
    const clearSearchFunc = (searchEle)=>{
        let src = [...commonSearch]
        src.map((val)=>{
            if(val.name!==searchEle){
                if(val.name==="City"){
                    val.search={
                        state:"",
                        city:""
                      }
                }else if(val.name==="Fuel"){
                    val.search={
                        state:"",
                        city:"",
                        date:""
                      }
                }else if(val.name==="Branch"){
                    val.search={
                        bank:"",
                        state:"",
                        city:""
                      }
                }else{
                    val.search=""
                }
            }
            return val
        })
        setCommonSearch(src)
    }

    useEffect(() => {
        tokenAuth()
        getData()
        clearSearchFunc("Version")
    }, [])
    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3 className="my-1">Version</h3>
                <Row className='align-items-center'>
                    <Col md="auto" xs={6}>
                        <Form.Select onChange={e => { setSize(Number(e.target.value)); setCurrent(1) }} className="my-1">
                        {
                            [10, 25, 50, 100].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                            ))
                        }
                        </Form.Select>
                    </Col>
                    <Col md="auto">
                        <Form.Control type="search" placeholder="Search By Module" value={commonSearch[12].search} onChange={(e) => requestSearch(e.target.value,sData)} className="form-control my-1" />
                    </Col>
                    <Col md="auto">
                        <Link className="btn btn-primary my-1" to="/version/add">Add New</Link>
                    </Col>
                </Row>
            </div>
            <div className="page-content">
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Body>
                                <Table bordered responsive>
                                    {
                                        loading ? <div className='loader table-loader' ></div>:<></>
                                    }
                                    <thead>
                                        <tr>
                                            <th width='5%'>
                                            <div className='table-sort-filter justify-content-center'>
                                                No
                                                {/* <span className='table-sort'>
                                                    <div className={`sort-down ${iconcoror === "id_down" ? "active" : ""}`} id="id_down" onClick={(e) => { sorting('_id', "", "DSC", e) }} ></div>
                                                    <div className={`sort-up ${iconcoror === "id_up" ? "active" : ""}`} id="id_up" onClick={(e) => { sorting('_id', "", "ASC", e) }} ></div>
                                                </span> */}
                                            </div>    
                                            </th>
                                            <th width='30%'>
                                            <div className='table-sort-filter'>
                                                Module
                                                <span className='table-sort'>
                                                    <div className={`sort-down ${iconcoror === "module_down" ? "active" : ""}`} id="module_down" onClick={(e) => { sorting('moduleName', "", "DSC", e) }} ></div>
                                                    <div className={`sort-up ${iconcoror === "module_up" ? "active" : ""}`} id="module_up" onClick={(e) => { sorting('moduleName', "", "ASC", e) }} ></div>
                                                </span>
                                            </div>    
                                            </th>
                                            <th width='40%'>
                                            <div className='table-sort-filter'>
                                                API
                                                <span className='table-sort'>
                                                    <div className={`sort-down ${iconcoror === "api_down" ? "active" : ""}`} id="api_down" onClick={(e) => { sorting('apiName', "", "DSC", e) }} ></div>
                                                    <div className={`sort-up ${iconcoror === "api_up" ? "active" : ""}`} id="api_up" onClick={(e) => { sorting('apiName', "", "ASC", e) }} ></div>
                                                </span>
                                            </div>    
                                            </th>
                                            <th width='15%'>
                                            <div className='table-sort-filter'>
                                                Version
                                                <span className='table-sort'>
                                                    <div className={`sort-down ${iconcoror === "version_down" ? "active" : ""}`} id="version_down" onClick={(e) => { sorting('version', "", "DSC", e) }} ></div>
                                                    <div className={`sort-up ${iconcoror === "version_up" ? "active" : ""}`} id="version_up" onClick={(e) => { sorting('version', "", "ASC", e) }} ></div>
                                                </span>
                                            </div>    
                                            </th>
                                            <th width='10%' className='text-center'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            wetData(current, size).map((dataVal, ind) => {
                                                return (
                                                    <tr key={ind}>
                                                        <td className='text-center'>{(current === 1) ? ind + 1 : current * size + ind + 1 - size}</td>
                                                        {/* <td className='text-center'>{oid.indexOf(dataVal._id)+1}</td> */}
                                                        <td>{dataVal.moduleName}</td>
                                                        <td>{dataVal.apiName}</td>
                                                        <td>{dataVal.version}</td>
                                                        <td className='text-center'>
                                                            <Link className="btn btn-outline-primary me-2 btn-icon" size="sm" to={`/version/edit/${dataVal._id}`}><i className='bx bx-edit-alt'></i></Link>
                                                            <Button variant="outline-danger" size="sm" className="btn-icon" onClick={() => deleteUser(dataVal._id)}><i className='bx bx-trash-alt' ></i></Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        {
                                            dataLength === 0 ? <tr>
                                                <td colSpan="100%" class="p-0">
                                                    <div className='no-found'>
                                                        <img src="../../not-found/state.svg" alt='Data not found'/>
                                                        <p>No Found State List</p>
                                                    </div>
                                                </td>
                                            </tr> : ""
                                        }
                                    </tbody>
                                </Table>
                                {
                                    data.length > 10 ?
                                        <div className="pagination-custom">
                                            <Pagination showTitle={false}
                                                className="pagination-data"
                                                onChange={PaginationChange}
                                                total={data.length}
                                                current={current}
                                                pageSize={size}
                                                showSizeChanger={false}
                                                itemRender={PrevNextArrow}
                                                onShowSizeChange={PerPageChange}
                                            />
                                        </div> : ""
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Layout>
    )
}

export default State