import React, { useState } from 'react'
import { useEffect } from 'react'
import { Card, Col, Row, Form, Button, Breadcrumb, InputGroup } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import {toast} from 'react-toastify'
import { API } from '../../App'
import Layout from '../../layout/Layout'
import Cookies from 'js-cookie'

const MutualFundDetailAdd = () => {
  const tokenAuth = async () => {
    const token = Cookies.get('jwt')
    const response = await API.post("/home", {}, { headers: { "Authorization": `Bearer ${token}` } })
    if (response.data.status === false) {
      navigate("/")
    }
  }
  const [validate,setValidate] = useState(false)
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const getData = async () => {
    const token = Cookies.get('jwt')
    const response = await API.post("/getMutualFund", {}, { headers: { "Authorization": `Bearer ${token}` } })
    if (response.data.status === false) {
      if (response.data.response_code === 429) {
        navigate("/")
      } else {
        toast.error('Something went wrong in Mutual Fund List')
      }
    } else {
      setData(response.data.data.data)
    }
    // console.log(data);
  }

  const [inpData, setInpData] = useState({
    Category: "",
    Name: "",
    Type: "",
    Cap: "",
    Investment: "",
    Return: "",
    MinReturn: "",
    MaxReturn: "",
  })

  const getValue = (e) => {
    setInpData({ ...inpData, [e.target.name]: e.target.value })
  }

  const [selectedImage, setSelectedImage] = useState();

  const onImags = (event) => {
    console.log(event.target.files[0]);
    setSelectedImage(event.target.files[0]);
  }

  const saveData = async () => {
    if (inpData.Category === "" || inpData.Name === "" || inpData.Type === "" || inpData.Cap === "" || inpData.Investment === "" ||
      inpData.Return === "" || inpData.MinReturn === "" || inpData.MaxReturn === "" || selectedImage === undefined) {
      setValidate(true)
    } else {
      if(inpData.Name.match(/^[A-Za-z& ]+$/) && inpData.Cap.match(/^[A-Za-z& ]+$/)){
        const Form = new FormData()
        Form.append('category', inpData.Category)
        Form.append('name', inpData.Name)
        Form.append('type', inpData.Type)
        Form.append('cap', inpData.Cap)
        Form.append('investment', inpData.Investment)
        Form.append('return', inpData.Return)
        Form.append('minReturn', inpData.MinReturn)
        Form.append('maxReturn', inpData.MaxReturn)
        Form.append('image', selectedImage)
        const token = Cookies.get('jwt')
        const response = await API.post("/addFundDetail", Form, { headers: { "Authorization": `Bearer ${token}` } })
        if (response.data.status === true) {
          toast.success('Mutual Fund Detail Added Succesfully')
          navigate("/mutualfunddetail")
        } else {
          if (response.data.response_code === 429) {
            navigate("/")
          } else {
            toast.error('Something went wrong')
          }
        }
      }else{
        toast.warning('Enter valid Fund or Cap Detail')
      }
    }
  }

  useEffect(() => {
    tokenAuth()
    getData()
  }, [])

  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3>Add Mutual Fund Detail</h3>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/mutualfunddetail">Mutual Fund Detail List</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Add New Mutual Fund Detail</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="page-content">
      <Form noValidate validated={validate}>
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Row className='align-items-center'>
                  <Col lg={4} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='fund-name'>Fund Category</Form.Label>
                      <Form.Select id="fund-name" className="my-2" name='Category' onChange={(e) => { getValue(e) }} required>
                        <option value="">Select Fund Category</option>
                        {
                          data.map((dataVal, ind) => {
                            return (
                              <option value={dataVal._id} key={ind}>{dataVal.name}</option>
                            )
                          })
                        }
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col lg={4} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='fund-cap'>Fund Name</Form.Label>
                      <Form.Control type="text" className="my-2" id='fund-cap' value={inpData.Name} name='Name' onChange={(e) => { getValue(e) }} placeholder="Enter Your Fund Name" required/>
                    </Form.Group>
                  </Col>

                  <Col lg={4} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='bank-image'>Image</Form.Label>
                      <InputGroup className='my-2'>
                        <Form.Control type="file" id='bank-image' onChange={(e) => { onImags(e) }} required/>
                        {selectedImage && (
                          <img src={URL.createObjectURL(selectedImage)} alt="Logo" height={40} />
                        )}
                      </InputGroup>
                    </Form.Group>
                  </Col>

                  <Col lg={4} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='bank-name'>Fund Type</Form.Label>
                      <Form.Select id="bank-name" className="my-2" name='Type' onChange={(e) => { getValue(e) }}required>
                        <option value="">Select Fund Type</option>
                        <option value="Hybrid">Hybrid</option>
                        <option value="Debt">Debt</option>
                        <option value="Equity">Equity</option>
                        <option value="Tax Saver">Tax Saver</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col lg={4} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='fund-cap'>Fund Cap</Form.Label>
                      <Form.Control type="text" className="my-2" id='fund-cap' value={inpData.Cap} name='Cap' onChange={(e) => { getValue(e) }} placeholder="Enter Your Fund Cap" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={4} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='min-investment'>Min Investment</Form.Label>
                      <Form.Control type='text' className="my-2" id='min-investment' value={inpData.Investment} name='Investment' onChange={(e) => { getValue(e) }} placeholder="Enter Your Min Investment" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={4} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='three-return'>Three Return</Form.Label>
                      <Form.Control type='text' className="my-2" id='three-return' value={inpData.Return} name='Return' onChange={(e) => { getValue(e) }} placeholder="Enter Your Three Return" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={4} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='cm-returns'>Cat Mini Returns</Form.Label>
                      <Form.Control type='text' className="my-2" id='cm-returns' value={inpData.MinReturn} name='MinReturn' onChange={(e) => { getValue(e) }} placeholder="Enter Your Cat Mini Returns" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={4} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='cm-returns'>Cat Max Returns</Form.Label>
                      <Form.Control type='text' className="my-2" id='cm-returns' value={inpData.MaxReturn} name='MaxReturn' onChange={(e) => { getValue(e) }} placeholder="Enter Your Cat Max Returns" required/>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-end">
                <Button variant="primary" className="me-3" onClick={saveData}>Save</Button>
                <Link to="/mutualfunddetail">
                  <Button variant="secondary">Cancle</Button>
                </Link>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Form>
      </div>
    </Layout>
  )
}

export default MutualFundDetailAdd