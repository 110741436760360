import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import { Helmet } from 'react-helmet';


const PostOfficeSS = () => {
    document.title = "Post Office Saving Schemes";
    document.querySelector('meta[name="description"]').setAttribute('content', "Post Office Saving Schema");
    return (
        <div>
            {/* <Helmet>
                <title>Post Office Saving Scheme</title>
            </Helmet> */}
            <div className="webview">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <p>Department of Posts Ministry of Communications Government of India which regulates the country 's postal system also generates investors with several deposit avenues, widely known as <strong>post office saving schemes</strong>. </p>
                            <p>Post Office Investments includes a range of saving schemes which include high-interest rates as well as tax advantages and, most significantly, bear Indian Government's sovereign guarantee. All of these schemes are <a title="Section 80c of Income Tax Act" href="https://www.goodreturns.in/section-80c-of-income-tax-act-s1.html" >tax-exempt under Section 80c</a>, i.e. tax exemption is allowed up to Rs 1,50,000. There are some services in the <strong>Post Office Saving Schemes</strong>    that deliver efficiency and risk-free returns on capital. Such schemes are run by 1,54 lakh post offices throughout the country. For instance, the <a title="PPF Scheme" href="https://www.goodreturns.in/public-provident-fund-sschm2.html" >PPF scheme</a>; PPF is managed in addition to the post offices in each city, via 8200 branches of public sector banks. <a title="Post Office Saving Schemes that Offer Better Returns than Bank Deposits!" href="https://www.goodreturns.in/personal-finance/3-post-office-schemes-that-offer-better-returns-than-bank-deposits/articlecontent-pf15221-1161659.html" >Few Post Office Saving Schemes that Offer Better Returns than Bank Deposits!</a></p>
                            <p>Such schemes have been launched to establish avenues for investment and reinforce savings against the investment of Indian individuals. All schemes come under the <strong>Post Office Savings Scheme</strong> are accessible through all the post office of India which also enables the individual to make a faster registration and enrollment. Currently, the government offers general citizens with <a title="9 postal saving schemes for savings" href="https://www.goodreturns.in/personal-finance/investment/2018/01/savings-post-office-schemes-662044.html" >9 postal saving schemes for savings</a>. </p>

                            <h2>Benefits of Post Office Savings Scheme</h2>
                            <p><strong>Post Office Saving Schemes</strong> include some of the basic features and benefits which are listed below:</p>
                            <p><strong>Efficient and secure</strong>: Apart from the applicable circumstances, all post office savings schemes are supported by the government. </p>
                            <p><strong>Guaranteed and attractive return</strong>: In every 3 months the Ministry of Finance changes the interest rates of the post office savings scheme. </p>
                            <p>The applicable interest rates on Post Office Savings Scheme keeps changing.</p>
                            <p><strong>Easy investment process</strong>: Minimum paperwork and easy verification processes provided by the post office make it easier for you to apply to any of the saving schemes.</p>
                            <p><strong>Long term investments schemes:</strong> Many of the post office saving schemes are long term investments with a tenure up to 15 years. Many of the saving schemes for post offices are long-term savings and will last up to 15 years. A long term, like PPF, enables an individual over time to increase substantial assets. Therefore, they can be recognized as valuable financial security plans as well as pension benefits. </p>
                            <p><strong>Tax benefits:</strong> Tax benefit is one of the most recognized aspects of the post office savings scheme. Some schemes like National Saving Certificates provide tax exemptions on the amount of the deposit under Section 80C. Also, few schemes like Kisan Vikas Patra provide tax deductions on the interest earned. </p>
                            <p><strong>Beneficial to the investors of every income criteria:</strong> Post Office Savings Scheme are Postal assets are planned to reach investors through various economic structures and from every corner of the country. Any Indian citizen can take advantage of these schemes with 1,55 lakh branches of post offices, from rural to metropolitan. </p>
                            <p><strong>An array of options to choose:</strong> Indian post-savings schemes re scattered through various forms of savings and investment products to accommodate for specific investors. An array of financial products to choose such as savings deposit, recurring deposit, fixed deposit, monthly scheme, saving certificates, and more.  From these options, investors can select according to their financial targets. </p>

                            <h2>Post Office Savings Schemes</h2>
                            <ul>
                                <li>Post Office Savings Account</li>
                                <li>5-Year Post Office Recurring Deposit Account (RD)</li>
                                <li>Post Office Time Deposit Account (TD)</li>
                                <li>Post Office Monthly Income Scheme Account (MIS)</li>
                                <li>Senior Citizen Savings Scheme (SCSS)</li>
                                <li>15 year Public Provident Fund Account (PPF)</li>
                                <li>National Savings Certificates (NSC)</li>
                                <li>Kisan Vikas Patra (KVP)</li>
                                <li>Sukanya Samriddhi Accounts (SSA)</li>
                            </ul>

                            <h2>Who can Invest in Post Office Savings Scheme? </h2>
                            <p>The above-listed schemes can be opted by investors who choose a portfolio with no-risk investing coupled with a significant return. Saving schemes such as National Savings Certificates, Sukanya Samriddhi Accounts, and PPF provide you higher interest rates without any risks. The minimum investment amount is also affordable, thus investors from the lowest income classes can glance forward to investing in such schemes as well. </p>

                            <h2>Process to Apply for a Post Office Savings Scheme</h2>
                            <p>With the following methods, you can apply for any of the post office saving schemes</p>
                            <ul>
                                <li>Visit your nearest post office branch</li>
                                <li>Get the relevant application form according to your selected scheme. However you can also download these forms online from the official site of the India post.</li>
                                <li>Fill out the form with all the mandated details correctly and submit it along with your KYC proof and photographs, or any other required documents as per your selected scheme.</li>
                                <li>Complete the process by submitting the minimum investment amount as per your selected scheme.</li>
                            </ul>

                            <h2>Types of Post Office Savings Scheme</h2>
                            <p>Currently, the government offers the general citizens with 9 postal saving schemes, which are listed below.</p>

                            <h3>Post Office Savings Account</h3>
                            <ul>
                                <li>By depositing a minimum of Rs.20, you can also open a savings account with the post office which is close to savings accounts opened with banks.</li>
                                <li>You can only open one account with one post office and the account is transferable too across India.</li>
                                <li>You can open an account under a minor 's name, too. The rate of interest is 4 percent, which is completely taxable and TDS is deducted on the same.</li>
                                <li>Minimum balance is required to be maintained under the non-cheque facility is Rs.50/-</li>
                                <li>Additionally, under Section 80TTA of the Income Tax Act,1961, a deduction of Rs 10,000 per annum is applicable on the total savings account interest including post office savings interest. </li>
                            </ul>

                            <h3>Post Office Monthly Income Scheme</h3>
                            <p>This scheme is another successful saving method that falls under the Post Office Savings Scheme.</p>
                            <ul>
                                <li>This scheme offers guaranteed income on the capital amount invested by the investor.</li>
                                <li>Any Indian resident can open a single or joint MIS account. A minor is also eligible to invest in this scheme. And even the minor can access the account if he/she is more than 10 years old.</li>
                                <li>One can start the investment with a minimum amount of Rs 1500 up to a maximum of Rs.4.5 lakhs in a single account and Rs.9 lakhs for joint accounts.</li>
                                <li>This scheme enables investors to generate a steady monthly income under a maturity period of 5 years. The interest rate is higher as compared to other fixed-income investments such as FD. </li>
                                <li>One can maintain multiple accounts with a maximum investment of Rs.4.5 lakhs by merging the invested sum in all the accounts.</li>
                                <li>The scheme also provides liquidity which means that the investors can withdraw the deposit amount after 1 year. But a penalty will be charged of 2% if the investor withdraws the amount between 1 year to 3 years, similarly 1% penalty will be charged
                                    on withdrawals after 3 years.</li>
                                <li>You can also transfer your account from one place to another within India. </li>
                            </ul>

                            <h3>Post Office Recurring Deposit</h3>
                            <ul>
                                <li>One can open a 5-year RD account with the post office to invest small fixed amounts of money at regular intervals. One can open number of accounts as there are no limitations.</li>
                                <li>For every deposit Rs 5 there is a default charge of Rs 0.05.</li>
                                <li>The account will be discontinued after 4 regular defaults, but can be resurrected under two months.</li>
                                <li>Once the tenure is complete, account can still be extended on a year-to-year basis for 5 additional years.</li>
                                <li>Post Account RD enables a small investor by encouraging them to invest from a minimum of Rs.10 per month and any amount in multiples of Rs.5. </li>
                                <li>Joint accounts, multiple accounts and account for minor can also be opened</li>
                                <li>RD account is transferable from one post office to another</li>
                                <li>RD account also allows a partial withdrawal up to 50% of the balance after a year.</li>
                                <li>Post office RD has no TDS on interest. </li>
                                <li>For any investor searching for a risk-free investing path, it's one of the better investment options to regularly save some amount on a monthly basis. </li>' +
                            </ul>

                            <h3>Post Office Time Deposit</h3>
                            <p>As a post office saving scheme you can also access time deposits for 1, 2, 3 and 5 years of tenure. Only minors above the age of 10 can invest with a parent in the time deposits. The investing method is equivalent to fixed deposits. The salient features of this scheme are:</p>
                            <ul>
                                <li>A minimum of Rs 200 can be invested without any upper limit</li>
                                <li>One can hold a number of accounts under the scheme</li>
                                <li>Accounts in a single holding or joint holding method can be opened. Even an investment is authorized in the minor 's name.</li>
                                <li>Accounts can be relocated from one post office branch to another across the country</li>
                                <li>Upon the maturity time deposit is expired, it will automatically renew again with the existing interest rate on maturity day for the same term.</li>
                                <li>The investment made in the 5-year post office time deposit has a tax benefit under Section 80C of The Income Tax Act, 1961. </li>
                            </ul>

                            <h3>Kisan Vikas Patra</h3>
                            <p>KVP certificates enable you to receive double the amount of your deposit within 9 years and 10 months. Kisan Vikas Patra scheme is a few of those saving ways that will allow individuals to accumulate capital over time without perpetrating concern of any associated uncertainty. </p>
                            <ul>
                                <li>The investment is accessible in Rs.1,000, Rs. 5,000, Rs.10,000 and Rs. 50,000 denominations and comes with a minimum investment limit of Rs 1000 with no upper limit.</li>
                                <li>Certificates can be transferred easily and can be approved to the third person.</li>
                                <li>Certificate in existence is comparatively liquid because it provides withdrawal facility after 2.5 years of investment tenure.</li>
                                <li>This scheme is valuable for small investors who do not have attention to other financial products.</li>
                            </ul>

                            <h3>Senior Citizen’s Savings Scheme</h3>
                            <ul>
                                <li>For SCSS the minimum eligibility age is 60 years. One can also invest in this scheme after his/her voluntary retirement after 55 years of age. The amount invested in those situations should not surpass the retirement value of the corpus earned. </li>
                                <li>The minimum investment amount is Rs 1000 and the maximum investment cap allowed per individual is Rs.15 lakh. </li>
                                <li>A person can hold several accounts with his spouse under his or her name or in joint holding.</li>
                                <li>The interest rate on the SCSS is updated once every 3 months and is therefore subject to change quarterly every year. The interest rate on the SCSS is updated once every 3 months and is therefore subject to change four times a year. The Monetary PlanningCommittee of the Reserve Bank of India operates at periodic intervals, and the relevant interest rates are determined on the basis of prevailing economic status.</li>
                                <li>Premature withdrawal is also allowed in the scheme after a year with a penalty of 1.5%. After 2 years of deposit, 1 per cent penalty is levied.</li>
                                <li>After the scheme matures, an account can be extended for another three years.</li>
                                <li>Tax deduction under Section 80C of The Income Tax Act is available for the principal amount invested by an investor up to the limit of Rs. 1.5 Lakh. However, the tax will be deducted in case the amount of interest exceeds Rs.10,000 per annum.</li>
                            </ul>

                            <h3>Public Provident Fund</h3>
                            <ul>
                                <li>PPF is a long-term fund offered at an interest rate of 7.9 per cent per annum (compounded annually) over a duration of 15 years.</li>
                                <li>No minimum or maximum age is required to open a PPF account</li>
                                <li>One can invest with a minimum amount of Rs. 500 and a maximum of Rs. 1.5 lakhs per fiscal year. Investments can be rendered in 12 equal installments or in a lump sum.</li>
                                <li>You can open the account in a single holding type only.</li>
                                <li>You can also invest in the name of a minor by merging balances in all your accounts without exceeding the overall contribution cap.</li>
                                <li>The maturity duration can also be expanded to another 5 years after completion of the tenure of 15 years. </li>
                                <li>PPF is a mere long-term savings strategy with premature closure facility only approved after five years of account opening and only for severe conditions. Partial withdrawal is only allowed after the completion of 5 years from the date of the account opening. </li>
                                <li>Investors can take benefit of the loan facility from the third fiscal year</li>
                                <li>Investment in PPF account by an individual is also eligible for tax deduction under Section 80C of The Income Tax Act. The total return earned is completely tax-free. </li>
                            </ul>

                            <h2>National Savings Certificate (NSC)</h2>
                            <ul>
                                <li>The NSC has a 5 year maturity period. The NSC interest rate is compounded half-yearly but payable at maturity at 6.8% per annum</li>
                                <li>You can start investing from a minimum of Rs.1,000 (or multiples of Rs.100) with no upper limit</li>
                                <li>The account can be opened a single adult, on behalf of a minor above 10 years of age</li>
                                <li>Investment in NSC is tax free under Income Tax Act Section 80C. Interest on NSC is therefore considered to be reinvested under Section 80 C and therefore tax free, with the exception of interest on the NSC's final year.</li>
                                <li>Investors are also allowed to avail loans from the bank</li>
                                <li>NSC is transferable too, which means an investor can transfer his / her certificate from person to person across India</li>
                                <li>NSC is a long-term tax efficient saving scheme and is perfect for investors with no risk appetite.</li>
                            </ul>

                            <h2>Sukanya Samriddhi Accounts</h2>
                            <ul>
                                <li>Sukanya Samriddhi is a girl child support scheme launched by the government of India. It presently provides an impressive cumulative annual interest rate of 7.6% per annum.</li>
                                <li>One can make a minimum investment of Rs.250 up to a maximum of Rs.1,50,000/- in a financial year.</li>
                                <li>Pursuant to Section 80 C, investment in the Sukanya Samridhhi account is tax deductible up to Rs 1.5 lakh per year. Even the interest on the Sukanya Samriddhi account is tax-free and the amount of maturity is tax-free.</li>
                                <li>The account can only be opened on behalf of a girl child by parents or legal guardians. The girl's age on the day of account opening should be 10 years or less.</li>' +
                            </ul>

                            <h2>FAQ's</h2>
                            <h4>Q: How to invest in the monthly income scheme of the post office?</h4>
                            <p>Ans: The Post office Monthly Income Plan is a low-risk plan with a stable income. One can invest up to Rs.4.5 lakh per month and earn 7.7% interest per year. To invest in a post office system, each individual must have an MIS account. Any resident can open the MIS account individually or jointly. The minimum investment required for this program is Rs.1500.</p>
                            <h4>Q: Are investments in post office safe and tax-free?</h4>
                            <p>Ans: Yes, it is safe because the investments under the post carry the sovereign guarantee of the Indian government. All of these schemes are tax-exempt up to a certain limit and some schemes like PPF, Sukanya Samridhi Yojna also have tax benefits on returns.</p>
                            <h4>Q: Is there any investment scheme for girl child in Post Office?</h4>
                            <p>Ans: All plans except the Seniors Savings Program can be used by students over the age of 18. Sukanya Samriddhi Yojna (SSY) is a program for girl child in which parents have to deposit a fixed standard of a minimum or higher amount that matures and is given to the girl when she turns 21.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default PostOfficeSS