import React, { useEffect, useState } from 'react'
import { Breadcrumb, Card, Col, Row, Form, Button } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { API } from '../../App'
import Layout from '../../layout/Layout'
import Cookies from 'js-cookie'

const FuelAdd = () => {
    const tokenAuth = async () => {
        const token = Cookies.get('jwt')
        const response = await API.post("/home", {}, { headers: { "Authorization": `Bearer ${token}` } })
        if (response.data.status === false) {
            navigate("/")
        }
    }
    const [date,setDate] = useState({
        min:"",
        max:""
    })
    const [loading,setLoading] = useState(false)
    const [validate,setValidate] = useState(false)
    const navigate = useNavigate()
    const [state, setState] = useState([])
    const getData = async () => {
        const token = Cookies.get('jwt')
        const response = await API.post("/add/fuelcity/state/list", {}, { headers: { "Authorization": `Bearer ${token}` } })
        if (response.data.status === false) {
            if (response.data.response_code === 429) {
                navigate("/")
            } else {
                toast.error('something went wrong in State List', { appearance: "error" })
            }
        } else {
            setState(response.data.data)
        }
    }

    const [inpData, setInpData] = useState({
        State: "",
        Petrol: "",
        Diesel: "",
        Lpg: "",
        Cng: "",
        Gold22: "",
        Gold24: "",
        Date:""
    })

    const getValue = async (e) => {
        console.log(e.target.name);
        console.log(e.target.value);
        setInpData({ ...inpData, [e.target.name]: e.target.value })
        if (e.target.name === "Date") {
            const dateArr = e.target.value.split("-")
            setInpData({ ...inpData, [e.target.name]:e.target.value===""? e.target.value:dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0] })
        }
    }

    const saveData = async () => {
        if (inpData.State === "" || inpData.Petrol === "" || inpData.Diesel === "" || inpData.Lpg === "" ||
            inpData.Cng === "" ||  inpData.Gold22 === "" || inpData.Gold24 === "" ) {
            setValidate(true)
        }else if(inpData.Date===""){
            toast.warning("Please Select Date")
        } else {
            const Form = new FormData()
            Form.append('stateId', inpData.State)
            Form.append('Petrol', inpData.Petrol)
            Form.append('Diesel', inpData.Diesel)
            Form.append('Lpg', inpData.Lpg)
            Form.append('Cng', inpData.Cng)
            Form.append('gold24', inpData.Gold24)
            Form.append('gold22', inpData.Gold22)
            Form.append("Date",inpData.Date)
            setLoading(true)
            const token = Cookies.get('jwt')
            const response = await API.post("/add/fuel/city", Form, { headers: { "Authorization": `Bearer ${token}` } })
            if (response.data.status === true) {
                setLoading(false)
                toast.success("Fuel Added Succesfully", { appearance: "success" })
                navigate("/fuel")
            } else {
                setLoading(false)
                if (response.data.response_code === 429) {
                    navigate("/")
                } else {
                    toast.error('something went wrong')
                }
            }
        }
    }

    useEffect(() => {
        tokenAuth()
        getData()
        let today = new Date();
        let yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();
        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        const maxDate = yyyy + '-' + mm + '-' + dd;
        today.setDate(today.getDate()-14)
        yyyy = today.getFullYear();
        mm = today.getMonth() + 1; // Months start at 0!
        dd = today.getDate();
        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        const minDate = yyyy + '-' + mm + '-' + dd;
        setDate({min:minDate,max:maxDate})
    }, [])

    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3>Add Fuel</h3>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/fuel">Fuel List</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Add New Fuel</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="page-content">
            <Form noValidate validated={validate}>
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Body>
                                <Row className='align-items-center'>
                                    {
                                        loading ? <div className='loader' ></div>:<></>
                                    }
                                    <Col lg={3} sm={12}>
                                        <Form.Group>
                                            <Form.Label htmlFor='state'>State</Form.Label>
                                            <Form.Select id="state" className="my-2" name="State" onChange={(e) => { getValue(e) }} required>
                                                <option value="">Select State</option>
                                                {
                                                    state.map((dataVal, ind) => {
                                                        return (
                                                            <option value={dataVal._id}>{dataVal.name}</option>
                                                        )
                                                    })
                                                }
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    {/* <Col lg={3} sm={12}>
                                    <Form.Group>
                                        <Form.Label htmlFor='city'>City</Form.Label>
                                        <Form.Select id="city" className="my-2" name="City" onChange={(e) => { getValue(e) }} required>
                                            <option value="" selected={inpData.City===""?true:false}>Select City</option>
                                            {
                                                city.map((dataVal, ind) => {
                                                    return (
                                                        <option value={dataVal._id}>{dataVal.name}</option>
                                                    )
                                                })
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                    </Col> */}
                                    <Col lg={3} sm={12}>
                                        <Form.Group>
                                            <Form.Label htmlFor='today-petrol'>Petrol Price</Form.Label>
                                            <Form.Control type="text" className="my-2" id='today-petrol' value={inpData.Petrol} name="Petrol" onChange={(e) => { getValue(e) }} placeholder="Enter Your Petrol" required/>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={3} sm={12}>
                                        <Form.Group>
                                            <Form.Label htmlFor='today-Cng'>Diesel Price</Form.Label>
                                            <Form.Control type="text" className="my-2" id='today-Cng' value={inpData.Diesel} name="Diesel" onChange={(e) => { getValue(e) }} placeholder="Enter Your Diesel" required/>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={3} sm={12}>
                                        <Form.Group>
                                            <Form.Label htmlFor='today-lpg'>LPG Price</Form.Label>
                                            <Form.Control type="text" className="my-2" id='today-lpg' value={inpData.Lpg} name="Lpg" onChange={(e) => { getValue(e) }} placeholder="Enter Your LPG" required/>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={3} sm={12}>
                                        <Form.Group>
                                            <Form.Label htmlFor='today-cng'>CNG Price</Form.Label>
                                            <Form.Control type="text" className="my-2" id='today-cng' value={inpData.Cng} name="Cng" onChange={(e) => { getValue(e) }} placeholder="Enter Your CNG" required/>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={3} sm={12}>
                                        <Form.Group>
                                            <Form.Label htmlFor='gold-22'>Gold 22</Form.Label>
                                            <Form.Control type="text" className="my-2" id='gold-22' value={inpData.Gold22} name="Gold22" onChange={(e) => { getValue(e) }} placeholder="Enter Your Gold 22" required/>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={3} sm={12}>
                                        <Form.Group>
                                            <Form.Label htmlFor='gold-24'>Gold 24</Form.Label>
                                            <Form.Control type="text" className="my-2" id='gold-24' value={inpData.Gold24} name="Gold24" onChange={(e) => { getValue(e) }} placeholder="Enter Your Gold 24" required/>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={3} sm={12}>
                                        <Form.Group>
                                            <Form.Label htmlFor='gold-24'>Date</Form.Label>
                                            <Form.Control type="date" placeholder="DD-MM-YYYY" min={date.min} max={date.max}  className="form-control my-2" onChange={(e) => { getValue(e) }} name="Date" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer className="text-end">
                                <Button variant="primary" className="me-3" onClick={saveData}>Save</Button>
                                <Link to="/fuel">
                                    <Button variant="secondary">Cancle</Button>
                                </Link>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Form>
            </div>
        </Layout>
    )
}

export default FuelAdd