import React, { useState } from 'react'
import { Breadcrumb, Row, Col, Card, Form, Button, InputGroup } from 'react-bootstrap'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Layout from '../../layout/Layout'
import {toast} from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { API } from '../../App';
import Cookies from 'js-cookie'

const MutualFundAdd = () => {
  const tokenAuth = async () => {
    const token = Cookies.get('jwt')
    const response = await API.post("/home", {}, { headers: { "Authorization": `Bearer ${token}` } })
    if (response.data.status === false) {
      navigate("/")
    }
  }
  const [validate,setValidate] = useState(false)
  const [status, setStatus] = useState({
    status:1,
    show:0
  })
  const navigate = useNavigate()
  const [inpData, setInpData] = useState({
    name: ""
  })

  const [selectedImage, setSelectedImage] = useState();

  const onImags = (event) => {
    console.log(event.target.files[0]);
    setSelectedImage(event.target.files[0]);
  }

  const getValue = (e) => {
    setInpData({ ...inpData, [e.target.name]: e.target.value })
  }

  const saveData = async () => {
    if (inpData.name === "" || selectedImage === undefined) {
      setValidate(true)
    } else {
      // if(inpData.name.match(/^[A-Za-z& ]+$/)){
        const Form = new FormData()
        Form.append('name', inpData.name)
        Form.append('status', JSON.stringify(status))
        Form.append('image', selectedImage)
        const token = Cookies.get('jwt')
        const response = await API.post("/addMutualFund", Form, { headers: { "Authorization": `Bearer ${token}` } })
        if (response.data.status === true) {
          toast.success('Mutual Fund Added Succesfully')
          navigate("/mutualfund")
        } else {
          if (response.data.response_code === 429) {
            navigate("/")
          } else {
            toast.error('Something went wrong')
          }
        }
      // }else{
      //   toast.warning('Please Enter Valid Mutual Fund Name')
      // }
    }
  }

  useEffect(() => {
    tokenAuth()
  }, [])

  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3>Add Mutual Fund</h3>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/mutualfund">Mutual Fund List</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Add New Mutual Fund</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="page-content">
      <Form noValidate validated={validate}>
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Row>
                  <Col lg={6} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='mf-name'>Name</Form.Label>
                      <Form.Control type="text" min={0} className="my-2" id='mf-name' name="name" onChange={(e) => { getValue(e) }} placeholder="Enter Your Name" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={2} sm={12} >
                    <Form.Group>
                      <Form.Label className="d-block mb-2" htmlFor='mf-status'>Status </Form.Label>
                      <BootstrapSwitchButton id="mf-status" checked={status.status === 1 ? true : false} onlabel='Enable' onChange={(checked) => { if (checked === true) { setStatus({...status,['status']:1}) } else { setStatus({...status,['status']:0}) } }} offlabel='Disable' width={125} />
                    </Form.Group>
                  </Col>
                  <Col lg={2} sm={12} >
                    <Form.Group>
                      <Form.Label className="d-block mb-2" htmlFor='mf-status'>Show </Form.Label>
                      <BootstrapSwitchButton id="mf-status" checked={status.show === 1 ? true : false} onlabel='Enable' onChange={(checked) => { if (checked === true) { setStatus({...status,['show']:1}) } else { setStatus({...status,['show']:0}) } }} offlabel='Disable' width={125} />
                    </Form.Group>
                  </Col>
                </Row>
                  <Row>
                    <Col lg={3} sm={12}>
                      <Form.Group >
                        <Form.Label htmlFor='state-name'>Image</Form.Label>
                        <InputGroup className="my-2">
                          <Form.Control type="file" id='state-name' onChange={(e) => { onImags(e) }} placeholder="Enter Your Name" required/>
                          {selectedImage && (
                              <img src={URL.createObjectURL(selectedImage)} alt="Logo" height={40} />
                          )}
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
              </Card.Body>
              <Card.Footer className="text-end">
                <Button variant="primary" className="me-3" onClick={saveData}>Save</Button>
                <Link to="/mutualfund">
                  <Button variant="secondary">Cancle</Button>
                </Link>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Form>
      </div>
    </Layout>
  )
}

export default MutualFundAdd