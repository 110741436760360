import React, { useState } from 'react'
import { Card, Col, Row, Form, Button, Breadcrumb } from 'react-bootstrap'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Layout from '../../layout/Layout'
import {toast} from 'react-toastify'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { API } from '../../App'
import { useEffect } from 'react'
import Cookies from 'js-cookie'
const LoanDetailEdit = () => {
  const tokenAuth = async () => {
    const token = Cookies.get('jwt')
    const response = await API.post("/home", {}, { headers: { "Authorization": `Bearer ${token}` } })
    if (response.data.status === false) {
      navigate("/")
    }
  }
  const [validate,setValidate] = useState(false)
  const navigate = useNavigate()
  const { _id } = useParams()
  const [loan, setLoan] = useState([])
  const [bank, setBank] = useState([])
  const [user, setUser] = useState({
    Loan: "",
    Bank: "",
    Interest: "",
    Process: "",
    Amount: "",
    Tenure: "",
    status: "",
    id: ""
  })
  const getData = async () => {
    const token = Cookies.get('jwt')
    const detailData = await API.post(`/showLoanDetail/${_id}`, {}, { headers: { "Authorization": `Bearer ${token}` } })
    if (detailData.data.status === false) {
      if (detailData.data.response_code === 429) {
        navigate("/")
      } else {
        toast.error('Something went wrong')
      }
    } else {
      setUser({
        Loan: detailData.data.data.loanId._id,
        Bank: detailData.data.data.bankId._id,
        Interest: detailData.data.data.interestRate,
        Process: detailData.data.data.processingFee,
        Amount: detailData.data.data.loanAmount,
        Tenure: detailData.data.data.tenure,
        status: detailData.data.data.status === 1 ? true : false,
        id: detailData.data.data._id
      })
    }
    const loanRes = await API.post("/getLoan", {}, { headers: { "Authorization": `Bearer ${token}` } })
    if (loanRes.data.status === false) {
      if (loanRes.data.response_code === 429) {
        navigate("/")
      } else {
        toast.error('Something went wrong in Loan List')
      }
    } else {
      setLoan(loanRes.data.data.data)
    }
    const bankRes = await API.post("/getLoanBank", {}, { headers: { "Authorization": `Bearer ${token}` } })
    if (bankRes.data.status === false) {
      if (bankRes.data.response_code === 429) {
        navigate("/")
      } else {
        toast.error('Something went wrong in Bank List')
      }
    } else {
      setBank(bankRes.data.data)
    }

  }

  const getVal = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  const change = (e) => {
    setUser({ ...user, ['status']: e })
    console.log(user);
  }

  const saveData = async () => {
    if (user.Loan === "" || user.Bank === "" || user.Interest === "" || user.Process === "" || user.Amount ==="" || user.Tenure === "") {
      setValidate(true)
    } else {
      const Form = new FormData
      Form.append("loan", user.Loan)
      Form.append("bank", user.Bank)
      Form.append("interest", user.Interest)
      Form.append("process", user.Process)
      Form.append("amount", user.Amount)
      Form.append("tenure", user.Tenure)
      Form.append("status", user.status === true ? 1 : 0)
      const token = Cookies.get('jwt')
      const response = await API.post(`/editLoanDetail/${_id}`, Form, { headers: { "Authorization": `Bearer ${token}` } })
      if (response.data.status === true) {
        toast.success('Loan Detail Updated Succesfully')
        navigate("/loandetail")
      } else {
        if (response.data.response_code === 429) {
          navigate("/")
        } else {
          toast.error('Something went wrong')
        }
      }
    }
  }

  useEffect(() => {
    tokenAuth()
    getData()
  }, [])

  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3>Edit Loan Detail</h3>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/loandetail">Loan Detail List</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Edit Loan Detail</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="page-content">
      <Form noValidate validated={validate}>
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Row className='align-items-center'>
                  <Col lg={3} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='loan-name'>Loan Name</Form.Label>
                      <Form.Select id="loan-name" className="my-2" name='Loan' onChange={(e) => { getVal(e) }}required>
                        <option value="">Select Loan Name</option>
                        {
                          loan.map((dataVal, ind) => {
                            return (
                              <option selected={dataVal._id === user.Loan ? true : false} value={dataVal._id} key={ind}>{dataVal.name}</option>
                            )
                          })
                        }
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={3} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='bank-name'>Bank Name</Form.Label>
                      <Form.Select id="bank-name" className="my-2" name='Bank' onChange={(e) => { getVal(e) }}required>
                        <option value="">Select Bank Name</option>
                        {
                          bank.map((dataVal, ind) => {
                            return (
                              <option selected={dataVal._id === user.Bank ? true : false} value={dataVal._id} key={ind}>{dataVal.name}</option>
                            )
                          })
                        }
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={3} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='loan-desc'>Intrest Rate</Form.Label>
                      <Form.Control type="text" className="my-2" id='loan-desc' value={user.Interest} name="Interest" onChange={(e) => { getVal(e) }} placeholder="Enter Your Intrest Rate" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={3} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='loan-pfee'>Process Fee</Form.Label>
                      <Form.Control type='text' className="my-2" id='loan-pfee' value={user.Process} name="Process" onChange={(e) => { getVal(e) }} placeholder="Enter Your Process Fee" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={3} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='loan-amt'>Loan Amount</Form.Label>
                      <Form.Control type='text' className="my-2" id='loan-amt' value={user.Amount} name="Amount" onChange={(e) => { getVal(e) }} placeholder="Enter Your Loan Amount" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={3} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='loan-tenure'>Tenure</Form.Label>
                      <Form.Control type='text' className="my-2" id='loan-tenure' value={user.Tenure} name="Tenure" onChange={(e) => { getVal(e) }} placeholder="Enter Your Tenure" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={3} >
                    <Form.Group>
                      <Form.Label className="d-block mb-2" htmlFor='loan-status'>Status </Form.Label>
                      <BootstrapSwitchButton id="loan-status" checked={user.status} onChange={change} onlabel='Enable' offlabel='Disable' width={125} />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-end">
                <Button variant="primary" className="me-3" onClick={saveData}>Save</Button>
                <Link to="/loandetail">
                  <Button variant="secondary">Cancle</Button>
                </Link>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Form>
      </div>
    </Layout>
  )
}

export default LoanDetailEdit