import React, { useState } from 'react'
import { Breadcrumb, Card, Col, Row, Form, Button, InputGroup } from 'react-bootstrap'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Layout from '../../layout/Layout'
import {toast} from 'react-toastify'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { API } from '../../App'
import Cookies from 'js-cookie'

const LoanEdit = () => {
  const tokenAuth = async () => {
    const token = Cookies.get('jwt')
    const response = await API.post("/home", {}, { headers: { "Authorization": `Bearer ${token}` } })
    if (response.data.status === false) {
      navigate("/")
    }
  }
  const [validate,setValidate] = useState(false)
  const navigate = useNavigate()
  const { _id } = useParams()
  const [user, setUser] = useState({
    Name: "",
    Description: "",
    About: "",
    Status: "",
    Webview:""
  })

  const getData = async () => {
    const token = Cookies.get('jwt')
    const loanData = await API.post(`/showLoan/${_id}`, {}, { headers: { "Authorization": `Bearer ${token}` } })
    console.log(loanData);
    if (loanData.data.status === false) {
      if (loanData.data.response_code === 429) {
        navigate("/")
      } else {
        toast.error('Something went wrong')
      }
    } else {
      setUser({
        Name: loanData.data.data.name,
        Description: loanData.data.data.description,
        About: loanData.data.data.about,
        Status: loanData.data.data.status === 1 ? true : false,
        Webview:loanData.data.data.webview,
        Image: loanData.data.data.image,
        id: loanData.data.data._id
      })
    }
  }

  const getVal = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  const [selectedImage, setSelectedImage] = useState(null);

  const onImags = (event) => {
    console.log(event.target.files[0]);
    setSelectedImage(event.target.files[0]);
  }

  const change = (e) => {
    setUser({ ...user, ['Status']: e })
    console.log(user);
  }

  const saveData = async () => {
    if (user.Name === "" || user.Description === "" || user.About === "" || user.Webview==="") {
      setValidate(true)
    } else {
      // if(user.Name.match(/^[A-Za-z& ]+$/)){
        const Form = new FormData
        Form.append("name", user.Name)
        Form.append("description", user.Description)
        Form.append("about", user.About)
        Form.append("webview", user.Webview)
        Form.append("status", user.Status === true ? 1 : 0)
        Form.append('image', selectedImage === null ? user.Image : selectedImage)
        const token = Cookies.get('jwt')
        const response = await API.post(`/editLoan/${_id}`, Form, { headers: { "Authorization": `Bearer ${token}` } })
        console.log(response);
        if (response.data.status === true) {
          toast.success('Loan Updated Succesfully')
          navigate("/loan")
        } else {
          if (response.data.response_code === 429) {
            navigate("/")
          } else {
            toast.error('Something went wrong')
          }
        }
      // }else{
      //   toast.warning('Please enter valid Loan Name')
      // }
    }
  }

  useEffect(() => {
    tokenAuth()
    getData()
  }, [])

  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3>Edit Loan</h3>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/loan">Loan List</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Edit Loan</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="page-content">
      <Form noValidate validated={validate}>
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Row>
                  <Col lg={6} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='loan-name'>Name</Form.Label>
                      <Form.Control type="text" className="my-2" id='loan-name' name='Name' value={user.Name} onChange={(e) => { getVal(e) }} placeholder="Enter Your Name" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={6} >
                    <Form.Group >
                      <Form.Label className="d-block mb-2" htmlFor='loan-status'>Status </Form.Label>
                      <BootstrapSwitchButton id="loan-status" checked={user.Status} onChange={change} onlabel='Enable' offlabel='Disable' width={125} />
                    </Form.Group>
                  </Col>
                  <Col lg={6} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='loan-desc'>Description</Form.Label>
                      <Form.Control as="textarea" rows={4} className="my-2" id='loan-desc' name='Description' value={user.Description} onChange={(e) => { getVal(e) }} placeholder="Enter Your Description" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={6} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='loan-about'>About</Form.Label>
                      <Form.Control as="textarea" rows={4} className="my-2" id='loan-about' name='About' value={user.About} onChange={(e) => { getVal(e) }} placeholder="Enter Your Day" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={6} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='loan-about'>Webview</Form.Label>
                      <Form.Control as="textarea" rows={4} className="my-2" id='loan-about' name='Webview' value={user.Webview} onChange={(e) => { getVal(e) }} placeholder="Enter Your Day" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={4} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='state-name'>Image</Form.Label>
                      <InputGroup className="my-2">
                        <Form.Control type="file" id='state-name' onChange={(e) => { { onImags(e) } }} placeholder="Enter Your Name" />
                        <img src={user.Image} alt="Logo" height={40} />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-end">
                <Button variant="primary" className="me-3" onClick={saveData} >Save</Button>
                <Link to="/loan">
                  <Button variant="secondary">Cancle</Button>
                </Link>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Form>
      </div>
    </Layout>
  )
}

export default LoanEdit