import React, { useState } from 'react'
import { Button, Form, Card, Breadcrumb, Col, Row } from 'react-bootstrap'
import Layout from '../../layout/Layout'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import {toast} from 'react-toastify'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { API } from '../../App'
import { useEffect } from 'react'
import Cookies from 'js-cookie'
const BankDetailEdit = () => {
  const tokenAuth = async () => {
    const token = Cookies.get('jwt')
    const response = await API.post("/home", {}, { headers: { "Authorization": `Bearer ${token}` } })
    if (response.data.status === false) {
      navigate("/")
    }
  }
  const [validate,setValidate] = useState(false)
  const navigate = useNavigate()
  const { _id } = useParams()
  const [data, setData] = useState([])
  const [user, setUser] = useState({
    Bank: "",
    Tollfree: "",
    Statement: "",
    StateSms: "",
    CustCare: "",
    SMS: "",
    URL: "",
    Status: "",
    id: ""
  })
  const getData = async () => {
    const token = Cookies.get('jwt')
    const bankDetailData = await API.post(`/showBankDetail/${_id}`, {}, { headers: { "Authorization": `Bearer ${token}` } })

    if (bankDetailData.data.status === false) {
      if (bankDetailData.data.response_code === 429) {
        navigate("/")
      } else {
        toast.error('Something went wrong')
      }
    } else {
      setUser({
        Bank: bankDetailData.data.data.bankId,
        Tollfree: bankDetailData.data.data.tollFreeNum,
        Statement: bankDetailData.data.data.miniStatNum,
        StateSms: bankDetailData.data.data.miniStatSms,
        CustCare: bankDetailData.data.data.custCareNum,
        SMS: bankDetailData.data.data.smsInquiry,
        URL: bankDetailData.data.data.netBankingUrl,
        Status: bankDetailData.data.data.status,
        id: bankDetailData.data.data._id
      })
    }

    const response = await API.post("/getActiveBank", {}, { headers: { "Authorization": `Bearer ${token}` } })
    if (response.data.status === false) {
      if (response.data.response_code === 429) {
        navigate("/")
      } else {
        toast.error('Something went wrong in Bank')
      }
    } else {
      setData(response.data.data)
    }
  }

  const getVal = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  const saveData = async () => {
    if (user.Bank === "" || user.Tollfree === "" || user.Statement === "" || user.StateSms === "" ||
      user.CustCare === "" || user.SMS === "" || user.URL === "") {
      if (user.Bank === "") {
        setValidate(true)
      } else if (user.Tollfree === "") {
        setValidate(true)
      } else if (user.Statement === "") {
        setValidate(true)
      } else if (user.StateSms === "") {
        setValidate(true)
      } else if (user.CustCare === "") {
        setValidate(true)
      } else if (user.SMS === "") {
        setValidate(true)
      } else if(user.URL===""){
        setValidate(true)
      }
    } else {
      const Form = new FormData
      Form.append('bank', user.Bank)
      Form.append('tollfree', user.Tollfree)
      Form.append('statement', user.Statement)
      Form.append('stateSms', user.StateSms)
      Form.append('custCare', user.CustCare)
      Form.append('sms', user.SMS)
      Form.append('url', user.URL)
      Form.append('status', user.Status)
      const token = Cookies.get('jwt')
      const response = await API.post(`/editBankDetail/${_id}`, Form, { headers: { "Authorization": `Bearer ${token}` } })
      if (response.data.status === true) {
        toast.success('Bank Detail Updated Succesfully')
        navigate("/bank-detail")
      } else {
        if (response.data.response_code === 429) {
          navigate("/")
        } else {
          toast.error('Something went wrong')
        }
      }
    }
  }

  useEffect(() => {
    tokenAuth()
    getData()
  }, [])

  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3>Edit Bank Details</h3>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/bank-detail">Bank Details List</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Edit Bank Details</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="page-content">
      <Form noValidate validated={validate}>
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Row>
                  <Col lg={4} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='bank-name'>Bank Name</Form.Label>
                      <Form.Select id="bank-name" className="my-2" name='Bank' onChange={(e) => { getVal(e) }} required>
                        <option value="">Select Bank Name</option>
                        {
                          data.map((dataVal, ind) => {
                            return (
                              <option selected={dataVal._id === user.Bank ? true : false} value={dataVal._id} key={ind}>{dataVal.name}</option>
                            )
                          })
                        }
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={4} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='tollfree-no'>Tollfree Number</Form.Label>
                      <Form.Control type="number" className="my-2" id='tollfree-no' value={user.Tollfree} name="Tollfree" onChange={(e) => { getVal(e) }} placeholder="Enter Your Number" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={4} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='ministatement'>Ministatement</Form.Label>
                      <Form.Control type="text" className="my-2" id='ministatement' value={user.Statement} name="Statement" onChange={(e) => { getVal(e) }} placeholder="Enter Your Ministatement" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={4} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='ministatement-sms'>Ministatement SMS</Form.Label>
                      <Form.Control type="text" className="my-2" id='ministatement-sms' value={user.StateSms} name="StateSms" onChange={(e) => { getVal(e) }} placeholder="Enter Your Ministatement SMS" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={4} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='tollfree-no'>Customer Care Number</Form.Label>
                      <Form.Control type="number" className="my-2" id='tollfree-no' value={user.CustCare} name="CustCare" onChange={(e) => { getVal(e) }} placeholder="Enter Your Number" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={4} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='sms-inqry'>SMS Inquiry</Form.Label>
                      <Form.Control type="text" className="my-2" id='sms-inqry' value={user.SMS} name="SMS" onChange={(e) => { getVal(e) }} placeholder="Enter Your SMS Inquiry" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={4} sm={12}>
                    <Form.Group>
                      <Form.Label htmlFor='n-url'>Netbanking URL</Form.Label>
                      <Form.Control type="text" className="my-2" id='n-url' value={user.URL} name="URL" onChange={(e) => { getVal(e) }} placeholder="Enter Your Netbanking URL" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={4} sm={12} >
                    <Form.Group>
                      <Form.Label className="d-block mb-2" htmlFor='bank-status'>Status </Form.Label>
                      <BootstrapSwitchButton id="bank-status" checked={user.Status === 1 ? true : false} onChange={(e) => { if (e === true) { setUser({ ...user, Status: 1 }) } else { setUser({ ...user, Status: 0 }) } }} onlabel='Enable' offlabel='Disable' width={125} />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-end">
                <Button variant="primary" className="me-3" onClick={saveData}>Save</Button>
                <Link to="/bank-detail">
                  <Button variant="secondary">Cancle</Button>
                </Link>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Form>
      </div>
    </Layout>
  )
}

export default BankDetailEdit