import React, { useState } from 'react'
import { Breadcrumb, Row, Col, Card, Form, Button } from 'react-bootstrap'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Layout from '../../layout/Layout'
import {toast} from 'react-toastify'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { API } from '../../App';
import { useEffect } from 'react';
import Cookies from 'js-cookie'

const StateEdit = () => {
  const tokenAuth = async() => {
    const token = Cookies.get('jwt')
    const response = await API.post("/home",{},{headers:{"Authorization" : `Bearer ${token}`}})
    if(response.data.status===false){
        navigate("/")
    }
  }
  const [validate,setValidate] = useState(false)
  const navigate = useNavigate()
  const {_id} = useParams()
  const [user,setUser] = useState({
    name:"",
    status:"",
    id:""
  })

  const getData = async() => {
    const token = Cookies.get('jwt')
    const stateData = await API.post(`/showState/${_id}`,{},{headers:{"Authorization" : `Bearer ${token}`}})
    setUser({
      name:stateData.data.data.name,
      status:stateData.data.data.status===1?true:false,
      id:stateData.data.data._id
    })
  }

  const getVal = (e) => {
    setUser({...user,[e.target.name]:e.target.value})
  }

  const change = (e) => {
      setUser({...user,['status']:e})
  }

  const saveData = async() => {
    if(user.name===""){
      setValidate(true)
    }else{
      if(user.name.match(/^[A-Za-z& ]+$/)){
        const Form = new FormData
        Form.append("name",user.name)
        Form.append("status",user.status===true ? 1 : 0)
        const token = Cookies.get('jwt')
        const response = await API.post(`/editState/${_id}`,Form,{headers:{"Authorization" : `Bearer ${token}`}})
        if(response.data.status===true){
          toast.success('State Updated Succesfully')
          navigate("/state")
        }else{
          if(response.data.response_code===429){
            navigate("/")
          }else{
            toast.error('Something went wrong')
          }
        }
      }else{
        toast.warning('Please enter valid state name')
      }
    }
  }

  useEffect(()=>{
    tokenAuth()
    getData()
  },[])

  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3>Edit State</h3>
        <Breadcrumb>
          <Breadcrumb.Item><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Breadcrumb.Item>
          <Breadcrumb.Item>State List</Breadcrumb.Item>
          <Breadcrumb.Item active>Edit State</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="page-content">
      <Form noValidate validated={validate}>
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Row>
                  <Col sm={4}>
                    <Form.Group>
                      <Form.Label htmlFor='state-name'>Name</Form.Label>
                      <Form.Control type="text" min={0} className="my-2" id='state-name' value={user.name} onChange={(e)=>{getVal(e)}} name="name" placeholder="Enter Your Name" required/>
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group>
                      <Form.Label className="d-block mb-2" htmlFor='state-status'>Status</Form.Label>
                      <BootstrapSwitchButton id="state-status" checked={user.status} onChange={change} onlabel='Enable' offlabel='Disable' width={125} />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-end">
                <Button variant="primary" className="me-3" onClick={saveData}>Save</Button>
                <Link to="/state">
                <Button variant="secondary">Cancle</Button>
                </Link>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Form>
      </div>
    </Layout>
  )
}

export default StateEdit