import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import { Helmet } from 'react-helmet';

const PMJanDhan = () => {
    document.title = "Pradhan Mantri Jan-Dhan Yojana";
    document.querySelector('meta[name="description"]').setAttribute('content', "Pradhan Mantri Jan-Dhan Yojana");
    return (
        <div>
            {/* <Helmet>
                <title>PM Jan Dhan Yojna</title>
            </Helmet> */}
            <div className="webview">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <p><strong>Pradhan Mantri Jan-Dhan Yojana (PMJDY)</strong> "was initially introduced on 28 August 2014 for a span of 4 years (in two phases) under the National Mission for Financial Inclusion. It provides for uniform access to banking services with at least one basic banking account per family, pension, access to credit, insurance and financial literacy. <strong>PMJDY</strong> has provided the three social security programs viz. a platform. <a title="Pradhan Mantri Jeevan Jyoti Bima Yojana" href="https://www.goodreturns.in/personal-finance/insurance/2015/03/pradhan-mantri-jeevan-jyoti-bima-yojana-highlights-the-scheme-340701.html" >Pradhan Mantri Jeevan Jyoti Bima Yojana</a> (PMJJBY), <a title="Pradhan Mantri Suraksha Bima Yojana" href="https://www.goodreturns.in/personal-finance/insurance/2018/09/pradhan-mantri-suraksha-bima-yojana-how-get-rs-2-lakh-insur-766980.html" >Pradhan Mantri Suraksha Bima Yojana</a> (PMSBY), <a title="Pradhan Mantri Mudra Yojana" href="https://www.goodreturns.in/classroom/2018/03/how-apply-pradhan-mantri-mudra-yojana-loan-scheme/articlecontent-pf11657-682167.html" >Pradhan Mantri Mudra Yojana</a> (PMMY), and <a title="Atal Pension Yojana" href="https://www.goodreturns.in/classroom/2018/01/how-apply-atal-pension-yojana-663429.html" >Atal Pension Yojana</a> (APY). Government of india includes a focus on citizens' financial inclusion, as it is essential for reducing poverty. Excluding a significant number of citizens from any exposure to financial services is impeding our country 's progress. This is the scheme that empowers citizens financially so that everyone would enjoy the advantages of growth and prosperity. </p>
                            <p><strong>Pradhan Mantri Jan Dhan Yojana (PMJDY)</strong>, the world's largest financial effectiveness strategy, was revealed on 15 August 2014 by Honourable Prime Minister Shri Narendra Modi from the Red Fort ramparts and initiated by him throughout the country on 28 August 2014. The Prime Minister had characterized the day as a celebration to mark the liberation of the poor from a destructive cycle when launching the Yojana. On the same day, the Prime Minister also emailed an approximate 7.25 lakhbank staff, asking them to help achieve the target of 7.5 crore bank accounts, and liberating them from financial inaccuracy. </p>
                            <p>The <strong>benefits offered under the Pradhan Mantri Jan Dhan Yojana</strong> have also been recognized by Guinness World Records. This has reported that the "Most bank accounts registered in a week as part of the Financial Inclusion Campaign are 18,096,130and have been accomplished by the Department of Financial Services. Contrary to the stated intention of opening bank accounts for 7.5 crore uncovered household members by 26 January 2015, banks have now approved 12.54 crore accounts as of 31 January2015 following a survey of 21.06 crore households with deposits in excess of Rs 10,000 crores. The goal was set after the country undertook a survey of 21.02 crore households. Today, almost 100% coverage has been reached. 60%  of the accounts generated are in rural areas and 40% are in urban cities. The percentage of women account holders is roughly 51%. </p>
                            <p><strong>Pradhan Mantri Jan Dhan Yojana offers</strong> a forum for unified access to banking services for each household with at least one standard banking account, personal finance, and access to resources, insurance, and retirement services. It includes both urban and rural regions and will get a debit card (RuPay card) for those who open an account. The account can be created at zero balance in either bank branch or Business Correspondent (Bank Mitr) network. Each bank account is on the banks' CoreBanking System (CBS). Mobile banking service is even enabled using the USSD network. There is a call centre facility and toll-free number which is accessible nationwide. </p>
                            <h2>Eligibility Required to Open PMJDY Account</h2>
                            <p>To open a PMJDY account, you must meet the below-given eligibility criteria.</p>
                            <ul>
                                <li>Only Indian citizens can open an account under this scheme</li>
                                <li>In order to open an account under the PMJDY, individuals can visit any nearest bank branch or Business Correspondent (Bank Mitr)</li>
                                <li>Minors who are over the age of 10 can open the savings account at a bank branch.</li>
                                <li>The account opening form of the scheme is available in both English and Hindi format, on the PMJDY's official site. </li>' +
                            </ul>
                            <h2>What are the Documents Required to Open PMJDY Account?</h2>
                            <p>You must carry the below listed documents to open PMJDY account under the scheme.</p>
                            <ul>
                                <li>Driving licence</li>
                                <li>Voter ID</li>
                                <li>Passport</li>
                                <li>PAN Card</li>
                                <li>Job card issued by the National Rural Employment Guarantee Act</li>
                                <li>An attested passport size photograph along with a letter from a Gazetted officer must be submitted</li>
                                <li>Any Identity card generated by the state or Central Government, Scheduled Commercial Banks, Regulatory or Statutory.</li>
                            </ul>
                            <h2>Attainment under Pradhan Mantri Jan Dhan Yojana</h2>
                            <ul>
                                <li>32.41 Crore Jan Dhan accounts have been established with deposit balances of more than Rs 81,200 crore.</li>
                                <li>In rural and semi-urban areas 53 percent of Jan Dhan account holders and 59 percent of Jan Dhan accounts are women. More than 83 percent of Jan Dhan active accounts (excluding Assam states, Meghalaya, J&amp;K) are embedded Aadhaar, with approximately24.4 crore RuPay cards issued to those account holders. </li>
                                <li>Banking Correspondents (BCs) were implemented in 1,26 lakh Sub-Service Areas (rural areas) with 1000-1500 residences each. In the month of July 2018, almost 13.16 crore Aadhar Enabled Payment System (AePS) transactions took place via BCs.</li>
                                <li>Around 13.98 active users under Pradhan Mantri Suraksha Bima Yojana (PMSBY) with 19,436 claims, including the amount of Rs. 388.72 crore settled up to date. </li>
                                <li>Similarly, under Pradhan Mantri Jeevan Jyoti Bima Yojana, 5.47 Crore users with 1.10 lakh claims, worth rs. 2206.28 crore settled to date.</li>
                                <li>1.11 crore citizens have registered for Atal Pension Yojana (APY). </li>
                                <li>A PMJDY Implementation system has been developed through which Jan Dhan accounts and mobile banking were linked to Aadhar (JAM). This process not only facilitates savings, credit disbursement, social security, etc., but more crucially, through DBT, it channelizes the direct benefits of various government schemes to the needy people of the country.</li>
                            </ul>
                            <h2>Mission Mode Objectives of Pradhan Mantri Jan Dhan Yojana </h2>
                            <p>PMJDY, to be conducted in Mission Mode, foresees the development of reliable financial services within a reasonable distance for all people. This contains some elements which are listed below. </p>
                            <p><strong>Enabling the universal access of banking facilities:</strong> Filtering each district into a Sub-Service Area (SSA) catering for 1000-1500 dwellings in a way that every dwelling has access to banking facilities within a fair range. </p>
                            <p><strong>Offering essential banking accounts with RuPay debit card and overdraft facilities to every household:</strong> The aim would be to eventually provide all uncovered residences with bank services by developing basic bank accounts by August 2015.RuPay Debit Card will be issued to account holder. Overdraft facility to every basic banking account holder would be provided after a proper evaluation. </p>
                            <p><strong>Financial Literacy Programme:</strong> Financial literacy would be an important aspect of the project to enable beneficiaries to make the most use of the financial services they access. </p>
                            <p><strong>Establishment of Credit Guarantee Fund:</strong> Development of a credit guarantee fund would compensate the defaults in overdraft accounts. </p>
                            <p><strong>Micro-insurance:</strong> To enable both willing and eligible persons with micro-insurance on an on-going basis. </p>
                            <h2>Insurance Cover Under Pradhan Mantri Jan Dhan Yojana</h2>
                            <p>On 15th August, 2014 the honourable Prime Minister unveiled a robust Financial Inclusion Plan addressing the vast number of citizens presently depriving people of basic financial services.  The Pradhan Mantri Jan Dhan Yojana (PMJDY) intends to deliver a basic bank account to every household who don’t have or carry a bank account. The bank account is bundled with a RuPay debit card with a built-in accidental cover of Rs 1 lakh for every family. At the announcement of the PMJDY scheme on 28.08.14 in New Delhi, the Honourable Prime Minister declared a Rs. 30,000/- life cover with the RuPay Card for all those who first register to a bank account during the period from 15th August 2014 to 26th January 2015. This life insurance cover under Pradhan Mantri Jan Dhan Yojana will cover the death of the life assured, for any cause.  The policy aims at ensuring coverage for those households who can not afford direct insurance,, including the urban and rural families who are not covered under any life insurance benefits or scheme. </p>
                            <p><strong>Eligibility required</strong></p>
                            <p>As per the following eligibility criteria one can get life cover of Rs. 30,000/- in case of death due to any cause. </p>
                            <ul>
                                <li>For the first time, the individual opening the bank account, in addition to RuPay Card, during the duration from 15-08-14 to 26-01-15, or any additional duration may be further extended by the Govt of India.</li>
                                <li>The person must usually be the head of the household or an earning member of the family and should be in the 18-59 age group. In case the head of the family is 60 years of age or older, the second earning member of the family will be covered, subject to eligibility, in the above listed age bracket.</li>
                                <li>Individual must have a RuPay Card and Bio – Metric Card registered to a bank account</li>
                                <li>Any bank account including a small account managed by an individual is eligible.</li>
                                <li>The above RuPay Card should be valid and being used for the coverage to be effective. </li>
                                <li>Under the Bima Scheme, only one person in the family will be covered and in the case of the individual with several cards / accounts the coverage will be provided only under one card i.e. one individual per household will earn a single cover of Rs.30,000/-, according to the criteria of eligibility.</li>
                                <li>The insurance cover of Rs 30.000/- under the policy would initially be for a 5-year term. The policy will then be updated, and the terms and conditions of its operation, including the concern of the insured's future premium payment afterwards, will be appropriately determined. </li>' +
                            </ul>
                            <h2>Claim Settlement</h2>
                            <p>The nominee of the account holder is eligible to claim the amount of Rs.30,000/-. The Rs.30000/- compensation balance is owed to the account holder's nominee(s). The risk cover will be given to the individual from the age of 18 (Completed) before he meets the completed age of 60 years. And after the 60 years of age he / she can exit the scheme. The account holder's nominee will be eligible to receive Rs.30,000/-'death compensation in case of the account holder's unfortunate death related to any circumstance.And one can exit the scheme after reaching age 60 years old. </p>
                            <p>The operation of claim settlement will be transferred to the LIC Offices. The further process will be as follows:</p>
                            <ul>
                                <li>Claim papers will be obtained by the appropriate Bank's District Branch / Nodal Office and sent to LIC's Pension &amp; Group Scheme Units for settling claims. </li>
                                <li>The claim will be paid out in the bank account of the nominee</li>
                                <li>The amount of the claim will be transferred to the nominee's bank account by NEFT / APBS.</li>
                            </ul>
                            <h2>Features of the PMJDY scheme</h2>
                            <p>Some of the key benefits of the scheme are mentioned below:</p>
                            <ul>
                                <li>Interest is provided for the contributions made towards the scheme</li>
                                <li>No minimum balance is required to maintain by an individual</li>
                                <li>An overdraft facility is given in case individuals hold the account for 6 months</li>
                                <li>Individuals can earn Rs.1 lakh Cover for Accidental Benefits under the RuPay program.</li>
                                <li>Insurance benefits and access to pensions are offered under the scheme.</li>
                                <li>Direct Benefit Transfer method is given in case individuals become beneficiaries of government schemes.</li>
                                <li>An overdraft facility of Rs.5,000 is issued per household</li>
                                <li>The cover for Personal Accident can only be asserted after an effective non-financial or financial transaction has been produced by the RuPay Cardholder. Under the scheme, transactions made within 90 days of the incident are considered as liable PMJDY transactions. The transaction must therefore be rendered at an E-COM, POS, ATM, Bank Mitra, branch of the bank, etc. </li>
                                <li>Account-holders can also use the Mobile banking service to verify their balance.</li>
                            </ul>
                            <h2>List of banks that offer PMJDY scheme</h2>
                            <p>One can open an account at both public and private sector banks under the scheme. The list of banks which participate in the scheme is mentioned below.</p>
                            <p><strong>Private Sector Banks:</strong></p>
                            <ul>
                                <li>IndusInd Bank Ltd.</li>
                                <li>Federal Bank Ltd.</li>
                                <li>HDFC Bank Ltd.</li>
                                <li>Axis Bank Ltd.</li>
                                <li>ICICI Bank Ltd</li>
                                <li>Dhanalaxmi Bank Ltd.</li>
                                <li>YES Bank Ltd.</li>
                                <li>Kotak Mahindra Bank Ltd.</li>
                                <li>Karnataka Bank Ltd.</li>
                            </ul>
                            <h2>Current Milestone of Pradhan Mantri Jan Dhan Yojana</h2>
                            <p>Under the country's flagship financial inclusion project Pradhan Mantri Jan Dhan Yojana (PMJDY), initiated by the Modi government about six years ago, now has 40 Crore active bank accounts. There are 40,05 crore beneficiaries of this scheme according to the latest statistic and deposits in Jan Dhan bank accounts surpass Rs 1.30 lac crore. Another achievement reached under the world's biggest initiative for financial inclusion, PMJDY: Cumulative accounts opened under the scheme reach 40 Cr mark.The milestone arrives just ahead of the announcement of PMJDY 's 6th anniversary. The scheme was initiated on 28 August 2014 with the goal of providing the citizens of the country with equal access to banking facilities. Accounts launched under PMJDYare Basic Savings Bank Deposit (BSBD) accounts that have added RuPay debit card and overdraft functionality. Maintaining a minimum balance is not required in BSBD accounts. </p>
                            <p>In consideration of the scheme's stunning success, the government expanded the accident insurance coverage to Rs 2 lakh in 2018, from Rs 1 lakh for new accounts launched after August 28, 2018. In addition, the cap facility for overdraft was doubled toRs 10,000 too. The Government has also changed the attention from "every family" to "every unbanked citizen." </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default PMJanDhan