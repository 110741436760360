import React, { useEffect, useState } from 'react'
import { Breadcrumb, Row, Col, Card, Form, Button } from 'react-bootstrap'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Layout from '../../layout/Layout'
import { API } from '../../App';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie'

const TopFundDetailEdit = () => {

  const tokenAuth = async () => {
    const token = Cookies.get('jwt')
    const response = await API.post("/home", {}, { headers: { "Authorization": `Bearer ${token}` } })
    if (response.data.status === false) {
      navigate("/")
    }
  }
  const [validate,setValidate] = useState(false)
  const navigate = useNavigate()
  const { _id } = useParams()
  const [data, setData] = useState([])
  let [status, setStatus] = useState(1)
  const [fund, setFund] = useState([])
  const [user, setUser] = useState({
    Company: "",
    Fund: "",
    FundName: "",
    Category: "",
    Rating: "",
    Return1: "",
    Risk: "",
    Nav: "",
    MiniSip: "",
    ExpRatio: "",
    FundSize: "",
    SID: "",
    Pros: "",
    Loss: "",
    TaxImp: "",
    Address: "",
    InvObj: "",
    Min1: "",
    Min2: "",
    Phone: "",
    Website: "",
    Custodian: "",
    Agent: "",
    AgentWeb: "",
    AgentAdd: "",
    AgentEmail: ""
  })
  const getData = async () => {
    const token = Cookies.get('jwt')
    const amcFundDet = await API.post(`/show/amc/fund/detail/${_id}`, {}, { headers: { "Authorization": `Bearer ${token}` } })
    if (amcFundDet.data.status === false) {
      if (amcFundDet.data.response_code === 429) {
        navigate("/")
      } else {
        toast.error('something went wrong')
      }
    } else {
      setUser({
        Company: amcFundDet.data.data.companyId,
        Fund: amcFundDet.data.data.fundId,
        FundName: amcFundDet.data.data.fundName,
        Category: amcFundDet.data.data.category,
        Rating: amcFundDet.data.data.rating,
        Return1: amcFundDet.data.data.return1y,
        Risk: amcFundDet.data.data.risk,
        Nav: amcFundDet.data.data.nav,
        MiniSip: amcFundDet.data.data.minSipAmt,
        ExpRatio: amcFundDet.data.data.expenseRatio,
        FundSize: amcFundDet.data.data.fundSize,
        SID: amcFundDet.data.data.sid,
        Pros: amcFundDet.data.data.pros,
        Loss: amcFundDet.data.data.loss,
        TaxImp: amcFundDet.data.data.taxImp,
        Address: amcFundDet.data.data.address,
        InvObj: amcFundDet.data.data.invObj,
        Min1: amcFundDet.data.data.min1stInv,
        Min2: amcFundDet.data.data.min2ndInv,
        Phone: amcFundDet.data.data.phone,
        Website: amcFundDet.data.data.website,
        Custodian: amcFundDet.data.data.custodian,
        Agent: amcFundDet.data.data.agent,
        AgentWeb: amcFundDet.data.data.agengWeb,
        AgentAdd: amcFundDet.data.data.agentAdd,
        AgentEmail: amcFundDet.data.data.agentEmail
      })
      setStatus(amcFundDet.data.data.status)
      setReturns(amcFundDet.data.data.returns)
      setHoldings(amcFundDet.data.data.holdings)
      setManager(amcFundDet.data.data.fundManeger)
    }
    const response = await API.post("/amc/company/list", {}, { headers: { "Authorization": `Bearer ${token}` } })
    if (response.data.status === false) {
      if (response.data.response_code === 429) {
        navigate("/")
      } else {
        toast.error('something went wrong in AMC Company List', { appearance: "error" })
      }
    } else {
      setData(response.data.data.data)
    }
    const fund = await API.post("/amc/fundlist/by/company", { company: amcFundDet.data.data.companyId }, { headers: { "Authorization": `Bearer ${token}` } })
    if (fund.data.status === false) {
      if (fund.data.response_code === 429) {
        navigate("/")
      } else {
        toast.error('something went wrong in Fund List', { appearance: "error" })
      }
    } else {
      setFund(fund.data.data)
    }
  }

  const getVal = async (e) => {
    setUser({ ...user, [e.target.name]: e.target.value })
    if (e.target.name === "Company") {
      if (e.target.value !== "") {
        const Form = new FormData
        Form.append("company", e.target.value)
        const token = Cookies.get('jwt')
        const response = await API.post("/amc/fundlist/by/company", Form, { headers: { "Authorization": `Bearer ${token}` } })
        if (response.data.status === true) {
          // setUser({ ...user, ["Fund"]: "" })
          setFund(response.data.data)
        } else {
          if (response.data.response_code === 429) {
            navigate("/")
          } else {
            setFund([])
          }

        }
      } else {
        setFund([])
      }
    }
  }

  //Returns
  const [returns, setReturns] = useState([{
    name: "",
    oneY: "",
    threeY: "",
    fiveY: "",
    allTime: ""
  }])
  const addReturn = (tag1, name) => {
    const returnTag = {
      name: "",
      oneY: "",
      threeY: "",
      fiveY: "",
      allTime: ""
    }
    setReturns([...returns, returnTag])
  }

  const removeReturn = (index, id) => {
    const data = [...returns]
    data.splice(index, 1)
    setReturns(data)
  }

  const returnChange = (e, index) => {
    let data = [...returns];
    data[index][e.target.name] = e.target.value
    setReturns(data)
  }

  //Holdings
  const [holdings, setHoldings] = useState([{
    url: "",
    name: "",
    sector: "",
    instrument: "",
    assets: ""
  }])
  const addHoldings = (tag1, name) => {
    const holdingTag = {
      url: "",
      name: "",
      sector: "",
      instrument: "",
      assets: ""
    }
    setHoldings([...holdings, holdingTag])
  }

  const removeHolding = (index, id) => {
    const data = [...holdings]
    data.splice(index, 1)
    setHoldings(data)
  }

  const holdingChange = (e, index) => {
    let data = [...holdings];
    data[index][e.target.name] = e.target.value
    setHoldings(data)
  }

  //Fund Managers
  const [manager, setManager] = useState([{
    name: "",
    education: "",
    experiance: "",
    managedList: [{
      schemeName: "",
      url: ""
    }]
  }])
  const addManager = (tag1, name) => {
    const returnTag = {
      name: "",
      education: "",
      experiance: "",
      managedList: [{
        schemeName: "",
        url: ""
      }]
    }
    setManager([...manager, returnTag])
  }

  const removeManager = (index, id) => {
    const data = [...manager]
    data.splice(index, 1)
    setManager(data)
  }

  const managerChange = (e, index) => {
    let data = [...manager];
    data[index][e.target.name] = e.target.value
    setManager(data)
  }

  const listChange = (e, ind, index) => {
    let data = [...manager];
    data[ind].managedList[index][e.target.name] = e.target.value
    setManager(data)
  }

  const addList = (ind) => {
    let data = [...manager]
    data[ind].managedList.push({
      schemeName: "",
      url: ""
    })
    setManager(data)
  }

  const removeList = (ind, index) => {
    let data = [...manager];
    data[ind].managedList.splice(index, 1)
    setManager(data)
  }

  const saveData = async () => {
    if (user.Company === "" || user.Fund === "" || user.FundName === "" || user.Category === "" || user.Rating === "" || user.Return1 === "" ||
      user.Risk === "" || user.Nav === "" || user.MiniSip === "" || user.ExpRatio === "" || user.FundSize === "" || user.SID === "" ||
      user.Pros === "" || user.Loss === "" || user.TaxImp === "" || user.Address === "" || user.InvObj === "" || user.Min1 === "" ||
      user.Min2 === "" || user.Phone === "" || user.Website === "" || user.Custodian === "" || user.Agent === "" || user.AgentWeb === "" ||
      user.AgentAdd === "" || user.AgentEmail === "") {
      setValidate(true)
    } else {
      let returnCounter = 0
      returns.map((returnVal) => {
        if (returnVal.name === "" || returnVal.oneY === "" || returnVal.threeY === "" || returnVal.fiveY === "" || returnVal.allTime === "") {
          returnCounter++
        }
      })
      if (returnCounter === 0) {
        let holdingCounter = 0
        holdings.map((holdingsVal) => {
          if (holdingsVal.url === "" || holdingsVal.name === "" || holdingsVal.sector === "" || holdingsVal.instrument === "" || holdingsVal.assets === "") {
            holdingCounter++
          }
        })
        if (holdingCounter === 0) {
          let managerCount = 0
          manager.map((managerVal) => {
            if (managerVal.name === "" || managerVal.education === "" || managerVal.experiance === "") {
              managerCount++
            }
            managerVal.managedList.map((listVal) => {
              if (listVal.schemeName === "" || listVal.url === "") {
                managerCount++
              }
            })
          })
          if (managerCount === 0) {
            const Form = new FormData()
            Form.append('companyid', user.Company)
            Form.append('fundid', user.Fund)
            Form.append('fundname', user.FundName)
            Form.append('category', user.Category)
            Form.append('rating', user.Rating)
            Form.append('return1', user.Return1)
            Form.append('risk', user.Risk)
            Form.append('nav', user.Nav)
            Form.append('minisip', user.MiniSip)
            Form.append('expratio', user.ExpRatio)
            Form.append('fundsize', user.FundSize)
            Form.append('pros', user.Pros)
            Form.append('loss', user.Loss)
            Form.append('sid', user.SID)
            Form.append('invobj', user.InvObj)
            Form.append('taximp', user.TaxImp)
            Form.append('min1', user.Min1)
            Form.append('min2', user.Min2)
            Form.append('address', user.Address)
            Form.append('phone', user.Phone)
            Form.append('website', user.Website)
            Form.append('custodian', user.Custodian)
            Form.append('agent', user.Agent)
            Form.append('agentadd', user.AgentAdd)
            Form.append('agentmail', user.AgentEmail)
            Form.append('agentweb', user.AgentWeb)
            Form.append('return', JSON.stringify(returns))
            Form.append('holdings', JSON.stringify(holdings))
            Form.append('fundmanager', JSON.stringify(manager))
            Form.append('status', status)
            const token = Cookies.get('jwt')
            const response = await API.post(`/edit/amc/company/fund/detail/${_id}`, Form, { headers: { "Authorization": `Bearer ${token}` } })
            console.log(response);
            if (response.data.status === true) {
              toast.success("Top Fund Detail Updated Succesfully")
              navigate("/funddetail")
            } else {
              if (response.data.response_code === 429) {
                navigate("/")
              } else {
                toast.error('something went wrongg')
              }
            }
          } else {
            setValidate(true)
          }
        } else {
          setValidate(true)
        }
      } else {
        setValidate(true)
      }
    }
  }

  useEffect(() => {
    tokenAuth()
    getData()
  }, [])

  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3>Edit Top Fund Details</h3>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/funddetail">Top Fund Detail List</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Edit Top Fund Details</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="page-content">
      <Form noValidate validated={validate}>
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                {/* 1 */}
                <Row>
                  <Col lg={3} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='state-name'>Company Name</Form.Label>
                      <Form.Select id="state-name" className="my-2" name='Company' onChange={getVal} required>
                        <option value="">Select Company</option>
                        {
                          data.map((dataVal, ind) => {
                            return (
                              <option selected={dataVal._id === user.Company ? true : false} value={dataVal._id} key={ind}>{dataVal.name}</option>
                            )
                          })
                        }
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={3} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='top-fund'>Top Fund</Form.Label>
                      <Form.Select id="top-fund" className="my-2" name='Fund' onChange={getVal} required>
                        <option value="">Select Fund Name</option>
                        {
                          fund.map((dataVal, ind) => {
                            return (
                              <option selected={dataVal._id === user.Fund ? true : false} value={dataVal._id} key={ind}>{dataVal.fundName}</option>
                            )
                          })
                        }
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={3} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='fund-name'>Fund Name</Form.Label>
                      <Form.Control type="text" className="my-2" id='fund-name' name='FundName' value={user.FundName} onChange={(e) => { getVal(e) }} placeholder="Enter Your Fund Name" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={3} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='cat-name'>Category</Form.Label>
                      <Form.Control type="text" className="my-2" id='cat-name' name='Category' value={user.Category} onChange={(e) => { getVal(e) }} placeholder="Enter Your Category" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={3} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='rating'>Rating</Form.Label>
                      <Form.Control type="text" className="my-2" id='rating' name='Rating' value={user.Rating} onChange={(e) => { getVal(e) }} placeholder="Enter Your Rating" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={3} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='return-1'>Return 1Y</Form.Label>
                      <Form.Control type="text" className="my-2" id='return-1' name='Return1' value={user.Return1} onChange={(e) => { getVal(e) }} placeholder="Enter Your Return One Year" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={3} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='risk'>Risk</Form.Label>
                      <Form.Control type="text" className="my-2" id='risk' name='Risk' value={user.Risk} onChange={(e) => { getVal(e) }} placeholder="Enter Your Risk" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={3} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='nav'>Nav</Form.Label>
                      <Form.Control type="text" className="my-2" id='nav' name='Nav' value={user.Nav} onChange={(e) => { getVal(e) }} placeholder="Enter Your Nav" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={3} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='miniSipAmt'>Mini Sip Amt</Form.Label>
                      <Form.Control type="text" className="my-2" id='miniSipAmt:' name='MiniSip' value={user.MiniSip} onChange={(e) => { getVal(e) }} placeholder="Enter Your Mini Sip Amt" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={3} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='expRatio'>Exp Ratio</Form.Label>
                      <Form.Control type="text" className="my-2" id='expRatio' name='ExpRatio' value={user.ExpRatio} onChange={(e) => { getVal(e) }} placeholder="Enter Your Exp Ratio" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={3} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='fundSize'>Fund Size</Form.Label>
                      <Form.Control type="text" className="my-2" id='fundSize' name='FundSize' value={user.FundSize} onChange={(e) => { getVal(e) }} placeholder="Enter Your Fund Size" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={3} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='sid'>Sid</Form.Label>
                      <Form.Control type="text" className="my-2" id='sid' name='SID' value={user.SID} onChange={(e) => { getVal(e) }} placeholder="Enter Your Sid" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={6} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='pros'>Pros</Form.Label>
                      <Form.Control as="textarea" rows={4} className="my-2" id='pros' name='Pros' value={user.Pros} onChange={(e) => { getVal(e) }} placeholder="Enter Your Pros" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={6} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='loss'>Loss</Form.Label>
                      <Form.Control as="textarea" rows={4} className="my-2" id='loss' name='Loss' value={user.Loss} onChange={(e) => { getVal(e) }} placeholder="Enter Your Loss" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={6} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='taxImp'>Tax Imp</Form.Label>
                      <Form.Control as="textarea" rows={4} className="my-2" id='taxImp' name='TaxImp' value={user.TaxImp} onChange={(e) => { getVal(e) }} placeholder="Enter Your Tax Imp" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={6} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='address'>Address</Form.Label>
                      <Form.Control as="textarea" rows={4} className="my-2" id='address' name='Address' value={user.Address} onChange={(e) => { getVal(e) }} placeholder="Enter Your Address" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={3} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='invObj'>Inv Obj</Form.Label>
                      <Form.Control type="text" className="my-2" id='invObj' name='InvObj' value={user.InvObj} onChange={(e) => { getVal(e) }} placeholder="Enter Your invObj" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={3} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='min1stInv'>Min 1st Inv</Form.Label>
                      <Form.Control type="text" className="my-2" id='min1stInv' name='Min1' value={user.Min1} onChange={(e) => { getVal(e) }} placeholder="Enter Your Min 1st Inv" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={3} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='min2ndInv'>Min 2nd Inv</Form.Label>
                      <Form.Control type="text" className="my-2" id='min2ndInv' name='Min2' value={user.Min2} onChange={(e) => { getVal(e) }} placeholder="Enter Your Min 2nd Inv" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={3} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='phone'>Phone</Form.Label>
                      <Form.Control type="text" className="my-2" id='phone' name='Phone' value={user.Phone} onChange={(e) => { getVal(e) }} placeholder="Enter Your Phone" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={3} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='web-url'>WebSite</Form.Label>
                      <Form.Control type="text" className="my-2 pe-5" id='web-url' name='Website' value={user.Website} onChange={(e) => { getVal(e) }} placeholder="Enter Your WebSite" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={3} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='custodian'>Custodian</Form.Label>
                      <Form.Control type="text" className="my-2" id='custodian' name='Custodian' value={user.Custodian} onChange={(e) => { getVal(e) }} placeholder="Enter Your Custodian" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={3} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='agent'>Agent</Form.Label>
                      <Form.Control type="text" className="my-2" id='agent' name='Agent' value={user.Agent} onChange={(e) => { getVal(e) }} placeholder="Enter Your Agent" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={3} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='a-url'>Agent Web</Form.Label>
                      <Form.Control type="text" className="my-2 pe-5" id='a-url' name='AgentWeb' value={user.AgentWeb} onChange={(e) => { getVal(e) }} placeholder="Enter Your Agent Web" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={6} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='agentAdd'>Agent Add</Form.Label>
                      <Form.Control as="textarea" rows={4} className="my-2" id='agentAdd' name='AgentAdd' value={user.AgentAdd} onChange={(e) => { getVal(e) }} placeholder="Enter Your Agent Add" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={6} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='a-mail'>Agent Email</Form.Label>
                      <Form.Control as="textarea" rows={4} className="my-2" id='a-email' name='AgentEmail' value={user.AgentEmail} onChange={(e) => { getVal(e) }} placeholder="Enter Your Agent Email" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={3} sm={12} >
                    <Form.Label className="d-block mb-2" htmlFor='bank-status'>Status </Form.Label>
                    <BootstrapSwitchButton id="bank-status" checked={status === 1 ? true : false} onChange={(checked) => { if (checked === true) { setStatus(1) } else { setStatus(0) } }} onlabel='Enable' offlabel='Disable' width={125} />
                  </Col>
                </Row>
                {/* 2. Return */}
                <Row className='mt-2'>
                  <Col sm={12}>
                    {
                      returns.map((returnVal, ind) => {
                        return (
                          <Row>
                            <Col lg={2} sm={12}>
                              <Form.Group >
                                <Form.Label htmlFor='r-name'>Return Name</Form.Label>
                                <Form.Control type="text" className="my-2" id='r-name' value={returnVal.name} name='name' onChange={(e) => { returnChange(e, ind) }} placeholder="Enter Your Name" required/>
                              </Form.Group>
                            </Col>
                            <Col lg={2} sm={12}>
                              <Form.Group >
                                <Form.Label htmlFor='oney'>Return One Year</Form.Label>
                                <Form.Control type="text" className="my-2" id='oney' value={returnVal.oneY} name='oneY' onChange={(e) => { returnChange(e, ind) }} placeholder="Enter Your One Year" required/>
                              </Form.Group>
                            </Col>
                            <Col lg={2} sm={12}>
                              <Form.Group >
                                <Form.Label htmlFor='five'>Return Five Year</Form.Label>
                                <Form.Control type="text" className="my-2" id='five' value={returnVal.threeY} name='threeY' onChange={(e) => { returnChange(e, ind) }} placeholder="Enter Your Five Year" required/>
                              </Form.Group>
                            </Col>
                            <Col lg={2} sm={12}>
                              <Form.Group >
                                <Form.Label htmlFor='threey'>Return Three Year</Form.Label>
                                <Form.Control type="text" className="my-2" id='threey' value={returnVal.fiveY} name='fiveY' onChange={(e) => { returnChange(e, ind) }} placeholder="Enter Your Three Year" required/>
                              </Form.Group>
                            </Col>
                            <Col lg={2} sm={12}>
                              <Form.Group >
                                <Form.Label htmlFor='alltime'>Return All Time</Form.Label>
                                <Form.Control type="text" className="my-2" id='alltime' value={returnVal.allTime} name='allTime' onChange={(e) => { returnChange(e, ind) }} placeholder="Enter Your All Time" required/>
                              </Form.Group>
                            </Col>
                            <Col lg={2} sm={12} className="align-self-end my-2">
                              {
                                ind === 0 ? <Button variant="primary" size="sm" className="btn-icon" onClick={addReturn}><i class='bx bx-plus'></i></Button> :
                                  <Button variant="danger" size="sm" className="btn-icon" onClick={() => { removeReturn(ind) }}><i class='bx bx-x' ></i></Button>
                              }
                            </Col>
                          </Row>
                        )
                      })
                    }
                  </Col>

                </Row>
                {/* 3. Holdings */}
                <Row>
                  <Col sm={12}>
                    {
                      holdings.map((holdingVal, ind) => {
                        return (
                          <Row>
                            <Col lg={2} sm={12}>
                              <Form.Group >
                                <Form.Label htmlFor='h-url'>Holdings URL</Form.Label>
                                <Form.Control type="text" className="my-2" id='r-name' value={holdingVal.url} name='url' onChange={(e) => { holdingChange(e, ind) }} placeholder="Enter Your URL" required/>
                              </Form.Group>
                            </Col>
                            <Col lg={2} sm={12}>
                              <Form.Group >
                                <Form.Label htmlFor='h-name'>Holdings Name</Form.Label>
                                <Form.Control type="text" className="my-2" id='h-name' value={holdingVal.name} name='name' onChange={(e) => { holdingChange(e, ind) }} placeholder="Enter Your Name" required/>
                              </Form.Group>
                            </Col>
                            <Col lg={2} sm={12}>
                              <Form.Group >
                                <Form.Label htmlFor='h-sector'>Holdings Sector</Form.Label>
                                <Form.Control type="text" className="my-2" id='h-sector' value={holdingVal.sector} name='sector' onChange={(e) => { holdingChange(e, ind) }} placeholder="Enter Your Sector" required/>
                              </Form.Group>
                            </Col>
                            <Col lg={2} sm={12}>
                              <Form.Group >
                                <Form.Label htmlFor='h-instrument'>Holdings Instrument</Form.Label>
                                <Form.Control type="text" className="my-2" id='h-instrument' value={holdingVal.instrument} name='instrument' onChange={(e) => { holdingChange(e, ind) }} placeholder="Enter Your Instrument" required/>
                              </Form.Group>
                            </Col>
                            <Col lg={2} sm={12}>
                              <Form.Group >
                                <Form.Label htmlFor='h-assets'>Holdings Assets</Form.Label>
                                <Form.Control type="text" className="my-2" id='h-assets' value={holdingVal.assets} name='assets' onChange={(e) => { holdingChange(e, ind) }} placeholder="Enter Your Assets" required/>
                              </Form.Group>
                            </Col>
                            <Col lg={2} sm={12} className="align-self-end my-2">
                              {
                                ind === 0 ? <Button variant="primary" size="sm" className="btn-icon" onClick={addHoldings}><i class='bx bx-plus'></i></Button> :
                                  <Button variant="danger" size="sm" className="btn-icon" onClick={() => { removeHolding(ind) }}><i class='bx bx-x' ></i></Button>
                              }
                            </Col>
                          </Row>
                        )
                      })
                    }
                  </Col>

                </Row>
                {/* 4. Fund Manager */}
                <Row>
                  <Col sm={12}>
                    {
                      manager.map((managerVal, ind) => {
                        return (
                          <Row>
                            <Col lg={2} sm={12}>
                              <Form.Group >
                                <Form.Label htmlFor='fm-url'>Name</Form.Label>
                                <Form.Control type="text" className="my-2" id='fm-name' value={managerVal.name} name='name' onChange={(e) => { managerChange(e, ind) }} placeholder="Enter Your Name" required/>
                              </Form.Group>
                            </Col>
                            <Col lg={2} sm={12}>
                              <Form.Group >
                                <Form.Label htmlFor='h-edu'>Education</Form.Label>
                                <Form.Control type="text" className="my-2" id='h-edu' value={managerVal.education} name='education' onChange={(e) => { managerChange(e, ind) }} placeholder="Enter Your Education" required/>
                              </Form.Group>
                            </Col>
                            <Col lg={2} sm={12}>
                              <Form.Group >
                                <Form.Label htmlFor='fm-exp'>Experiance</Form.Label>
                                <Form.Control type="text" className="my-2" id='fm-exp' value={managerVal.experiance} name='experiance' onChange={(e) => { managerChange(e, ind) }} placeholder="Enter Your Experiance" required/>
                              </Form.Group>
                            </Col>
                            {/* Managed List */}
                            <Col lg={5} sm={12}>
                              {
                                managerVal.managedList.map((listVal, index) => {
                                  return (
                                    <Row>
                                      <Col lg={5} sm={12}>
                                        <Form.Group >
                                          <Form.Label htmlFor='ml-sname'>Scheme Name</Form.Label>
                                          <Form.Control type="text" className="my-2" id='ml-sname' value={listVal.schemeName} name='schemeName' onChange={(e) => { listChange(e, ind, index) }} placeholder="Enter Your Scheme Name" required/>
                                        </Form.Group>
                                      </Col>
                                      <Col lg={5} sm={12}>
                                        <Form.Group >
                                          <Form.Label htmlFor='ml-url'>URL</Form.Label>
                                          <Form.Control type="text" className="my-2" id='ml-url' value={listVal.url} name='url' onChange={(e) => { listChange(e, ind, index) }} placeholder="Enter Your URL" required/>
                                        </Form.Group>
                                      </Col>
                                      <Col lg={2} sm={12} className="align-self-end my-2">
                                        {
                                          index === 0 ? <Button variant="primary" size="sm" className="btn-icon" onClick={() => { addList(ind) }}><i class='bx bx-plus'></i></Button> :
                                            <Button variant="danger" size="sm" className="btn-icon" onClick={() => { removeList(ind, index) }}><i class='bx bx-x' ></i></Button>
                                        }
                                      </Col>
                                    </Row>
                                  )
                                })
                              }
                            </Col>

                            <Col lg={1} sm={12} className="my-2">
                              {
                                ind === 0 ? <Button variant="primary" size="sm" className="btn-icon m-t-30" onClick={addManager}><i class='bx bx-plus'></i></Button> :
                                  <Button variant="danger" size="sm" className="btn-icon m-t-30" onClick={() => { removeManager(ind) }}><i class='bx bx-x' ></i></Button>
                              }
                            </Col>
                          </Row>
                        )
                      })
                    }
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-end">
                <Button variant="primary" className="me-3" onClick={saveData}>Save</Button>
                <Link to="/funddetail">
                  <Button variant="secondary">Cancle</Button>
                </Link>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Form>
      </div>
    </Layout>
  )
}

export default TopFundDetailEdit