import React, { useEffect, useState } from 'react'
import { Breadcrumb, Row, Col, Card, Form, Button, InputGroup } from 'react-bootstrap'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Layout from '../../layout/Layout'
import { API } from '../../App';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie'

const MutualFundCompanyEdit = () => {

    const tokenAuth = async () => {
        const token = Cookies.get('jwt')
        const response = await API.post("/home", {}, { headers: { "Authorization": `Bearer ${token}` } })
        if (response.data.status === false) {
            navigate("/")
        }
    }
    const [validate,setValidate] = useState(false)
    const navigate = useNavigate()
    const { _id } = useParams()
    const [user, setUser] = useState({
        Name: "",
        Status: "",
        Image: ""
    })

    const getData = async () => {
        const token = Cookies.get('jwt')
        const amcData = await API.post(`/show/amc/${_id}`, {}, { headers: { "Authorization": `Bearer ${token}` } })
        if (amcData.data.status === false) {
            if (amcData.data.response_code === 429) {
                navigate("/")
            } else {
                toast.error('something went wrong')
            }
        } else {
            setUser({
                Name: amcData.data.data.name,
                Status: amcData.data.data.status,
                Image: amcData.data.data.img
            })
        }
    }

    const getVal = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value })
    }

    const [selectedImage, setSelectedImage] = useState(null);

    const onImags = (event) => {
        setSelectedImage(event.target.files[0]);
    }

    const saveData = async () => {
        if (user.Name === "") {
            setValidate(true)
        } else {
            const Form = new FormData()
            Form.append('name', user.Name)
            Form.append('status', user.Status)
            Form.append('image', selectedImage == null ? user.Image : selectedImage)
            const token = Cookies.get('jwt')
            const response = await API.post(`/edit/amc/${_id}`, Form, { headers: { "Authorization": `Bearer ${token}` } })
            if (response.data.status === true) {
                toast.success("Company updated succesfully")
                navigate("/mutualfundcompany")
            } else {
                if (response.data.response_code === 429) {
                    navigate("/")
                } else {
                    toast.error('something went wrong')
                }
            }
        }
    }

    useEffect(() => {
        tokenAuth()
        getData()
    }, [])

    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3>Add Mutual Fund Company</h3>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/mutualfundcompany">Mutual Fund Company List</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Edit Mutual Fund Company</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="page-content">
            <Form noValidate validated={validate}>
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col lg={4} sm={12}>
                                        <Form.Group >
                                            <Form.Label htmlFor='state-name'>Company Name</Form.Label>
                                            <Form.Control type="text" className="my-2" id='state-name' value={user.Name} name="Name" onChange={(e) => { getVal(e) }} placeholder="Enter Your Company Name" required/>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4} sm={12}>
                                        <Form.Group >
                                            <Form.Label htmlFor='state-name'>Image</Form.Label>
                                            <InputGroup className="my-2">
                                                <Form.Control type="file" id='state-name' onChange={(e) => { { onImags(e) } }} placeholder="Enter Your Name" />
                                                <img src={user.Image} alt="Logo" height={40} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4} sm={12}>
                                        <Form.Group >
                                            <Form.Label className="d-block mb-2" htmlFor='bank-status'>Status </Form.Label>
                                            <BootstrapSwitchButton id="bank-status" checked={user.Status === 1 ? true : false} onChange={(checked) => { if (checked === true) { setUser({ ...user, Status: 1 }) } else { setUser({ ...user, Status: 0 }) } }} onlabel='Enable' offlabel='Disable' width={125} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer className="text-end">
                                <Button variant="primary" className="me-3" onClick={saveData}>Save</Button>
                                <Link to="/mutualfundcompany">
                                    <Button variant="secondary">Cancle</Button>
                                </Link>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Form>
            </div>
        </Layout>
    )
}

export default MutualFundCompanyEdit