import React, { useContext } from 'react'
import { Col, Row, Table, Button, Card, Form } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Layout from '../../layout/Layout'
import { useState } from 'react';
import { API, AuthContext } from '../../App';
import Pagination from 'rc-pagination'
import Swal from 'sweetalert2'
import { useEffect } from 'react';
import {toast} from 'react-toastify'
import { requestSearch1 } from '../Search';
import Cookies from 'js-cookie'
import {SearchFunction} from '../../Api/Api'

const District = () => {
    const { dtSearch,setDtSearch,commonSearch,setCommonSearch} = useContext(AuthContext);
    const [iconcoror, Seticoncoror] = useState("id_up")
    const [sData, setSData] = useState([])
    const [dataLength, setDataLength] = useState(1)
    const navigate = useNavigate()
    const tokenAuth = async (dataSort) => {
        const token = Cookies.get('jwt')
        const response = await API.post("/home", dataSort, { headers: { "Authorization": `Bearer ${token}` } })
        if (response.data.status === false) {
            navigate("/")
        }
    }
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [state, setState] = useState([])
    const getData = async (search) => {
        const token = Cookies.get('jwt')
        setLoading(true)
        const response = await API.post("/getCity", search.state===''?{}:{id:search.state}, { headers: { "Authorization": `Bearer ${token}` } })
        if (response.data.status === false) {
            if (response.data.response_code === 429) {
                navigate("/")
            } else {
                setDataLength(0)
                setLoading(false)
                setSData([])
            }
        } else {
            setData(response.data.data.data)
            setSData(response.data.data.data)
            if(commonSearch[1].search.city!==""){
                requestSearch(commonSearch[1].search.city,response.data.data.data)
            }else{
                setDataLength(1)
            }
            setLoading(false)
            setCurrent(1)
        }
        const stateRes = await API.post("/getState", {}, { headers: { "Authorization": `Bearer ${token}` } })
        if (stateRes.data.status === true) {
            setState(stateRes.data.data.data)
        }
    }

    const change = async (id, e) => {
        var Status;
        Status = (e === true) ? 1 : 0
        const Form = new FormData()
        // console.log(Status)
        Form.append('id', id)
        Form.append('status', Status)
        const token = Cookies.get('jwt')
        const update = await API.post(`/cityStatus/${id}`, Form, { headers: { "Authorization": `Bearer ${token}` } })
        if (update.data.status === false) {
            if (update.data.response_code === 429) {
                navigate("/")
            } else {
                toast.error('Something went wrong')
            }
        }else{
            var statusData = data.findIndex(x => x._id ===id);
            data[statusData].status=Status
            toast.success('Status Updated')
        }
    }

    const deleteUser = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger me-2'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                const token = Cookies.get('jwt')
                const delCity = await API.post(`/deleteCity/${id}`, {}, { headers: { "Authorization": `Bearer ${token}` } })
                if (delCity.data.status === false) {
                    if (delCity.data.response_code === 429) {
                        navigate("/")
                    } else {
                        swalWithBootstrapButtons.fire(
                            'Cancelled',
                            `${delCity.data.response_message}`,
                            'error'
                        )
                    }
                } else {
                    getData(dtSearch)
                    setCurrent(1)
                }

            } 
        })
    }

    const sorting = (col, type = "string", order, e) => {
        Seticoncoror(e.target.id)
        if (order === "ASC") {
            if(col==="state"){
                const sorted = [...data].sort((a, b) =>
                    a.state.name.toLowerCase().trim() > b.state.name.toLowerCase().trim() ? 1 : -1
                );
                if (iconcoror !== e.target.id) {
                    setData(sorted)
                }
            }else{
                const sorted = [...data].sort((a, b) =>
                    a[col] > b[col] ? 1 : -1
                );
                if (iconcoror !== e.target.id) {
                    setData(sorted)
                }
            }
        }
        if (order === "DSC") {
          if(col==="state"){
            const sorted = [...data].sort((a, b) =>
                a.state.name.toLowerCase().trim() < b.state.name.toLowerCase().trim() ? 1 : -1
            );
            if (iconcoror !== e.target.id) {
                setData(sorted)
            }
          }else{
            const sorted = [...data].sort((a, b) =>
                a[col] < b[col] ? 1 : -1
            );
            if (iconcoror !== e.target.id) {
                setData(sorted)
            }
          }
        }
    }

    //ClearSearchFunction
    const clearSearchFunc = (searchEle)=>{
        let src = [...commonSearch]
        src.map((val)=>{
            if(val.name!==searchEle){
                if(val.name==="City"){
                    val.search={
                        state:"",
                        city:""
                      }
                }else if(val.name==="Fuel"){
                    val.search={
                        state:"",
                        city:"",
                        date:""
                      }
                }else if(val.name==="Branch"){
                    val.search={
                        bank:"",
                        state:"",
                        city:""
                      }
                }else{
                    val.search=""
                }
            }
            return val
        })
        setCommonSearch(src)
    }

    useEffect(() => {
        tokenAuth()
        getData(commonSearch[1].search)
        clearSearchFunc("City")
    }, [])
    const requestSearch2 = async(e) => {
        // getData({state:e.target.value,city:''})
        const src = await SearchFunction("/getCity",{id:e.target.value,city:''})
        console.log(src,"src");
        setData(src.data)
        const serObj = {
            state:e.target.value,
            city:''
        }
        // setDtSearch(serObj)
        let srcVal = [...commonSearch]
        srcVal[1].search = serObj
        setCommonSearch(srcVal)
    };

    const requestSearch = (searchedVal,mainData) => {
        const searchData = requestSearch1(searchedVal,mainData,mainData,"name")
        if(searchData.length===0){
            setData([])
            setDataLength(0)
        }else{
            setData(searchData)
            setDataLength(1)
        }
        setCurrent(1)
        // setDtSearch({...dtSearch,['city']:searchedVal})
        let srcVal = [...commonSearch]
        srcVal[1].search.city = searchedVal
        setCommonSearch(srcVal)
    };

    // Paggintion Code // 
    const [size, setSize] = useState(10);
    const [current, setCurrent] = useState(1);

    const wetData = (current, pageSize) => {
        return data.slice((current - 1) * pageSize, current * pageSize);
    };

    const PerPageChange = (value) => {
        console.log(value)
        setSize(value);
        console.log(size)
        const newPerPage = Math.ceil(data.length / value);
        if (current > newPerPage) {
            setCurrent(newPerPage);
        }
    }

    const PaginationChange = (page, pageSize) => {
        setCurrent(page);
        setSize(pageSize)
    }
    const PrevNextArrow = (current, type, originalElement) => {
        if (type === 'prev') {
            return <button className='paggination-btn'>Previous</button>;
        }
        if (type === 'next') {
            return <button className='paggination-btn'>Next</button>;
        }
        return originalElement;
    }

    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3 className='my-1'>District</h3>
                <Row className='align-items-center justify-content-end'>
                    <Col md="3">
                        <Form.Control type="search" placeholder="Search City" name='city' value={commonSearch[1].search.city} onChange={(e) => requestSearch(e.target.value,sData)} className="form-control my-1" />
                    </Col>
                    <Col md="4">
                        <Form.Select id="loan-name" className="my-1" name="state" onChange={requestSearch2}>
                            <option defaultValue="" value="">Select State Name</option>
                            {
                                state.map((dataVal, ind) => {
                                    return (
                                        <option key={ind} selected={dataVal._id === commonSearch[1].search.state ? true : false} value={dataVal._id}>{dataVal.name}</option>
                                    )
                                })
                            }
                        </Form.Select>
                    </Col>
                    <Col md="auto" xs={6}>
                        <Form.Select onChange={e => { setSize(Number(e.target.value)); setCurrent(1) }} className="my-1">
                        {
                            [10, 25, 50, 100].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                            ))
                        }
                        </Form.Select>
                    </Col>
                    <Col md="auto">
                        <Link className="btn btn-primary my-1" to="/district/add">Add New</Link>
                    </Col>
                </Row>
            </div>
            <div className="page-content">
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Body>
                                    {
                                        loading ? <div className='loader table-loader' ></div> : <></>
                                    }
                                <Table bordered responsive>
                                    <thead>
                                        <tr>
                                            {/* <th width='5%' className='text-center' onClick={()=>{sorting("_id")}}>No <i className='bx bxs-sort-alt m-r-0'></i></th> */}
                                            <th width='5%'>
                                            <div className='table-sort-filter justify-content-center'>
                                                No.
                                                {/* <span className='table-sort'>
                                                    <div className={`sort-down ${iconcoror === "id_down" ? "active" : ""}`} id="id_down" onClick={(e) => { sorting('_id', "", "DSC", e) }} ></div>
                                                    <div className={`sort-up ${iconcoror === "id_up" ? "active" : ""}`} id="id_up" onClick={(e) => { sorting('_id', "", "ASC", e) }} ></div>
                                                </span> */}
                                            </div>
                                            </th>
                                            <th width='35%' >
                                            <div className='table-sort-filter'>
                                                State Name
                                                <span className='table-sort'>
                                                    <div className={`sort-down ${iconcoror === "state_down" ? "active" : ""}`} id="state_down" onClick={(e) => { sorting('state', "", "DSC", e) }} ></div>
                                                    <div className={`sort-up ${iconcoror === "state_up" ? "active" : ""}`} id="state_up" onClick={(e) => { sorting('state', "", "ASC", e) }} ></div>
                                                </span>
                                            </div>
                                            </th>
                                            <th width='40%'>
                                            <div className='table-sort-filter'>
                                                Name 
                                                <span className='table-sort'>
                                                    <div className={`sort-down ${iconcoror === "name_down" ? "active" : ""}`} id="name_down" onClick={(e) => { sorting('name', "", "DSC", e) }} ></div>
                                                    <div className={`sort-up ${iconcoror === "name_up" ? "active" : ""}`} id="name_up" onClick={(e) => { sorting('name', "", "ASC", e) }} ></div>
                                                </span>
                                            </div>
                                            </th>
                                            <th width='10%'>
                                            <div className='table-sort-filter justify-content-center'>
                                                Status
                                                <span className='table-sort'>
                                                    <div className={`sort-down ${iconcoror === "status_down" ? "active" : ""}`} id="status_down" onClick={(e) => { sorting('status', "", "DSC", e) }} ></div>
                                                    <div className={`sort-up ${iconcoror === "status_up" ? "active" : ""}`} id="status_up" onClick={(e) => { sorting('status', "", "ASC", e) }} ></div>
                                                </span>
                                            </div>
                                            </th>
                                            <th width='10%' className='text-center'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            wetData(current, size).map((dataVal, ind) => {
                                                return (
                                                    <tr key={ind}>
                                                        <td className='text-center'>{(current === 1) ? ind + 1 : current * size + ind + 1 - size}</td>
                                                        {/* <td className='text-center'>{oid.indexOf(dataVal?._id)+1}</td> */}
                                                        <td>{dataVal?.state?.name}</td>
                                                        <td>{dataVal?.name}</td>
                                                        <td className='text-center'>
                                                            <BootstrapSwitchButton checked={dataVal.status === 1 ? true : false} onChange={(e) => { change(dataVal._id, e) }} onlabel='Enable' offlabel='Disable' size="sm" width={100} />
                                                        </td>
                                                        <td className='text-center'>
                                                            <Link className="btn btn-outline-primary me-2 btn-icon" size="sm" to={`/district/edit/${dataVal._id}`} ><i className='bx bx-edit-alt'></i></Link>
                                                            <Button variant="outline-danger" size="sm" className="btn-icon" onClick={() => deleteUser(dataVal._id)}><i className='bx bx-trash-alt' ></i></Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        {
                                            dataLength === 0 ? <tr>
                                                <td colSpan="100%" className="p-0">
                                                    <div className='no-found'>
                                                        <img src="../../not-found/district-amc.svg" alt='Data Not Found'/>
                                                        <p>No Found District List</p>
                                                    </div>
                                                </td>
                                            </tr> : ""
                                        }
                                    </tbody>
                                </Table>
                                {
                                    data.length > size ?
                                    <div className="pagination-custom">
                                        <Pagination showTitle={false}
                                            className="pagination-data"
                                            onChange={PaginationChange}
                                            total={data.length}
                                            current={current}
                                            pageSize={size}
                                            showSizeChanger={false}
                                            itemRender={PrevNextArrow}
                                            onShowSizeChange={PerPageChange}
                                        />
                                    </div>:""
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Layout>
    )
}

export default District