import React, { useEffect, useState } from 'react'
import { Breadcrumb, Row, Col, Card, Form, Button } from 'react-bootstrap'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Layout from '../../layout/Layout'
import { API } from '../../App';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

const AmcAdd = () => {

  const tokenAuth = async () => {
    const token = Cookies.get('jwt')
    const response = await API.post("/home", {}, { headers: { "Authorization": `Bearer ${token}` } })
    if (response.data.status === false) {
      navigate("/")
    }
  }
  const [validate,setValidate] = useState(false)
  const [status, setStatus] = useState({
    status:1,
    webview:0
  })
  const navigate = useNavigate()
  const [inpData, setInpData] = useState({
    name: ""
  })

  const getValue = (e) => {
    setInpData({ ...inpData, [e.target.name]: e.target.value })
  }

  const saveData = async () => {
    if (inpData.name === "") {
      setValidate(true)
    } else {
      const Form = new FormData()
      Form.append('name', inpData.name)
      Form.append('status', status.status)
      Form.append('webview', status.webview)
      const token = Cookies.get('jwt')
      const response = await API.post("/add/amc/field", Form, { headers: { "Authorization": `Bearer ${token}` } })
      if (response.data.status === true) {
        toast.success("AMC Field Added Succesfully")
        navigate("/amc")
      } else {
        if (response.data.response_code === 429) {
          navigate("/")
        } else {
          toast.error('something went wrong')
        }
      }
    }
  }

  useEffect(() => {
    tokenAuth()
  }, [])

  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3>Add AMC</h3>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/amc">AMC List</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Add New AMC</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="page-content">
      <Form noValidate validated={validate}>
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Row>
                  <Col lg={4} sm={12}>
                    <Form.Group >
                      <Form.Label htmlFor='state-name'>Company Name</Form.Label>
                      <Form.Control type="text" className="my-2" id='state-name' name="name" onChange={(e) => { getValue(e) }} placeholder="Enter Your Company Name" required/>
                    </Form.Group>
                  </Col>
                  <Col lg={2} sm={6} >
                    <Form.Group >
                      <Form.Label className="d-block mb-2" htmlFor='bank-status'>Status </Form.Label>
                      <BootstrapSwitchButton id="bank-status" checked={status.status === 1 ? true : false} onChange={(checked) => { if (checked === true) { setStatus({...status,['status']:1}) } else { setStatus({...status,['status']:0}) } }} onlabel='Enable' offlabel='Disable' width={125} />
                    </Form.Group>
                  </Col>
                  <Col lg={2} sm={6} >
                    <Form.Group >
                      <Form.Label className="d-block mb-2" htmlFor='bank-status'>Webview </Form.Label>
                      <BootstrapSwitchButton id="bank-status" checked={status.webview === 1 ? true : false} onChange={(checked) => { if (checked === true) { setStatus({...status,['webview']:1}) } else { setStatus({...status,['webview']:0}) } }} onlabel='Enable' offlabel='Disable' width={125} />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-end">
                <Button variant="primary" className="me-3" onClick={saveData}>Save</Button>
                <Link to="/amc">
                  <Button variant="secondary">Cancle</Button>
                </Link>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Form>
      </div>
    </Layout>
  )
}

export default AmcAdd