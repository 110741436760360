import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Form, Button, Breadcrumb } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { API } from '../../App'
import Layout from '../../layout/Layout'
import Cookies from 'js-cookie'

const FuelEdit = () => {

    const tokenAuth = async () => {
        const token = Cookies.get('jwt')
        const response = await API.post("/home", {}, { headers: { "Authorization": `Bearer ${token}` } })
        if (response.data.status === false) {
            navigate("/")
        }
    }
    const [validate,setValidate] = useState(false)
    const navigate = useNavigate()
    const { _id } = useParams()
    const [user, setUser] = useState({
        Petrol: "",
        Diesel: "",
        Lpg: "",
        Cng: "",
        Gold22: "",
        Gold24: "",
    })

    const getData = async () => {
        const token = Cookies.get('jwt')
        const response = await API.post(`/show/fuel/city/${_id}`, {}, { headers: { "Authorization": `Bearer ${token}` } })

        if (response.data.status === false) {
            if (response.data.response_code === 429) {
                navigate("/")
            } else {
                toast.error('something went wrong')
            }
        } else {
            setUser({
                Petrol: response.data.data.petrol ? response.data.data.petrol : "N/A",
                Diesel: response.data.data.diesel ? response.data.data.diesel : "N/A",
                Lpg: response.data.data.lpg ? response.data.data.lpg : "N/A",
                Cng: response.data.data.cng ? response.data.data.cng : "N/A",
                Gold22: response.data.data.gold22 ? response.data.data.gold22 : "N/A",
                Gold24: response.data.data.gold24 ? response.data.data.gold24 : "N/A",
            })
        }
    }

    const getValue = async (e) => {
        setUser({ ...user, [e.target.name]: e.target.value })
    }

    const saveData = async () => {
        if (user.Petrol === "" || user.Diesel === "" || user.Lpg === "" || user.Cng === "" || user.Gold22 === "" || user.Gold24 === "") {
            setValidate(true)
        } else {
            const Form = new FormData()
            Form.append('Petrol', user.Petrol)
            Form.append('Diesel', user.Diesel)
            Form.append('Lpg', user.Lpg)
            Form.append('Cng', user.Cng)
            Form.append('gold24', user.Gold24)
            Form.append('gold22', user.Gold22)
            const token = Cookies.get('jwt')
            const response = await API.post(`/edit/fuel/city/${_id}`, Form, { headers: { "Authorization": `Bearer ${token}` } })
            if (response.data.status === true) {
                toast.success("Fuel City Updated Succesfully")
                navigate("/fuel")
            } else {
                if (response.data.response_code === 429) {
                    navigate("/")
                } else {
                    toast.error('something went wrong')
                }
            }
        }
    }

    useEffect(() => {
        tokenAuth()
        getData()
    }, [])

    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3>Edit Fuel</h3>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/fuel">Fuel List</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Edit Fuel</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="page-content">
            <Form noValidate validated={validate}>
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Body>
                                <Row className='align-items-center'>
                                    <Col lg={3} sm={12}>
                                        <Form.Group >
                                            <Form.Label htmlFor='today-petrol'>Petrol Price</Form.Label>
                                            <Form.Control type="text" className="my-2" id='today-petrol' name='Petrol' value={user.Petrol} onChange={(e) => { getValue(e) }} placeholder="Enter Your Petrol" required/>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={3} sm={12}>
                                        <Form.Group >
                                            <Form.Label htmlFor='today-Cng'>Diesel Price</Form.Label>
                                            <Form.Control type="text" className="my-2" id='today-Cng' name='Diesel' value={user.Diesel} onChange={(e) => { getValue(e) }} placeholder="Enter Your Diesel" required/>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={3} sm={12}>
                                        <Form.Group >
                                            <Form.Label htmlFor='today-lpg'>LPG Price</Form.Label>
                                            <Form.Control type="text" className="my-2" id='today-lpg' name='Lpg' value={user.Lpg} onChange={(e) => { getValue(e) }} placeholder="Enter Your LPG" required/>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={3} sm={12}>
                                        <Form.Group >
                                            <Form.Label htmlFor='today-cng'>CNG Price</Form.Label>
                                            <Form.Control type="text" className="my-2" id='today-cng' name='Cng' value={user.Cng} onChange={(e) => { getValue(e) }} placeholder="Enter Your CNG" required/>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={3} sm={12}>
                                        <Form.Group >
                                            <Form.Label htmlFor='gold-22'>Gold 22</Form.Label>
                                            <Form.Control type="text" className="my-2" id='gold-22' name='Gold22' value={user.Gold22} onChange={(e) => { getValue(e) }} placeholder="Enter Your Gold 22" required/>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={3} sm={12}>
                                        <Form.Group >
                                            <Form.Label htmlFor='gold-24'>Gold 24</Form.Label>
                                            <Form.Control type="text" className="my-2" id='gold-24' name='Gold24' value={user.Gold24} onChange={(e) => { getValue(e) }} placeholder="Enter Your Gold 24" required/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer className="text-end">
                                <Button variant="primary" className="me-3" onClick={saveData}>Save</Button>
                                <Link to="/fuel">
                                    <Button variant="secondary">Cancle</Button>
                                </Link>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Form>
            </div>
        </Layout>
    )
}

export default FuelEdit