import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Form, Table, Col, Row } from 'react-bootstrap'
import Layout from '../../layout/Layout'
import { Link, useNavigate } from 'react-router-dom'
import Pagination from 'rc-pagination'
import Swal from 'sweetalert2'
import { API, AuthContext } from '../../App'
import Cookies from 'js-cookie'

const Fuel = () => {
    const {commonSearch,setCommonSearch} = useContext(AuthContext);
    const [dataLength, setDataLength] = useState(1)
    const navigate = useNavigate()
    const tokenAuth = async () => {
        const token = Cookies.get('jwt')
        const response = await API.post("/home", {}, { headers: { "Authorization": `Bearer ${token}` } })
        if (response.data.status === false) {
            navigate("/")
        }
    }
    const [date, setDate] = useState({
        min: "",
        max: ""
    })
    const [iconcoror, Seticoncoror] = useState("id_up")
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [state, setState] = useState([])
    const [city, setCity] = useState([])
    const getData = async (search) => {
        setLoading(true)
        const token = Cookies.get('jwt')
        const response = await API.post("/get/fuel/list",{search:search}, { headers: { "Authorization": `Bearer ${token}` } })
        if (response.data.status === false) {
            if (response.data.response_code === 429) {
                navigate("/")
            } else {
                setDataLength(0)
                setData([])
                setLoading(false)
                setCity([])
            }
        } else {
            setData(response.data.data.data)
            setCity(response.data.data.city)
            setDataLength(1)
            setLoading(false)
            // setFuelSearch(search)
            let srcVal = [...commonSearch]
            srcVal[13].search = search
            setCommonSearch(srcVal)

        }
        const stateRes = await API.post("/add/fuelcity/state/list", {}, { headers: { "Authorization": `Bearer ${token}` } })
        if (stateRes.data.status === false) {
            if (stateRes.data.response_code === 429) {
                navigate("/")
            } else {
                // setDataLength(0) 
            }
        } else {
            setState(stateRes.data.data)
        }
    }

    const copyPrice = async () => {

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger me-2'
            },
            buttonsStyling: false
        })

        let today = new Date();
        let yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();
        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        const formattedToday = mm + '/' + dd + '/' + yyyy; //today date (dd-mm-yyyy)

        let searchDate = search.date.split("-")
        const copyDate = searchDate[1] + "/" + searchDate[2] + "/" + searchDate[0]
        if (copyDate === formattedToday) {
            swalWithBootstrapButtons.fire(
                'Cancelled',
                'You Can Not Copy Today Price !',
                'error'
            )
        } else {
            swalWithBootstrapButtons.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, Copy it!',
                cancelButtonText: 'Cancel!',
                reverseButtons: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    setLoading(true)
                    const token = Cookies.get('jwt')
                    const response = await API.post("/copy/fuel/list", { date: search.date === "" ? "" : copyDate }, { headers: { "Authorization": `Bearer ${token}` } })
                    if (response.data.status === false) {
                        if (response.data.response_code === 429) {
                            navigate("/")
                        } else {
                            setLoading(false)
                            swalWithBootstrapButtons.fire(
                                'Cancelled',
                                `${response.data.response_message}`,
                                'error'
                            )
                        }
                    } else {
                        setLoading(false)
                        swalWithBootstrapButtons.fire(
                            'Copied!',
                            `${response.data.response_message}`,
                            'success'
                        )
                        navigate("/fuel")
                        getData(commonSearch[13].search)
                    }

                }
            })
        }
    }

    const deleteUser = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger me-2'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                const token = Cookies.get('jwt')
                const delCity = await API.post(`/delete/fuel/city/${id}`, {}, { headers: { "Authorization": `Bearer ${token}` } })
                if (delCity.data.status === false) {
                    if (delCity.data.response_code === 429) {
                        navigate("/")
                    } else {
                        swalWithBootstrapButtons.fire(
                            'Cancelled',
                            `${delCity.data.response_message}`,
                            'error'
                        )
                    }
                } else {
                    getData(commonSearch[13].search)
                    setCurrent(1)
                }

            }
        })
    }

    const sorting = (col, type = "string", order, e) => {
        Seticoncoror(e.target.id)
        if (order === "ASC") {
            if(col==="petrol" || col==="diesel" || col==="cng" || col==="lpg" || col==="gold22" || col==="gold24"){
                const sorted = [...data].sort((a, b) =>
                  Number(a[col].replace("₹","").replace(",","").trim()) > Number(b[col].replace("₹","").replace(",","").trim()) ? 1 : -1
                );
                if (iconcoror !== e.target.id) {
                  setData(sorted)
                }
            // }else if(col==="date"){
            //     const sorted = [...data].sort((a, b) =>
            //       new Date(a[col]) > new Date(b[col]) ? 1 : -1
            //     );
            //     if (iconcoror !== e.target.id) {
            //       setData(sorted)
            //     }
            }else{
                const sorted = [...data].sort((a, b) =>
                  a[col] > b[col] ? 1 : -1
                );
                if (iconcoror !== e.target.id) {
                  setData(sorted)
                }
            }
        }
        if (order === "DSC") {
            if(col==="petrol" || col==="diesel" || col==="cng" || col==="lpg" || col==="gold22" || col==="gold24"){
                const sorted = [...data].sort((a, b) =>
                Number(a[col].replace("₹","").replace(",","").trim()) < Number(b[col].replace("₹","").replace(",","").trim()) ? 1 : -1
                );
                if (iconcoror !== e.target.id) {
                  setData(sorted)
                }
            // }else if(col==="date"){
            //     const sorted = [...data].sort((a, b) =>
            //       new Date(a[col]) < new Date(b[col]) ? 1 : -1
            //     );
            //     if (iconcoror !== e.target.id) {
            //       setData(sorted)
            //     }
            }else{
                const sorted = [...data].sort((a, b) =>
                  a[col] < b[col] ? 1 : -1
                );
                if (iconcoror !== e.target.id) {
                  setData(sorted)
                }
            }
        }
    }

    //ClearSearchFunction
    const clearSearchFunc = (searchEle)=>{
        let src = [...commonSearch]
        src.map((val)=>{
            if(val.name!==searchEle){
                if(val.name==="City"){
                    val.search={
                        state:"",
                        city:""
                      }
                }else if(val.name==="Fuel"){
                    val.search={
                        state:"",
                        city:"",
                        date:""
                      }
                }else if(val.name==="Branch"){
                    val.search={
                        bank:"",
                        state:"",
                        city:""
                      }
                }else{
                    val.search=""
                }
            }
            return val
        })
        setCommonSearch(src)
    }

    useEffect(() => {
        tokenAuth()
        setSearch(commonSearch[13].search)
        getData(commonSearch[13].search)
        clearSearchFunc("Fuel")
        let today = new Date();
        let yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();
        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        const maxDate = yyyy + '-' + mm + '-' + dd;
        today.setDate(today.getDate() - 14)
        yyyy = today.getFullYear();
        mm = today.getMonth() + 1; // Months start at 0!
        dd = today.getDate();
        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        const minDate = yyyy + '-' + mm + '-' + dd;
        setDate({ min: minDate, max: maxDate })
    }, [])

    const [search, setSearch] = useState({
        state: "",
        city: "",
        date: ""
    })
    const requestSearch = (e) => {
        setSearch({ ...search, [e.target.name]: e.target.value })
        // setFuelSearch({ ...fuelSearch, [e.target.name]: e.target.value })
        let srcVal = [...commonSearch]
        srcVal[13].search[e.target.name] = e.target.value
        setCommonSearch(srcVal)
        let filtObj = search
        filtObj[e.target.name] = e.target.value
        if (e.target.name === "state") {
            setSearch({ ...search, ["city"]: "" })
            // setFuelSearch({ ...fuelSearch, ["city"]: "" })
            let srcVal = [...commonSearch]
            srcVal[13].search['city'] = ''
            setCommonSearch(srcVal)
            filtObj["city"] = ""
        }
        getData(filtObj)
        setCurrent(1)
    };
    
    // Paggintion Code // 
    const [size, setSize] = useState(10);
    const [current, setCurrent] = useState(1);

    const wetData = (current, pageSize) => {
        return data.slice((current - 1) * pageSize, current * pageSize);
    };

    const PerPageChange = (value) => {
        console.log(value)
        setSize(value);
        console.log(size)
        const newPerPage = Math.ceil(data.length / value);
        if (current > newPerPage) {
            setCurrent(newPerPage);
        }
    }

    const PaginationChange = (page, pageSize) => {
        setCurrent(page);
        setSize(pageSize)
    }
    const PrevNextArrow = (current, type, originalElement) => {
        if (type === 'prev') {
            return <button className='paggination-btn'>Previous</button>;
        }
        if (type === 'next') {
            return <button className='paggination-btn'>Next</button>;
        }
        return originalElement;
    }

    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3>Fuel</h3>
                <Row className='align-items-center justify-content-end'>
                    <Col md="auto" xs={6}>
                        <Form.Select onChange={e => { setSize(Number(e.target.value)); setCurrent(1) }} className="my-1">
                        {
                            [10, 25, 50, 100].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                            ))
                        }
                        </Form.Select>
                    </Col>
                    <Col md="auto" xs={8}>
                        <Form.Select id="loan-name" className="my-1" name="state" onChange={requestSearch}>
                            <option value="" selected={commonSearch[13].search.state===""?true:false}>Select State Name</option>
                            {
                                state.map((dataVal, ind) => {
                                    return (
                                        <option value={dataVal._id} selected={commonSearch[13].search.state===dataVal._id?true:false}>{dataVal.name}</option>
                                    )
                                })
                            }
                        </Form.Select>
                    </Col>
                    <Col md="auto">
                        <Form.Select id="loan-name" className="my-1" name="city" onChange={requestSearch}>
                            <option value="" selected={commonSearch[13].search.city===""?true:false}>Select City Name</option>
                            {
                                city.map((dataVal, ind) => {
                                    return (
                                        <option value={dataVal?.city?._id} selected={commonSearch[13].search.city===dataVal?.city?._id?true:false}>{dataVal?.city?.name}</option>
                                    )
                                })
                            }
                        </Form.Select>
                    </Col>
                    <Col md="auto">
                        <Form.Control type="date" placeholder="DD-MM-YYYY" min={date.min} max={date.max} value={commonSearch[13].search.date} className="form-control my-1" name="date" onChange={requestSearch} />
                    </Col>
                    <Col md="auto">
                        <Link className="btn btn-success my-1" onClick={copyPrice}>Copy Price</Link>
                    </Col>
                    <Col md="auto">
                        <Link className="btn btn-primary my-1" to="/fuel/add">Add New</Link>
                    </Col>
                </Row>
            </div>
            <div className="page-content">
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Body>
                                {
                                    loading ? <div className='loader table-loader' ></div> : <></>
                                }
                                <Table bordered responsive>
                                    <thead>
                                        <tr>
                                            <th width='5%'>
                                            <div className='table-sort-filter justify-content-center'>
                                                No
                                                {/* <span className='table-sort'>
                                                    <div className={`sort-down ${iconcoror === "id_down" ? "active" : ""}`} id="id_down" onClick={(e) => { sorting('_id', "", "DSC", e) }} ></div>
                                                    <div className={`sort-up ${iconcoror === "id_up" ? "active" : ""}`} id="id_up" onClick={(e) => { sorting('_id', "", "ASC", e) }} ></div>
                                                </span> */}
                                            </div>    
                                            </th>
                                            <th width="15%">
                                            <div className='table-sort-filter'>
                                                State
                                                <span className='table-sort'>
                                                    <div className={`sort-down ${iconcoror === "state_down" ? "active" : ""}`} id="state_down" onClick={(e) => { sorting('state', "", "DSC", e) }} ></div>
                                                    <div className={`sort-up ${iconcoror === "state_up" ? "active" : ""}`} id="state_up" onClick={(e) => { sorting('state', "", "ASC", e) }} ></div>
                                                </span>
                                            </div>    
                                            </th>
                                            <th width="15%">
                                            <div className='table-sort-filter'>
                                                City
                                                <span className='table-sort'>
                                                    <div className={`sort-down ${iconcoror === "city_down" ? "active" : ""}`} id="city_down" onClick={(e) => { sorting('city', "", "DSC", e) }} ></div>
                                                    <div className={`sort-up ${iconcoror === "city_up" ? "active" : ""}`} id="city_up" onClick={(e) => { sorting('city', "", "ASC", e) }} ></div>
                                                </span>
                                            </div>    
                                            </th>
                                            <th width="9%">
                                            {/* <div className='table-sort-filter'> */}
                                                Date
                                                {/* <span className='table-sort'>
                                                    <div className={`sort-down ${iconcoror === "date_down" ? "active" : ""}`} id="date_down" onClick={(e) => { sorting('date', "", "DSC", e) }} ></div>
                                                    <div className={`sort-up ${iconcoror === "date_up" ? "active" : ""}`} id="date_up" onClick={(e) => { sorting('date', "", "ASC", e) }} ></div>
                                                </span>
                                            </div>     */}
                                            </th>
                                            <th width="8%">
                                            <div className='table-sort-filter'>
                                                Petrol Price
                                                <span className='table-sort'>
                                                    <div className={`sort-down ${iconcoror === "petrol_down" ? "active" : ""}`} id="petrol_down" onClick={(e) => { sorting('petrol', "", "DSC", e) }} ></div>
                                                    <div className={`sort-up ${iconcoror === "petrol_up" ? "active" : ""}`} id="petrol_up" onClick={(e) => { sorting('petrol', "", "ASC", e) }} ></div>
                                                </span>
                                            </div>    
                                            </th>
                                            <th width="8%">
                                            <div className='table-sort-filter'>
                                                Diesel Price
                                                <span className='table-sort'>
                                                    <div className={`sort-down ${iconcoror === "diesel_down" ? "active" : ""}`} id="diesel_down" onClick={(e) => { sorting('diesel', "", "DSC", e) }} ></div>
                                                    <div className={`sort-up ${iconcoror === "diesel_up" ? "active" : ""}`} id="diesel_up" onClick={(e) => { sorting('diesel', "", "ASC", e) }} ></div>
                                                </span>
                                            </div>    
                                            </th>
                                            <th width="8%">
                                            <div className='table-sort-filter'>
                                                CNG Price
                                                <span className='table-sort'>
                                                    <div className={`sort-down ${iconcoror === "cng_down" ? "active" : ""}`} id="cng_down" onClick={(e) => { sorting('cng', "", "DSC", e) }} ></div>
                                                    <div className={`sort-up ${iconcoror === "cng_up" ? "active" : ""}`} id="cng_up" onClick={(e) => { sorting('cng', "", "ASC", e) }} ></div>
                                                </span>
                                            </div>    
                                            </th>
                                            <th width="8%">
                                            <div className='table-sort-filter'>
                                                LPG Price
                                                <span className='table-sort'>
                                                    <div className={`sort-down ${iconcoror === "lpg_down" ? "active" : ""}`} id="lpg_down" onClick={(e) => { sorting('lpg', "", "DSC", e) }} ></div>
                                                    <div className={`sort-up ${iconcoror === "lpg_up" ? "active" : ""}`} id="lpg_up" onClick={(e) => { sorting('lpg', "", "ASC", e) }} ></div>
                                                </span>
                                            </div>    
                                            </th>
                                            <th width="8%">
                                            <div className='table-sort-filter'>
                                                Gold 22C Price
                                                <span className='table-sort'>
                                                    <div className={`sort-down ${iconcoror === "gold22_down" ? "active" : ""}`} id="gold22_down" onClick={(e) => { sorting('gold22', "", "DSC", e) }} ></div>
                                                    <div className={`sort-up ${iconcoror === "gold22_up" ? "active" : ""}`} id="gold22_up" onClick={(e) => { sorting('gold22', "", "ASC", e) }} ></div>
                                                </span>
                                            </div>    
                                            </th>
                                            <th width="8%">
                                            <div className='table-sort-filter'>
                                                Gold 24C Price
                                                <span className='table-sort'>
                                                    <div className={`sort-down ${iconcoror === "gold24_down" ? "active" : ""}`} id="gold24_down" onClick={(e) => { sorting('gold24', "", "DSC", e) }} ></div>
                                                    <div className={`sort-up ${iconcoror === "gold24_up" ? "active" : ""}`} id="gold24_up" onClick={(e) => { sorting('gold24', "", "ASC", e) }} ></div>
                                                </span>
                                            </div>    
                                            </th>
                                            <th width='8%' className='text-center'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            wetData(current, size).map((dataVal, ind) => {
                                                return (
                                                    <tr key={ind}>
                                                        <td className='text-center'>{(current === 1) ? ind + 1 : current * size + ind + 1 - size}</td>
                                                        {/* <td className='text-center'>{oid.indexOf(dataVal._id)+1}</td> */}
                                                        <td>{dataVal.state}</td>
                                                        <td>{dataVal.city}</td>
                                                        <td>{dataVal.date}</td>
                                                        <td>{dataVal.petrol}</td>
                                                        <td>{dataVal.diesel}</td>
                                                        <td>{dataVal.cng}</td>
                                                        <td>{dataVal.lpg}</td>
                                                        <td>{dataVal.gold22}</td>
                                                        <td>{dataVal.gold24}</td>
                                                        <td className='text-center'>
                                                            <Link className="btn btn-outline-primary me-2 btn-icon" size="sm" to={`/fuel/edit/${dataVal._id}`}><i className='bx bx-edit-alt'></i></Link>
                                                            <Button variant="outline-danger" size="sm" className="btn-icon" onClick={() => deleteUser(dataVal._id)}><i className='bx bx-trash-alt' ></i></Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        {
                                            dataLength === 0 ? <tr>
                                                <td colSpan="100%" class="p-0">
                                                    <div className='no-found'>
                                                        <img src="../../not-found/fuel.svg" alt='Data Not Found'/>
                                                        <p>No Found Fuel List</p>
                                                    </div>
                                                </td>
                                            </tr> : ""
                                        }
                                    </tbody>
                                </Table>
                                {
                                    data.length > size ?
                                    <div className="pagination-custom">
                                        <Pagination showTitle={false}
                                            className="pagination-data"
                                            onChange={PaginationChange}
                                            total={data.length}
                                            current={current}
                                            pageSize={size}
                                            showSizeChanger={false}
                                            itemRender={PrevNextArrow}
                                            onShowSizeChange={PerPageChange}
                                        />
                                    </div> : ""
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Layout>
    )
}

export default Fuel