import React from "react";

const Hcp_webview = (props) => {
  return (
    <>
      <img
        src="https://images.livemint.com/img/2021/01/04/1600x900/Loan_1589734291931_1589734292197_1609774868403.jpg"
        alt="Loan"
      />
      <h4>{props.Bank}</h4>
      <p className="inrst-rate">
        <span>Interest Rate:</span>{props.Intrest}
      </p>
      <p className="proc-fee">
        <span>Processing Fee:</span>{props.Process}
      </p>
      <div className="loan-deatils">
        <div className="loan-amnt">
          <h6>{props.Amount}</h6>
          <span className="d-block">Loan Amount</span>
        </div>
        <div className="tenure">
          <h6>{props.Tenure}</h6>
          <span className="d-block">Tenure</span>
        </div>
      </div>
    </>
  );
};

export default Hcp_webview;
