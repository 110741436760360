import React, { useContext } from 'react'
import { Button, Card, Form, Table, Col, Row } from 'react-bootstrap'
import Layout from '../../layout/Layout'
import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { API, AuthContext } from '../../App'
import { useEffect } from 'react'
import Pagination from 'rc-pagination'
import Swal from 'sweetalert2'
import Cookies from 'js-cookie'


const Branch = () => {
    const { branchSearch, setBranchSearch,commonSearch,setCommonSearch} = useContext(AuthContext);
    const [dataLength, setDataLength] = useState(1)
    const navigate = useNavigate()
    const tokenAuth = async () => {
        const token = Cookies.get('jwt')
        const response = await API.post("/home", {}, { headers: { "Authorization": `Bearer ${token}` } })
        if (response.data.status === false) {
            navigate("/")
        }
    }
    const [sort,setSort] = useState({
      _id:1
    })
    const [iconcoror, Seticoncoror] = useState("id_up")
    const [loading,setLoading] = useState(true)
    const [count, setCount] = useState()
    const [data, setData] = useState([])
    const [bank, setBank] = useState([])
    const [state, setState] = useState([])
    const [city, setCity] = useState([])
    const getData = async (dataSort,pageSize) => {
        setLoading(true)
        const token = Cookies.get('jwt')
        const response = await API.post("/getBranch", {sort:dataSort,size:pageSize}, { headers: { "Authorization": `Bearer ${token}` } })
        if (response.data.status === false) {
            if (response.data.response_code === 429) {
                navigate("/")
            } else {
                setDataLength(0)
                setData([])
                setCount(0)
                setLoading(false)
            }
        } else {
            if(response.data.data.total===0){
                setDataLength(0)
                setData([])
                setCount(0)
                setLoading(false)
            }else{
                setDataLength(1)
                setData(response.data.data.data)
                setCount(response.data.data.total)
                setLoading(false)
                if(commonSearch[14].search.bank !== "" || commonSearch[14].search.state !== "" || commonSearch[14].search.city !== ""){
                    PaginationChange(1, size,sort,commonSearch[14].search)
                    if(commonSearch[14].search.state !== ""){
                        const Form = new FormData
                        Form.append("stateId",commonSearch[14].search.state)
                        const token = Cookies.get('jwt')
                        const response = await API.post("/cityByState",Form,{headers:{"Authorization" : `Bearer ${token}`}})
                        if(response.data.status===true){
                            setCity(response.data.data)
                        }else{
                            if(response.data.response_code===429){
                            navigate("/")
                            }else{
                            setCity([])
                            }
                        }
                    }
                }
            }
        }
        const bankRes = await API.post("/ifsc/bank/list",{},{ headers: { "Authorization": `Bearer ${token}` } })
        if (bankRes.data.status === true) {
            setBank(bankRes.data.data)
        }
        const stateRes = await API.post("/getState",{},{ headers: { "Authorization": `Bearer ${token}` } })
        if (stateRes.data.status === true) {
            setState(stateRes.data.data.data)
        }
    }

    const deleteUser = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger me-2'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, Delete it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const token = Cookies.get('jwt')
                    const delCity = await API.post(`/deleteBranch/${id}`, {}, { headers: { "Authorization": `Bearer ${token}` } })
                    if (delCity.data.status === false) {
                        if (delCity.data.response_code === 429) {
                            navigate("/")
                        } else {
                            swalWithBootstrapButtons.fire(
                                'Cancelled',
                                `${delCity.data.response_message}`,
                                'error'
                            )
                        }
                    } else {
                        getData(sort,size)
                        setCurrent(1)
                    }

                }
            })
    }

    const sorting = (name,sort,e) => {
        if(iconcoror!==e.target.id){
            Seticoncoror(e.target.id)
            if(name==="_id"){
                const obj = {
                    _id:sort
                }
                setSort(obj)
                if(search.bank!=="" || search.state!=="" || search.city!==""){
                    PaginationChange(1, size,obj,search)
                }else{
                    getData(obj,size)
                }
            }else{
                const obj = {
                    branchName:sort
                }
                setSort(obj)
                if(search.bank!=="" || search.state!=="" || search.city!==""){
                    PaginationChange(1, size,obj,search)
                }else{
                    getData(obj,size)
                }
            }
        }
    }

    //ClearSearchFunction
    const clearSearchFunc = (searchEle)=>{
        let src = [...commonSearch]
        src.map((val)=>{
            if(val.name!==searchEle){
                if(val.name==="City"){
                    val.search={
                        state:"",
                        city:""
                      }
                }else if(val.name==="Fuel"){
                    val.search={
                        state:"",
                        city:"",
                        date:""
                      }
                }else if(val.name==="Branch"){
                    val.search={
                        bank:"",
                        state:"",
                        city:""
                      }
                }else{
                    val.search=""
                }
            }
            return val
        })
        setCommonSearch(src)
    }

    useEffect(() => {
        tokenAuth()
        getData(sort,size)
    }, [])
    const [search, setSearch] = useState({
        bank:"",
        state:"",
        city:""
    })
    const requestSearch = async(e) => {
        setSearch({...search,[e.target.name]:e.target.value})
        // setBranchSearch({...branchSearch,[e.target.name]:e.target.value})
        let srcVal = [...commonSearch]
        srcVal[14].search[e.target.name] = e.target.value
        setCommonSearch(srcVal)
        let filtObj = search
        filtObj[e.target.name] = e.target.value
        if (e.target.name === "state") {
            setSearch({ ...search, ["city"]: "" })
            // setBranchSearch({ ...branchSearch, ["city"]: "" })
            let srcVal = [...commonSearch]
            srcVal[13].search['city'] = ''
            setCommonSearch(srcVal)
            setCity([])
            filtObj["city"] = ""
        }
        PaginationChange(1, size,sort,filtObj)
        if(e.target.name==="state"){
            if(e.target.value!==""){
              const Form = new FormData
              Form.append("stateId",e.target.value)
              const token = Cookies.get('jwt')
              const response = await API.post("/cityByState",Form,{headers:{"Authorization" : `Bearer ${token}`}})
              if(response.data.status===true){
                setCity(response.data.data)
              }else{
                if(response.data.response_code===429){
                  navigate("/")
                }else{
                  setCity([])
                }
              }
            }
        }
        
    };

    // Paggintion Code // 
    const [size, setSize] = useState(10);
    const [current, setCurrent] = useState(1);

    const wetData = (current, pageSize) => {
        return data
        // return data.slice((current - 1) * pageSize, current * pageSize);
    };

    const PerPageChange = (value) => {
        console.log(value)
        setSize(value);
        console.log(size)
        const newPerPage = Math.ceil(data.length / value);
        if (current > newPerPage) {
            setCurrent(newPerPage);
        }
    }

    const PaginationChange = async (page, pageSize,Sorting,filtObj) => {
        if (commonSearch[14].search.bank !== "" || commonSearch[14].search.state !== "" || commonSearch[14].search.city !== "" || filtObj !== undefined) {
            const apiFunc =async () =>{
                const token = Cookies.get('jwt')
                // const Form = new FormData
                // Form.append("page", page)
                // Form.append("search", JSON.stringify(search))
                const Form = {
                    page:page,
                    search:commonSearch[14].search,
                    sort:Sorting,
                    size:pageSize
                }
                setLoading(true)
                const response = await API.post("/getSearchBranch", Form, { headers: { "Authorization": `Bearer ${token}` } })
                if (response.data.status === false) {
                    if (response.data.response_code === 429) {
                        navigate("/")
                    } else {
                        setDataLength(0)
                        setData([])
                        setCount(0)
                        setLoading(false)
                    }
                } else {
                    if(response.data.data.total===0){
                        setDataLength(0)
                        setData([])
                        setCount(0)
                        setLoading(false)
                    }else{
                        setDataLength(1)
                        setData(response.data.data.data)
                        setCount(response.data.data.total)
                        setLoading(false)
                    }
                }
                setCurrent(page);
                setSize(pageSize)
            }
            if (filtObj !== undefined) {
                apiFunc(filtObj)
            } else {
                apiFunc(search)
            }
        } else {
            const token = Cookies.get('jwt')
            // const Form = new FormData
            // Form.append("page", page)
            // Form.append("search", search)
            const Form = {
                page:page,
                search:commonSearch[14].search,
                sort:Sorting,
                size:pageSize
            }
            setLoading(true)
            const response = await API.post("/getBranch", Form, { headers: { "Authorization": `Bearer ${token}` } })
            if (response.data.status === false) {
                if (response.data.response_code === 429) {
                    navigate("/")
                } else {
                    setDataLength(0)
                    setData([])
                    setCount(0)
                    setLoading(false)
                }
            } else {
                if(response.data.data.total===0){
                    console.log("indata");
                    setDataLength(0)
                    setData([])
                    setCount(0)
                    setLoading(false)
                }else{
                    console.log("ins");
                    setDataLength(1)
                    setData(response.data.data.data)
                    setCount(response.data.data.total)
                    setLoading(false)
                }
            }
            setCurrent(page);
            setSize(pageSize)
        }
    }


    const PrevNextArrow = (current, type, originalElement) => {
        if (type === 'prev') {
            return <button className='paggination-btn'>Previous</button>;
        }
        if (type === 'next') {
            return <button className='paggination-btn'>Next</button>;
        }
        return originalElement;
    }

    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3 className='my-1'>Branch</h3>
                <Row className='align-items-center justify-content-end'>
                    <Col md="3">
                        <Form.Select id="loan-name" className="my-1" name="bank" onChange={requestSearch}>
                            <option value="" selected={commonSearch[14].search.bank===""?true:false}>Select Bank Name</option>
                            {
                                bank.map((dataVal, ind) => {
                                    return (
                                        <option value={dataVal._id} selected={commonSearch[14].search.bank===dataVal._id?true:false}>{dataVal.name}</option>
                                    )
                                })
                            }
                        </Form.Select>
                    </Col>
                    <Col md="3">
                        <Form.Select id="loan-name" className="my-1" name="state" onChange={requestSearch}>
                            <option value="" selected={commonSearch[14].search.state===""?true:false}>Select State Name</option>
                            {
                                state.map((dataVal, ind) => {
                                    return (
                                        <option value={dataVal._id} selected={commonSearch[14].search.state===dataVal._id?true:false}>{dataVal.name}</option>
                                    )
                                })
                            }
                        </Form.Select>
                    </Col>
                    <Col md="3">
                        <Form.Select id="loan-name" className="my-1" name="city" onChange={requestSearch}>
                            <option value="" selected={commonSearch[14].search.city===""?true:false}>Select City Name</option>
                            {
                                city.map((dataVal, ind) => {
                                    return (
                                        <option value={dataVal._id} selected={commonSearch[14].search.city===dataVal._id?true:false}>{dataVal.name}</option>
                                    )
                                })
                            }
                        </Form.Select>
                    </Col>
                    <Col md="auto" xs={6}>
                        <Form.Select onChange={e => { setSize(Number(e.target.value)); setCurrent(1);PaginationChange(1, e.target.value,sort,search) }} className="my-1">
                        {
                            [10, 25, 50, 100].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                            ))
                        }
                        </Form.Select>
                    </Col>
                    <Col md="auto">
                        <Link className="btn btn-primary my-1" to="/branch/add">Add New</Link>
                    </Col>
                </Row>
            </div>
            <div className="page-content">
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Body>
                                {
                                    loading ? <div className='loader table-loader' ></div>:<></>
                                }
                                <Table bordered responsive>
                                    <thead>
                                        <tr>
                                            {/* <th width='5%' className='text-center' onClick={()=>{sorting("_id")}}>No <i className='bx bxs-sort-alt m-r-0'></i></th> */}
                                            <th width='5%'>
                                            <div className='table-sort-filter  justify-content-center'>
                                                No
                                                {/* <span className='table-sort'>
                                                    <div className={`sort-down ${iconcoror === "id_down" ? "active" : ""}`} id="id_down" onClick={(e) => { sorting('_id', -1, e) }} ></div>
                                                    <div className={`sort-up ${iconcoror === "id_up" ? "active" : ""}`} id="id_up" onClick={(e) => { sorting('_id', 1, e) }} ></div>
                                                </span> */}
                                            </div>    
                                            </th>
                                            <th width='20%'>Bank Name</th>
                                            <th width='10%'>State Name</th>
                                            <th width='12%'>District Name</th>
                                            {/* <th width='12%' onClick={()=>{sorting("branch")}}>Branch <i className='bx bxs-sort-alt m-r-0'></i></th> */}
                                            <th width='12%'>
                                            <div className='table-sort-filter'>
                                                Branch
                                                <span className='table-sort'>
                                                    <div className={`sort-down ${iconcoror === "branchName_down" ? "active" : ""}`} id="branchName_down" onClick={(e) => { sorting('branchName_up', -1, e) }} ></div>
                                                    <div className={`sort-up ${iconcoror === "branchName_up" ? "active" : ""}`} id="branchName_up" onClick={(e) => { sorting('branchName_up', 1, e) }} ></div>
                                                </span>
                                            </div>    
                                            </th>
                                            <th width='11%'>Branch Code</th>
                                            <th width='11%'>IFSC Code</th>
                                            <th width='11%'>MICR Code</th>
                                            <th width='8%' className='text-center'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            wetData(current, size).map((dataVal, ind) => {
                                                return (
                                                    dataVal.stateId?
                                                    <tr key={ind}>
                                                        <td className='text-center'>{(current === 1) ? ind + 1 : current * size + ind + 1 - size}</td>
                                                        {/* <td className='text-center'>{oid.indexOf(dataVal._id)+1}</td> */}
                                                        <td>{dataVal?.bankId?.name}</td>
                                                        <td>{dataVal?.stateId?.name}</td>
                                                        <td>{dataVal?.cityId?.name}</td>
                                                        <td>{dataVal?.branchName}</td>
                                                        <td>{dataVal?.branchCode}</td>
                                                        <td>{dataVal?.IFSC_Code}</td>
                                                        <td>{dataVal?.MICR_Code}</td>
                                                        <td className='text-center'>
                                                            <Link className="btn btn-outline-primary me-2 btn-icon" size="sm" to={`/branch/edit/${dataVal._id}`}><i className='bx bx-edit-alt'></i></Link>
                                                            <Button variant="outline-danger" size="sm" className="btn-icon" onClick={() => deleteUser(dataVal._id)}><i className='bx bx-trash-alt' ></i></Button>
                                                        </td>
                                                    </tr>:""
                                                )
                                            })
                                        }
                                        {
                                            dataLength === 0 ? <tr>
                                                <td colSpan="100%" className="p-0">
                                                    <div className='no-found'>
                                                        <img src="../../not-found/bank-branch.svg" alt='Data Not Found'/>
                                                        <p>No Found Branch List</p>
                                                    </div>
                                                </td>
                                            </tr> : ""
                                        }
                                    </tbody>
                                </Table>
                                <div className="pagination-custom">
                                    <Pagination showTitle={false}
                                        className="pagination-data"
                                        onChange={(e)=>{PaginationChange(e,size,sort,search)}}
                                        total={count}
                                        current={current}
                                        pageSize={size}
                                        showSizeChanger={false}
                                        itemRender={PrevNextArrow}
                                        onShowSizeChange={PerPageChange}
                                    />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Layout>
    )
}

export default Branch